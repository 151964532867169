<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'newtitle'">
          <el-col class="newtitle">
            <p class="newtitle" v-html="content.text"></p>
          </el-col>
        </el-row>

      </div>
  </div>
</template>

<script>
export default {
name: "reproducibility-companion-papers",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Call for Reproducibility Companion Papers',
      contents:[
        {
          type:'primaryTitle',
          text:'Introduction',
        },
        {
          type:'text',
          text:`(open only to authors of papers at ACM Multimedia 2020)
          `,
        },
        {
          type:'text',
          text:`Authors of main-conference papers at ACM Multimedia 2020 are invited to make a submission to the new ACM Multimedia Reproducibility track at ACM Multimedia 2021. 
          The track implements the ACM Artifact and Review and Badging process.
          `,
        },
        {
          type:'text',
          text:`In 2021, we will continue to focus on the “Results Reproduced” badge. ACM defines “Results Reproduced” as “The main results of the paper have been obtained in a subsequent study by a person or team other than the authors, using, in part, artifacts provided by the author” 
          (from <a class="demo-link" href="https://www.acm.org/publications/policies/artifact-review-and-badging-current">https://www.acm.org/publications/policies/artifact-review-and-badging-current</a>).
          `,
        },
        {
          type:'text',
          text:`The companion paper and the associated artifacts will undergo a reproducibility review, which will result in an accept or a reject decision. Rejected papers are not disclosed.
          `,
        },
        {
          type:'text',
          text:`
          If accepted, the reviewers add a section to the companion paper describing the replication process and become co-authors of the companion paper. The final version may be up to 4 pages (with an optional page for references). A “Results Reproduced” ACM badge is added to the original paper and to the companion paper, which are both stored in the ACM Digital Library, together with the artifacts.
          `,
        },
        {
          type:'text',
          text:`The badged companion paper will appear in the ACM Multimedia 2021 proceedings and will be presented as a poster in the ACM Multimedia 2021 Reproducibility poster session.`,
        },
        {
          type:'primaryTitle',
          text:'Submission Guidelines',
        },
        {
          type:'text',
          text:'A submission to the Reproducibility track is composed of two parts:',
        },
        {
          type:'retract',
          text:'Companion paper: The companion paper is (up to) three and a half pages in length (excluding references). It contains a high-level description of the experiments carried out in the original paper and that are implemented in the archive.',
        },
        {
          type:'retract',
          text:'Archive: Contains the artifacts (e.g., code, scripts, data sets, protocols), which are cleanly packaged, ready for download and use to replicate the results from the original paper. It contains detailed readme file(s), examples, and all information needed to successfully carry out the experiments.',
        },
        {
          type:'text',
          text:`For additional information, please see the Call for Reproducibility Papers posted on the ACM Multimedia Reproducibility website:
          <a class="demo-link" href="https://project.inria.fr/acmmmreproducibility/cfp2021/">https://project.inria.fr/acmmmreproducibility/cfp2021/</a>.
          More about reproducibility in general, and its implementation in the ACM Multimedia conference series is here: 
          <a class="demo-link" href="https://project.inria.fr/acmmmreproducibility">https://project.inria.fr/acmmmreproducibility</a>`,
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Submission deadline: 7 February, 2021',
        },
        {
          type: 'noList',
          text: 'Camera-ready Submission: 12 August, 2021',
        },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For any questions regarding submissions, please email the Reproducibility Chairs <a href="mailto:mm-repro@sigmm.org"> &lt;mm-repro@sigmm.org&gt;</a>:',
        },
        {
          type:'noListBlack',
          text:'<b> Naoko Nitta</b>  (naoko@comm.eng.osaka-u.ac.jp), Osaka University, Japan',
        },
        {
          type:'noListBlack',
          text:'<b> Michael Alexander Riegler</b>  (michael@simula.no), SimulaMet, Norway',
        },


        {
          type:'newtitle',
          text:' ',
        },
        {
          type:'newtitle',
          text:' ',
        },
        {
          type:'newtitle',
          text:' ',
        },
        {
          type:'newtitle',
          text:'Call for Papers - ACM Multimedia 2022 Reproducibility Track',
        },
        {
          type:'text',
          text:'Dear authors presenting at ACM Multimedia 2021,',
        },
        {
          type:'text',
          text:'<b>*ACM Multimedia 2022 Reproducibility Track: CALL FOR PAPERS*</b>',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://project.inria.fr/acmmmreproducibility/acm-mm-2022-call-for-reproducibility-papers/">https://project.inria.fr/acmmmreproducibility/acm-mm-2022-call-for-reproducibility-papers/</a>',
        },
        {
          type:'noList',
          text:'Submission Deadline: February 7, 2022',
        },
        {
          type:'text',
          text:'<b>* Please visit the Reproducibility Poster Session on Oct. 22nd! *</b>',
        },
        {
          type:'text',
          text:'All authors of main conference papers accepted at ACM MM 2021 (oral and poster sessions) are invited to make a submission to the ACM Multimedia Reproducibility track at ACM Multimedia 2022. The track implements the ACM Artifact and Review and Badging process, and is designed to promote reproducibility, which in turn raises the visibility and applicability of papers that you have previously published at ACM Multimedia.',
        },
        {
          type:'text',
          text:'For 2022, we will again focus on the "Results Reproduced" badge. ACM defines "Results Reproduced" as: "The main results of the paper have been obtained in a subsequent study by a person or team other than the authors, using, in part, artifacts provided by the author." (from <a class="demo-link" href="https://www.acm.org/publications/policies/artifact-review-and-badging-current">https://www.acm.org/publications/policies/artifact-review-and-badging-current</a>).',
        },
        {
          type:'text',
          text:'A submission to the Reproducibility Track is composed of two parts:',
        },
        {
          type:'noList',
          text:'(i) Companion paper: The submitted companion paper is (up to) three and a half pages in length (excluding references). It contains a high-level description of the experiments carried out in the original paper and that are implemented in the archive.',
        },
        {
          type:'noList',
          text:'(ii) Archive: Contains the artifacts (e.g., code, scripts, data sets, protocols), which are cleanly packaged, ready for download and use to replicate the results from the original paper. It contains detailed readme file(s), examples, and any information needed to successfully carry out the experiments.',
        },
        {
          type:'text',
          text:'For additional information, please see the call for reproducibility papers posted on the ACM Multimedia Reproducibility website: <a class="demo-link" href="https://project.inria.fr/acmmmreproducibility/"> https://project.inria.fr/acmmmreproducibility/</a> ',
        },
        {
          type:'text',
          text:'The companion paper and the associated artifacts will undergo a reproducibility review, which will result in an accept or reject decision. Rejected papers are not disclosed.',
        },
        {
          type:'text',
          text:'If accepted, the reviewers add a section to the companion paper describing the replication process and become co-authors of the companion paper. The final version may be up to 4 pages (with an optional page for references). A "Results Reproduced" ACM badge is then added to both the original paper and to the companion paper, which are both stored in the ACM Digital Library, together with the artifacts.',
        },
        {
          type:'text',
          text:'The badged companion paper will appear in the ACM Multimedia 2022 proceedings and will be presented as a poster in the ACM Multimedia 2022 Reproducibility Poster Session.',
        },
        {
          type:'text',
          text:'Since the Reproducibility Track at ACM Multimedia is still quite a new event, we invite you to visit the <b>Reproducibility Poster Session on October 22th</b> and discuss the reproducibility effort with authors at the session, and learn from their experiences. Should you have any subsequent questions about the track or the process, please contact the ACM Multimedia 2022 Reproducibility Chairs via email. ',
        },
        {
          type:'text',
          text:'<b>ACM Multimedia 2022 Reproducibility Chairs:</b>',
        },
        {
          type:'noListBlack',
          text:'<b>Simone Ferlin-Reiter</b> (simone@ferlin.io), Ericsson AB, Sweden',
        },
        {
          type:'noListBlack',
          text:'<b>Michael Alexander Riegler</b> (michael@simula.no), SimulaMet, Norway',
        },
      ],
    }
  },
  mounted()
  {
    document.title = 'ACM Multimedia 2021 Reproducibility Companion Papers'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.newtitle {
    font-size: 3rem;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    color: #0054B0;
    margin-top: 1rem;
}

</style>