<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "sigmm-carer-awards",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: '',
      contents:[
        {
          type:'primaryTitle',
          text:'Whova App Guide for Attendees',
        },
        {
          type:'text',
          text:'Dear ACMMM 2021 Attendees,',
        },
        {
          type:'text',
          text:"We're excited to announce that ACMMM 2021 has a free, official app which you can <a class='demo-link' href='https://whova.com/portal/mm_202010/?source=download_email'>Download</a> and be able to:",
        },
        {
          type:'retract',
          text:'View the event <b>agenda</b> and plan your schedule',
        },
        {
          type:'retract',
          text:'Access <b>Livestreams and Videos</b> directly within sessions and utilize the <b>session</b> Q&A',
        },
        {
          type:'retract',
          text:'Set up <b>Virtual meet-ups</b> with your fellow attendees to connect remotely',
        },
        {
          type:'retract',
          text:'Create and converse through various <b>Discussion Topics</b> in the <b>Community Board</b>',
        },
        {
          type:'retract',
          text:'Receive updates such as <b>last minute room change</b> from the organizers',
        },
        {
          type:'retract',
          text:'Get presentation documents and slides (if the event organizer uploaded them)',
        },
        {
          type:'text',
          text:"After downloading, use your registered email address, when signing up in the app and choose a strong password, or sign up using a social media account. Once you're signed up, you should be taken directly to your event!",
        },
        {
          type:'text',
          text:"If you aren't directly added to the event, please contact the local/online chairs.",
        },
        {
          type:'text',
          text:'Already used the Whova app in the past? Double-check that it is installed on your phone, and log in using your existing account email and password.',
        },
        {
          type:'text',
          text:'If you need more guidance on how to use Whova to attend the event, please visit <a class="demo-link" href="https://whova.com/pages/whova-app-user-guide/?source=download">Whova User Guides</a>.',
        },
        {
          type:'text',
          text:'The following two guides you may also find helpful.'
        },
        {
          type:'retract',
          text:'Desktop App: <a class="demo-link" href="https://whova.com/portal/webapp/mm_202010/sign-up/">https://whova.com/portal/webapp/mm_202010/sign-up/</a>',
        },
        {
          type:'retract',
          text:'Mobile App: <a class="demo-link" href="https://whova.com/portal/mm_202010/?source=download_email">https://whova.com/portal/mm_202010/?source=download_email</a>',
        },
        {
          type:'text',
          text:'We look forward to seeing you soon at ACMMM 2021 !'
        }
        
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Whova APP Guide'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";
</style>