<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{ mainTitle }}
      </el-col>
    </el-row>

    <div v-for="(item,index) in Keynote" :key="index" :id="item.id">
      <div v-for="(content,index) in item.contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 't_author'">
        <el-col>
          <p class="author-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>

    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "panel",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Conference Panels',
      
      Keynote: [
        {
          // 定位id
          id: "1",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Panel 1',
            },
            {
              type: 'textBlue',
              text: '<b>Title: Social Signals and Multimedia: Past, Present, Future</b>'
            },
            {
              type: 'text',
              text: '<b>Panel Moderator: </b>'
            },
            {
              type: 't_author',
              text: 'Martha Larson, Radboud University'
            },
            {
              type: 'text',
              text: '<b>Panelists/Authors: </b>'
            },
            {
              type: 't_author',
              text: 'Hayley Hung, Delft University of Technology'
            },
            {
              type: 't_author',
              text: 'Cathal Gurrin, Dublin City University'
            },
            {
              type: 't_author',
              text: 'Martha Larson, Radboud University'
            },
            {
              type: 't_author',
              text: 'Hatice Gunes, University of Cambridge'
            },
            {
              type: 't_author',
              text: 'Fabien Ringeval, Université Grenoble Alpes'
            },
            {
              type: 't_author',
              text: 'Elisabeth Andre, University of Augsburg'
            },
            {
              type: 't_author',
              text: 'Louis-Philippe Morency, Carnegie Mellon University'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 21 October, 2021 14:00-15:00 (GMT+8 time zone)'
            },
            {
              type: 'text',
              text: '<b>Abstract: </b>The rising popularity of Artificial Intelligence (AI) has brought considerable public interest as well faster and more direct transfer of research ideas into practice. One of the aspects of AI that still trails behind considerably is the role of machines in interpreting, enhancing, modeling, generating, and influencing social behavior. Such behavior is captured as social signals, usually by sensors recording multiple modalities, making it classic multimedia data. Such behavior can also be generated by an AI system when interacting with humans. Using AI techniques in combination with multimedia data can be used to pursue multiple goals, two of which are highlighted here. First, supporting people during social interactions and helping them to fulfil their social needs either actively or passively. Second, improving our understanding of how people collaborate, build relationships, and process self identity. Despite the rise of fields such as Social Signal Processing, a similar panel organised at ACM Multimedia 2014, and an area on social and emotional signals at the ACM MM since 2014, we argue that we have yet to truly fulfil the potential of the combining social signals and multimedia. This panel asks where we have come far enough and what remaining challenges there are in light of recent global events.'
            },
          ]
        },
        {
          // 定位id
          id: "2",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Panel 2',
            },
            {
              type: 'textBlue',
              text: '<b>Title: The Next Generation of Multimodal Conversational Search and Recommendation</b>'
            },
            {
              type: 'text',
              text: '<b>Panel Moderator: </b>'
            },
            {
              type: 't_author',
              text: 'Alan Smeaton, Dublin City University'
            },
            {
              type: 'text',
              text: '<b>Panelists/Authors: </b>'
            },
            {
              type: 't_author',
              text: 'Alan Smeaton, Dublin City University'
            },
            {
              type: 't_author',
              text: 'Alex Hauptmann, Carnegie Mellon University'
            },
            {
              type: 't_author',
              text: 'Joao Magalhaes, Universidade Nova de Lisboa'
            },
            {
              type: 't_author',
              text: 'Tat-Seng Chua, National University of Singapore'
            },
            {
              type: 't_author',
              text: 'Claudia Hauff, Delft University of Technology'
            },
            {
              type: 't_author',
              text: 'Tao Mei, JD AI Research'
            },
            {
              type: 't_author',
              text: 'Verena Rieser, Alana.AI and Heriot-Watt University'
            },
            {
              type: 't_author',
              text: ''
            },
            {
              type: 'text',
              text: '<b>Date: </b> 22 October, 2021 16:00-17:00 (GMT+8 time zone)'
            },
            {
              type: 'text',
              text: '<b>Abstract: </b>The world has become multimodal. In addition to text, we have been sharing a huge amount of multimedia information in the form of images and videos on the Internet. The wide spread use of smart mobile devices has also changed the way we interact with the Internet. It is now natural for us to capture images and videos freely and use as part of a query, in addition to the traditional text and voices. These, along with the rapid advancements in multimedia, natural language processing, information retrieval, and conversation technologies, mean that it is time for us to explore multimodal conversation and its roles in search and recommendation. Multimodal conversation has the potential to help us to uncover and digest the huge amount of multimedia information and knowledge hidden within many systems. It also enables a natural 2-way interactions between humans and machines, with mutual benefits in enriching their respective knowledge. Finally, it opens up the possibilities of disrupting many existing applications and launching new innovative applications. This panel is timely and aims to explore this emerging trend, and discuss its potential benefits and pitfalls to society. The panel will also explore the limitations of current technologies and highlight future research directions towards developing a multimedia conversational system.'
            },
          ]
        },
          
      ],


    //   // //已经无效
    //   // contents: [

    //   ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Conference Panels'
  }
  ,
  methods: {
    goAnchor(id) {
      var anchor = document.getElementById(id);
      // chrome
      document.body.scrollTop = anchor.offsetTop;
      // firefox
      document.documentElement.scrollTop = anchor.offsetTop;
      // safari
      window.pageYOffset = anchor.offsetTop;
    }
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";

.photo {
  width: 28%;
  margin-top: 1.8rem;
  vertical-align: top;
  /*display: inline-block;*/
}

.parent {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: -3rem;
}

.personnel {
  float: left;
  width: 22rem;
  height: 36rem;
  background: #FFFFFF;
  border-radius: 1.8rem;
  border: 2px solid #EEEEEE;
  margin-right: 2rem;
  margin-left: 2rem;
  text-align: center;
  /*margin-bottom: -4rem;*/

}

.personnel:hover {
  box-shadow: 0 0.2rem 0.5rem 0 #B3B3B3;
}

.personnels {
  text-align: center;
  margin-bottom: 2rem;
}

.pre-header > div {
  width: 19rem;
  height: 19rem;
  /*background-color: red;*/
  margin: 1rem auto 1rem;
  border-radius: 1rem;
}

.pre-header > div > img {
  width: 100%;
}

.pre-title > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #0054B0;
  margin-top: 0.6rem;
  /*line-height: 19px;*/
}

.pre-middle > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #272727;
  margin-top: 0.6rem;
  /*line-height: 19px;*/
}

.pre-button {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-top: 0.6rem;

}

.pre-button > p {
  font-size: 1.4rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-family: Roboto-Medium, Roboto, sans-serif;
  font-weight: 500;

  color: #272727;
  /*line-height: 16px;*/
}

.pre {
  margin-top: 2rem;
}

@media screen  and (max-width: 750px) {
  .personnel p {
    margin: 0 0 .8rem !important;
  }

  .parent .personnel:first-child {
    margin-left: 2rem;
  }

  .personnel {
    margin-right: 1.2rem;
  }

  .personnels {
    width: 105%;
  }
}

.parent > div:first-child {
  margin-left: 2rem !important;
}


</style>
