<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'img1'">
          <el-col class="platform">
            <img class="img_logo" :src="Langbo_logo" alt="The Langbo Chengdu Logo">
            <img class="img1" :src="Langbo_img" alt="The Langbo Chengdu">
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'map1'">
          <el-col>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2357.8905182173066!2d104.08924201346328!3d30.654499728777573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x36efc55cbc503add%3A0xd25f43b1741b4a06!2sThe%20Langbo%20Chengdu%2C%20in%20The%20Unbound%20Collection%20by%20Hyatt!5e0!3m2!1sen!2sus!4v1628495323859!5m2!1sen!2sus" width=80% height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </el-col>
        </el-row>

      </div>
  </div>
</template>

<script>
export default {
name: "conference-venue",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      Langbo_logo: "/cnd-image/Langbo_logo.png",
      Langbo_img: "/cnd-image/Langbo_img.png",
      mainTitle: '',
      contents:[
        {
          type:'primaryTitle',
          text:"Conference Venue Information",
        },
        {
          type:'img1',
          text:'图片',
        },
        {
          type:'text',
          text:'The conference venue will be <a class="demo-link" href="https://www.hyatt.com/en-US/hotel/china/the-langbo-chengdu/ctuub">The Langbo Chengdu, in The Unbound Collection by Hyatt</a>. The Langbo Chengdu is located in the center of Chengdu, China, on the 38th to 60th floors of the Western International Finance Center at a height of 249.9 meters. The hotel is adjacent to Chunxi Road and Sino-Ocean Taikoo Li, with various places of interest and historical sites nearby. There are a total of 283 residential-style panoramic rooms and spacious suites with an area starting from 50 square meters. The hotel has a well-equipped fitness center and a heated swimming pool and provides various physiotherapy and massage services in the SPA center.',
        },

        {
          type:'text',
          text:'The hotel provides more than 2,000 square meters of spacious dining and banquet space to meet the various needs of exquisite business and social activities. The Fragrant Source Teahouse with 15 private chess and card rooms will bring guests an authentic Chengdu lifestyle experience. The helipad on the 63rd floor of the hotel overlooks the city and the snow-capped mountains in the distance. It is a unique choice for outdoor activities in the center of Chengdu.',
        },
        {
          type:'text',
          text:"The Langbo Chengdu houses four distinctive, pillar-free venues on one floor including Langbo Hall with a built-in 10m-wide LED display, and Tianfu Hall beneath a ceiling of 'jellyfish' chandeliers. Spanning almost 2,000 sqm, the flexible, well-equipped spaces are suitable for gatherings of anything from 30 to 750.",
        },
        {
          type:'text',
          text:"The Langbo Chengdu is an ideal place for large or small gatherings, with a superior location in the heart of the city, inspiring interior design, diverse venues, excellent culinary reputation, professional banquet and conference service team, and rich experience in hosting imaginative events.",
        },
        {
          type:'textBlue',
          text:'<b>Address</b>: Tower 2, WIFC, 258 East Street, Xiadong Street Section, Jinjiang, Chengdu, China, 610011',
        },
        {
          type:'textBlue',
          text:"<b>Tel</b>: +86 28 6329 7777",
        },
        {
          type:'textBlue',
          text:'<b>Fax</b>: +86 28 6329 8177',
        },
        {
          type:'textBlue',
          text:"<b>Email</b>: reservation@thelangbo.com",
        },
        {
          type:'map1',
          text:'地图',
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Conference Venue'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.platform {
  float: left;
  /*background: #FFFFFF;*/
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.img_logo {
    width: 28%;
    margin-top: 1.8rem;
    margin-left: 3rem;
    vertical-align: top;
    text-align: center;
    /*display: inline-block;*/
}
.img1 {
    width: 45%;
    margin-top: .4rem;
    margin-left: 5rem;
    vertical-align: top;
    text-align: center;
    /*display: inline-block;*/
}
</style>
