<template>
  <div id="index">
    <el-row class="header">
      <el-col>
        <herder/>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="main">
        <router-view/>
      </el-col>
    </el-row>
    <el-row class="footer">
      <el-col>
        <Footer/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Footer from "@/views/acmfooter/AcmFooter";
import herder from "@/views/herder/Herder";

export default {
  name: 'Home',
  components: {
    Footer,
    herder
  },
}
</script>

<style>
.footer{
  margin-top: 8rem;
}

.header {
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 1030;
  height: 100%;
}

.main {
  flex: 1;
  overflow-x: hidden;
}

.el-scrollbar__wrap {
  overflow: scroll;
  height: 100%;
}

.page-component__scroll {
  height: 50rem;
}

.el-menu.el-menu--horizontal {
  border: 0px solid white;
}

.el-icon-arrow-down:before {
  content: "" !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border: 0px solid white;
}

.grid-content {
  border-radius: .4rem;
  min-height: 3.6rem;
  height: 100%;
}
</style>
