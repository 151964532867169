<template>
  <div>
    <el-row class="header">
      <el-col>
        <herder/>
      </el-col>
    </el-row>
    <el-row class="context">
      <el-col :xs="2" :sm="2" :md="2" :lg="4" :xl="6">
        <div class="grid-content"></div>
      </el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="16" :xl="12">
        <router-view/>
      </el-col>
      <el-col :xs="2" :sm="2" :md="2" :lg="4" :xl="6">
        <div class="grid-content"></div>
      </el-col>
    </el-row>
    <el-row class="footer">
      <el-col>
        <Footer/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Herder from "@/views/herder/Herder";
import Footer from "@/views/acmfooter/AcmFooter";
export default {
  name: "Context",
  components:{
    Herder,
    Footer
  }
}
</script>

<style scoped>

.footer{
  margin-top: 7rem;
}
.header {
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 1030;
  height: 100%;
}
.context {
  margin-top: 2rem;
  min-height: 72.9rem;
}
.grid-content{
  min-height: 2rem;
}
@media screen and (max-width: 1040px){
  .center{
    width: 100%;
  }
}
</style>