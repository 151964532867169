<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'cvent_12'">
          <el-col class='c_img'>
            <img class="c1" :src="cvent1" alt="">
            <img class="c1" :src="cvent2" alt="">
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'cvent_3'">
          <el-col class='c_img'>
            <img class="c2" :src="cvent3" alt="">
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'cvent_4'">
          <el-col class='c_img'>
            <img class="c2" :src="cvent4" alt="">
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'cvent_5'">
          <el-col class='c_img'>
            <img class="c2" :src="cvent5" alt="">
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'cvent_6'">
          <el-col class='c_img'>
            <img class="c2" :src="cvent6" alt="">
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'cvent_7'">
          <el-col class='c_img'>
            <img class="c3" :src="cvent7" alt="">
          </el-col>
        </el-row>

      </div>
  </div>
</template>

<script>
export default {
name: "app-guide",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      cvent1: "/cnd-image/cvent_1.png",
      cvent2: "/cnd-image/cvent_2.png",
      cvent3: "/cnd-image/cvent_3.png",
      cvent4: "/cnd-image/cvent_4.png",
      cvent5: "/cnd-image/cvent_5.png",
      cvent6: "/cnd-image/cvent_6.png",
      cvent7: "/cnd-image/cvent_7.png",
      // cvent1: require("@/assets/appguide/cvent_1.png"),
      // cvent2: require("@/assets/appguide/cvent_2.png"),
      // cvent3: require("@/assets/appguide/cvent_3.png"),
      // cvent4: require("@/assets/appguide/cvent_4.png"),
      // cvent5: require("@/assets/appguide/cvent_5.png"),
      // cvent6: require("@/assets/appguide/cvent_6.png"),
      // cvent7: require("@/assets/appguide/cvent_7.png"),
      mainTitle: '',
      contents:[

        {
          type:'primaryTitle',
          text:'Whova app',
        },
        {
          type:'text',
          text:'<b>The ACM Multimedia 2021 Event has been published on Whova, registered authors please access <a class="demo-link" href="https://whova.com/portal/webapp/acmmu_202110/">https://whova.com/portal/webapp/acmmu_202110/</a> (Mainland China viewers need VPN to watch the videos).</b>'
        },
        {
          type:'text',
          text:'Whova app is used for watching pre-loaded videos of all papers. You can either download Whova Mobile App, or you can use Whova Web App. <b>Please use your email for conference registration to register Whova app account,</b> and ACM Multimedia 2021 will be automatically shown in your Whova app.',
        },
        {
          type:'text',
          text:'<b>About Whova app:</b> Please watch <a class="demo-link" href="https://www.youtube.com/watch?v=tE0nmYqgCCU"> this short video </a> to get to know the main features of Whova, and read the <a class="demo-link" href="https://whova.com/pages/whova-app-user-guide/"> attendee guide of Whova</a>.',
        },
        {
          type:'text',
          text:'<b>Pre-recorded videos:</b> In Whova (either Whova Mobile App or Web App), we have pre-recorded video presentations for all papers (including main conference regular papers, accepted submissions of other tracks) with a few exceptions (that means, the authors did not provide videos). Typically, the length of the video is 15 minutes (for regular oral papers) and 5 minutes (for most other presentations). The video is shown as "Recorded Video" in Whova. Please read the <a class="demo-link" href="https://whova.com/pages/whova-app-user-guide/"> attendee guide of Whova</a> for more details about using Whova. All participants are encouraged to watch the video presentations to prepare yourself for the live sessions.',
        },

        {
          type:'primaryTitle',
          text:'Cvent',
        },
        {
          type:'secondaryTitle',
          text:'Logging In to Attendee Hub',
        },
        {
          type:'text',
          text:'Attendee Hub link：<a class="demo-link" href="https://eur.cvent.me/43NLK">https://eur.cvent.me/43NLK</a>.',
        },
        {
          type:'text',
          text:'After clicking the link to go to the virtual event, you will be prompted to log in. After entering your first name, last name, and email address, a verification code will be sent to your email. Enter in the code to access the event.',
        },
        {
          type:'cvent_12',
          text:'',
        },

        {
          type:'secondaryTitle',
          text:'Retrieving Your Verification Code',
        },
        {
          type:'text',
          text:'If you did not receive your verification code, please consider the following:',
        },
        {
          type:'retract',
          text:'Ensure you are entering the same email you registered with for the event. ',
        },
        {
          type:'retract',
          text:'Check spam and junk folders for the email with the verification code.',
        },
        {
          type:'retract',
          text:'The email with the verification code should be triggered in less than a minute.',
        },
        {
          type:'retract',
          text:'If you still do not receive the verification code, contact the Event Planner. Once you’re logged in, you will stay logged into the event for 24 hours if you accept website cookies.',
        },

        {
          type:'secondaryTitle',
          text:'',
        },
        {
          type:'secondaryTitle',
          text:'Attendee Hub User Guide',
        },
        {
          type:'text',
          text:'After entered the attendee hub, you can check the conference agenda and choose the sessions you are interested in to watch session alive during the corresponding time. If you missed the live session, no worry, the attendee hub has play back function which you can watch the recorded videos any time you prefer. ',
        },
        {
          type:'cvent_3',
          text:'',
        },
        {
          type:'text',
          text:'The "Join Session" button will only appear 5 mins before the session start time. Click the button to join the live session-watch the live presentation and interact with presenters.',
        },
        {
          type:'cvent_4',
          text:'',
        },
        {
          type:'text',
          text:'On the right of the live stream interface, you can click chat button to put comments in the chat-box. Make sure your comments follow the policy against harassment at ACM activities.',
        },
        {
          type:'cvent_5',
          text:'',
        },
        {
          type:'text',
          text:'Before the Q&A Session, you can click the Q&A button to rise questions to the presenter. The most voted questions will be read out by the session chair on site and answered by the presenter. Make sure your question follow the policy against harassment at ACM activities.',
        },
        {
          type:'cvent_6',
          text:'',
        },


        {
          type:'secondaryTitle',
          text:'',
        },
        {
          type:'secondaryTitle',
          text:'Accessing the event App',
        },
        {
          type:'text',
          text:'To access the event app, please search Cvent Event App in any search engine and you can download the app which is available in Play store and App Store.  ',
        },
        {
          type:'text',
          text:'After downloading the app, search for the event ACM Multimedia 2021 you can log in using your First Name, Last Name and your Email address.  ',
        },
        {
          type:'cvent_7',
          text:'',
        },

      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 App Guide'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

.c_img {
  float: left;
  /*background: #FFFFFF;*/
  /*margin-left: 2rem;*/
  margin-right: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.c1 {
    /*width: 48%;*/
    height: 36rem;
    margin-top: .5rem;
    margin-left: 2rem;
    vertical-align: top;
    /*display: inline-block;*/
}
.c2 {
    width: 90%;
    /*height: 36rem;*/
    margin-top: .5rem;
    /*margin-left: 2rem;*/
    vertical-align: top;
    /*display: inline-block;*/
}
.c3 {
    /*width: 50%;*/
    height: 36rem;
    margin-top: .5rem;
    /*margin-left: 2rem;*/
    vertical-align: top;
    /*display: inline-block;*/
}


</style>
