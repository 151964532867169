import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
// eslint-disable-next-line no-unused-vars
Vue.config.productionTip = false
import '@/js/fishBone2'
import ElementUi from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUi)

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
