<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Call for Industrial Program Proposal',
      contents:[
        {
          type:'primaryTitle',
          text:'Introduction',
        },
        {
          type:'text',
          text:'ACM Multimedia is the premier international conference in the area of multimedia within the field of computer science. Multimedia research focuses on technologies that enable the use and exchange of content integrating the multiple perspectives of different digital modalities, including images, text, video, audio, speech, music, and sensor data.' 
        },
        {
          type:'text',
          text:'The Industrial Track of ACM Multimedia 2021 covers all aspects of innovative commercial or industrial-strength multimedia algorithms, systems, solutions, and applications, such as content-based image/video indexing/retrieval/recommendation, cross-domain person re-identification, object detection/ tracking/recognition/segmentation, 3D reconstruction, visual localization/navigation, and social computing. Related deep learning techniques are also welcome, such as cross-domain knowledge transfer, new network design, network architecture search etc. It targets a mixed audience of researchers and product developers from several communities, ie, machine learning, computer vision, artificial intelligence, etc.' 
        },
        {
          type:'text',
          text:'Each submission must include <b>at least one</b> author with a non-academic affiliation. All accepted papers will appear in the proceeding.' 
        },
        {
          type:'primaryTitle',
          text:'Submission Guidelines',
        },
        {
          type:'text',
          text:'Papers should be submitted through the conference submission system by selecting the “Industrial” track. Industrial track papers will go through the same double-blind review process as the main track but without a rebuttal phase due to time limitation. Qualified boundary papers rejected by the main track are encouraged to resubmit to the industrial track if the issues raised by the reviewers are sufficiently addressed (please indicate with a cover letter). Submissions must be 6 - 8 pages in length and the paper format is the same as the main track. For more details on the format, please see the submission instructions in the main conference call for papers. <a class="demo-link" href="http://2021.acmmm.org/regular-papers"> http://2021.acmmm.org/regular-papers</a>',
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Paper submission deadline: 15 July, 2021'
        },
        // {
        //   type:'noList',
        //   text:'Notification of acceptance: 1 September, 2021'
        // },
        {
          type:'noList',
          text:'Camera-ready deadline: 18 August, 2021',
        },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For any questions regarding submissions, please email the Industrial Chairs <a href="mailto:mm21-ind@sigmm.org"> &lt;mm21-ind@sigmm.org&gt;</a>:'
        },
        {
          type:'noListBlack',
          text:'<b>Changhu Wang</b> (wangchanghu@bytedance.com), ByteDance AI Lab, China'
        },
        {
          type:'noListBlack',
          text:'<b>Dong Yu</b> (dyu@tencent.com), Tencent AI Lab, USA'
        },
        {
          type:'noListBlack',
          text:'<b>Rongrong Ji</b> (rrji@xmu.edu.cn), Xiamen University, China'
        },
        {
          type:'noListBlack',
          text:'<b>Jianlong Fu</b> (jianf@microsoft.com), Microsoft Research Asia, China'
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Industrial Program Proposal'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>