<template>
  <div style="width: 30rem;">
    <div class="context-twitter">
      <div>ACMMM21 on Twitter</div>
      <hr class="hr"/>
      <div>
        <iframe class="iframe"
                style="border:none;width: 100%; height: 45rem;"
                :src="reportUrl" frameborder="no"
        ></iframe>
      </div>
    </div>
    <div class="Sponsors">
      <div class="sp-title">Sponsors</div>
      <!-- <hr class="hr2"/> -->
      <div v-for="(item,index) in sponsorsDate" :key="index">
        <div class="sponsors-title">{{ item.title }}</div>
        <hr class="hr3"/>
        <div v-if="item.type === 0">
          <div class="diamond" v-for="(image,i) in item.sponsors" :key="i">
            <img class="img" :src="image.img" alt="">
          </div>
        </div>
        <div v-if="item.type === 1">
          <div class="diamond" v-for="(image,i) in item.sponsors" :key="i">
            <img class="img" :src="image.img" alt="">
          </div>
        </div>
        <div v-if="item.type === 2">
          <div class="platinum" v-for="(image,i) in item.sponsors" :key="i" >
            <img class="img" :src="image.img" alt="">
          </div>
        </div>
        <div v-if="item.type === 3">
          <div class="gold" v-for="(image,i) in item.sponsors" :key="i" >
            <img class="img" :src="image.img" alt="">
          </div>
        </div>
        <div v-if="item.type === 4">
          <div class="silver" v-for="(image,i) in item.sponsors" :key="i" >
            <img class="img" :src="image.img" alt="">
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "twitter", methods: {},
  data() {
    return {
      reportUrl: '/twitter.html',
      sponsorsDate: [
        {
          type: 0,
          // title: 'Sponsors',
          sponsors: [
            {
              img: "/cnd-image/logo_sigmm.png",
            },
          ]
        },
        {
          type: 1,
          title: 'Diamond Sponsors',
          sponsors: [
            {
              img: "/cnd-image/logo_ant.png",
            },
            {
              img: "/cnd-image/logo_arc.png",
              // img: require("@/assets/sponsors/sponsosr_1@2x.png"),
            },
            {
              img: "/cnd-image/logo_oppo.png",
            },
          ]
        },
        {
          type: 2,
          title: 'Platinum Sponsors',
          sponsors:[
            {
              img: "/cnd-image/logo_baidu_2.png",
            },
            {
              img: "/cnd-image/logo_meituan.png",
            },
            {
              img: "/cnd-image/logo_alibaba.png",
            },
            {
              img: "/cnd-image/logo_kuaishou.png",
            },
            {
              img: "/cnd-image/logo_huawei.png",
            },
            {
              img: "/cnd-image/logo_byte.png",
            },
          ]
        },
        {
          type: 3,
          title: 'Gold Sponsors',
          sponsors:[
            {
              img: "/cnd-image/logo_meitu_new.png",
            },
            {
              img: "/cnd-image/logo_iqiyi.png",
            }
          ]
        },
        {
          type: 4,
          title: 'Silver Sponsors',
          sponsors:[
            {
              img: "/cnd-image/logo_xiaohongshu.png",
            },

          ]
        },
      ],
    }
  },
  created() {
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Semibold, PingFang SC;
}

.Sponsors {
  margin-top: 6rem;
  width: 30rem;
  color: #444444;
}

.sp-title {
  color: #444444;
  width: 100%;
  font-size: 2.8rem;
  font-weight: 600;
}

.sponsors-title {
  margin-top: 3.5rem;
  font-size: 2.4rem;
  font-weight: 600;
}

.diamond{
  width: 81%;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.platinum{
  float: left;
  width: 81%;
  margin-top: 1rem;
  margin-bottom: 2rem;
/*  margin-top: -2rem;
  margin-bottom: -4rem;*/
}
.gold{
  float: left;
  width: 81%;
  margin-top: 1rem;
  margin-bottom: 2rem;
/*  margin-top: -2rem;
  margin-bottom: -4.5rem;*/
}
.silver{
  float: left;
  width: 40%;
  margin-top: 1rem;
  margin-bottom: 2rem;
/*  margin-top: -1rem;
  margin-bottom: -4rem;*/
}

.img {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.context-twitter {
  margin-top: 10.5rem;
  width: 30rem;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 1.7rem;
}

.hr {
  width: 30rem;
  border: 1px solid #444;
}

.hr2 {
  padding-bottom: 1rem;
  width: 30rem;
  border-top: 2px solid #444;
}

.hr3 {
  padding-bottom: 1rem;
  width: 28rem;
  border-top: 2px solid #444;
}

.u-floatLeft {
  font-size: 1.2rem;
  float: left !important;
}

.u-floatRight {
  font-size: 1.2rem;
  float: right !important;
}

.iframe {
  margin-top: 1rem;
}
</style>
