<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{content.text}}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "workshop-papers",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Workshops',
      contents:[
        {
          type:'text',
          text:'The workshop proposals accepted for the ACM Multimedia 2021 edition are the following:',
        },
        {
          type:'text',
          text:'(For more information on workshop topics, please see the workshop webpages. Links will be added here upon availability.)',
        },
        {
          type:'secondaryBlack',
          text:'AI4TV 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="http://ai4tv.iti.gr/2021/"> 3rd International Workshop on AI for Smart TV Content Production, Access and Delivery</a>',
        },
        {
          type:'text',
          text:'Raphaël Troncy (EURECOM, France)',
        },
        {
          type:'text',
          text:'Jorma Laaksonen (Aalto University, Finland)',
        },
        {
          type:'text',
          text:'Hamed R. Tavakoli ( Nokia Technologies, Finland)',
        },
        {
          type:'text',
          text:'Vasileios Mezaris (CERTH-ITI, Greece)',
        },
        {
          type:'text',
          text:'Lyndon Nixon (MODUL Technology GmbH, Austria)',
        },
        {
          type:'text',
          text:'Mohammad Hosseini (Intel, USA)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },        
        {
          type:'secondaryBlack',
          text:'SUMAC 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://sumac-workshops.github.io/2021/"> 3rd workshop on Structuring and Understanding of Multimedia heritAge Contents</a>',
        },
        {
          type:'text',
          text:'Valerie Gouet-Brunet (LaSTIG Lab / IGN - Gustave Eiffel University, France)',
        },
        {
          type:'text',
          text:'Margarita Khokhlova (Fujitsu France)',
        },
        {
          type:'text',
          text:'Ronak Kosti (Pattern Recognition Lab / FAU Erlangen-Nurnberg, Germany)',
        },
        {
          type:'text',
          text:'Li Weng (Hangzhou Dianzi University, China)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },
        {
          type:'secondaryBlack',
          text:'UrbanMM 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://www.urbanmm.org"> 1st International Workshop on Multimedia Computing for Urban Data</a>',
        },
        {
          type:'text',
          text:'Stevan Rudinac (University of Amsterdam, Netherlands)',
        },
        {
          type:'text',
          text:'Alessandro Bozzon (Delft University of Technology, Netherlands)',
        },
        {
          type:'text',
          text:'Tat-Seng Chua (National University of Singapore, Singapore)',
        },
        {
          type:'text',
          text:'Suzanne Little (Dublin City University, Ireland)',
        },
        {
          type:'text',
          text:'Daniel Gatica-Perez (Idiap-EPFL, Switzerland)',
        },
        {
          type:'text',
          text:'Kiyoharu Aizawa (University of Tokyo, Japan)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },
        {
          type:'secondaryBlack',
          text:"MMSports'21",
        },
        {
          type:'text',
          text:'<a class="demo-link" href="http://mmsports.multimedia-computing.de/mmsports2021/index.html"> 4th International Workshop on Multimedia Content Analysis in Sports</a>',
        },
        {
          type:'text',
          text:'Rainer Lienhart (University of Augsburg, Germany)',
        },
        {
          type:'text',
          text:'Thomas Moeslund (Aalborg University, Denmark)',
        },
        {
          type:'text',
          text:'Hideo Saito (Keio University, Japan)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },
        {
          type:'secondaryBlack',
          text:'HUMA 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href=" https://hcma2021.github.io/"> 2nd International Workshop on Human-centric Multimedia Analysis</a>',
        },
        {
          type:'text',
          text:'Wu Liu (JD AI Research, China)',
        },
        {
          type:'text',
          text:'Xinchen Liu (JD AI Research, China)',
        },
        {
          type:'text',
          text:'Jingkuan Song (University of Electronic Science and Technology of China, China',
        },
        {
          type:'text',
          text:'Dingwen Zhang (Xidian University, China)',
        },
        {
          type:'text',
          text:'Wenbing Huang (Tsinghua University, China)',
        },
        {
          type:'text',
          text:'John Smith (TBM Research, USA)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },
        {
          type:'secondaryBlack',
          text:'ADVM 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://advm-workshop-2021.github.io/"> 1st International Workshop on Adversarial Learning for Multimedia</a>',
        },
        {
          type:'text',
          text:'Aishan Liu (Beihang University, China)',
        },
        {
          type:'text',
          text:'Xinyun Chen (UC Berkeley, USA)',
        },
        {
          type:'text',
          text:'Yingwei Li (Johns Hopkins University, USA)',
        },
        {
          type:'text',
          text:'Chaowei Xiao (NVIDIA Research & Arizona State University, USA)',
        },
        {
          type:'text',
          text:'Xun Yang (National University of Singapore, Singapore)',
        },
        {
          type:'text',
          text:'Xianglong Liu (Beihang University, China)',
        },
        {
          type:'text',
          text:'Dawn Song (UC Berkeley, USA)',
        },
        {
          type:'text',
          text:'Dacheng Tao (JD Explore Academy, China)',
        },
        {
          type:'text',
          text:'Alan Yuille (Johns Hopkins University, USA)',
        },
        {
          type:'text',
          text:'Anima Anandkumar (California Institute of Technology, USA)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },
        {
          type:'secondaryBlack',
          text:'AI & Food 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://sites.google.com/view/acmmm2021aixfood/home"> 3rd Workshop on AI & Food</a>',
        },
        {
          type:'text',
          text:'Ricardo Guerrero (Future Interaction, Samsung AI Center, UK)',
        },
        {
          type:'text',
          text:'Michael Spranger (Sony Corporation, Japan)',
        },
        {
          type:'text',
          text:'Shuqiang Jiang (Chinese Academy of Sciences, China)',
        },
        {
          type:'text',
          text:'Chong-Wah Ngo (Singapore Management University, Singapore)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },
        {
          type:'secondaryBlack',
          text:'Trustworthy AI 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://trustai-mm2021.github.io/"> 1st International Workshop on Trustworthy AI for Multimedia Computing</a>',
        },
        {
          type:'text',
          text:'Teddy Furon (INRIA, France)',
        },
        {
          type:'text',
          text:'Jingen Liu (JD.com AI Research (Silicon Valley), USA)',
        },
        {
          type:'text',
          text:'Yogesh Rawat (University of Central Florida, USA)',
        },
        {
          type:'text',
          text:'Wei Zhang (JD.com AI Research, China)',
        },
        {
          type:'text',
          text:'Qi Zhao (University of Minnesota, USA)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },
        {
          type:'secondaryBlack',
          text:'WAB 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://sites.google.com/view/wab2021"> 1st Workshop on Multimodal Product Identification in Livestreaming and WAB Challenge </a>',
        },
        {
          type:'text',
          text:'Yueting Zhuang (Zhejiang University, China)',
        },
        {
          type:'text',
          text:'Guilin Wu (Alibaba Group, China)',
        },
        {
          type:'text',
          text:'Yi Yang (University of Technology Sydney, Australia)',
        },
        {
          type:'text',
          text:'Yahong Han (Tianjin University, China)',
        },
        {
          type:'text',
          text:'Haihong Tang (Alibaba Group, China)',
        },
        {
          type:'text',
          text:'Xiaobo Li (Alibaba Group, China)',
        },
        {
          type:'text',
          text:'Xiaohan Wang (Zhejiang University, China)',
        },
        {
          type:'text',
          text:'Baoming Yan (Alibaba Group, China)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },


        {
          type:'secondaryBlack',
          text:'MuSe 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://www.muse-challenge.org/muse2021"> 2nd Multimodal Sentiment Analysis Challenge (MuSe 2021) </a>',
        },
        {
          type:'text',
          text:'Björn W. Schuller (Imperial College London, UK)',
        },
        {
          type:'text',
          text:'Lukas Stappen (University of Augsburg/ BMW Group, Germany)',
        },
        {
          type:'text',
          text:'Eva-Maria Meßner (University of Ulm, Germany)',
        },
        {
          type:'text',
          text:'Erik Cambria (Nanyang Technological University/ SenticNet, Singapore)',
        },
        {
          type:'text',
          text:'Guoying Zhao (University of Oulu, Finland)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },

        {
          type:'secondaryBlack',
          text:'MuCAI 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://sites.google.com/view/multimodal-conversational-ai/">2nd ACM Multimedia Workshop on Multimodal Conversational AI </a>',
        },
        {
          type:'text',
          text:'Joao Magalhaes (Universidade Nova de Lisboa, Portugal)',
        },
        {
          type:'text',
          text:'Alex Hauptmann (Carnegie Mellon University, USA)',
        },
        {
          type:'text',
          text:'Ricardo G. Sousa (Farfetch, Portugal)',
        },
        {
          type:'text',
          text:'Carlos Santiago (Universidade de Lisboa, Portugal)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },
        {
          type:'secondaryBlack',
          text:'MULL 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://mull-acmmm21.github.io/">1st Workshop on Multimedia Understanding with Less Labeling </a>',
        },
        {
          type:'text',
          text:'Xiu-Shen Wei (Nanjing University of Science and Technology, China)',
        },
        {
          type:'text',
          text:'Han-Jia Ye (Nanjing University, China)',
        },
        {
          type:'text',
          text:'Jufeng Yang (Nankai University, China)',
        },
        {
          type:'text',
          text:'Jian Yang (Nanjing University of Science and Technology, China)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },
        {
          type:'secondaryBlack',
          text:'ADGD 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://adgd2021.aisingapore.org/">1st Workshop on Synthetic Multimedia – Audiovisual Deepfake Generation and Detection</a>',
        },
        {
          type:'text',
          text:'Stefan Winkler (National University of Singapore, Singapore)',
        },
        {
          type:'text',
          text:'Weiling Chen (National University of Singapore, Singapore)',
        },
        {
          type:'text',
          text:'Abhinav Dhall (Monash University, Australia)',
        },
        {
          type:'text',
          text:'Pavel Korshunov (Idiap Research Institute, Switzerland)',
        },
        {
          type:'secondaryBlack',
          text:'',
        },
        {
          type:'secondaryBlack',
          text:'FME 2021',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://megc2021.github.io">1st Workshop on Facial Micro-Expression: Advanced Techniques for Facial Expressions Generation and Spotting </a>',
        },
        {
          type:'text',
          text:'Jingting Li (Chinese Academy of Sciences, China)',
        },
        {
          type:'text',
          text:'Moi Hoon Yap (Manchester Metropolitan University, UK)',
        },
        {
          type:'text',
          text:'Wen-Huang Cheng (National Yang Ming Chiao Tung University, Taiwan)',
        },
        {
          type:'text',
          text:'John See (Heriot-Watt University Malaysia, Malaysia)',
        },
        {
          type:'text',
          text:"Xiaopeng Hong (Xi'an Jiaotong University, China)",
        },
        {
          type:'text',
          text:'Xiaobai Li (University of Oulu, Finland)',
        },
        {
          type:'text',
          text:'Su-Jing Wang (Chinese Academy of Science, China)',
        },

      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Workshops'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>