<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "sigmm-carer-awards",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: '',
      contents:[
        {
          type:'primaryTitle',
          text:'SIGMM Carer Awards',
        },
        // {
        //   type:'text',
        //   text:'The ACM International Conference on Multimedia is the flagship conference of ACM SIGMM. It provides a great opportunity for researchers to present their work, get acquainted with the work of others, and discuss with colleagues both junior and senior. To promote participation in the conference events, ACM SIGMM will offer SIGMM Carer Awards for attendees (SIGMM membership required).',
        // },
        {
          type:'text',
          text:'The ACM International Conference on Multimedia is the flagship conference of ACM SIGMM. It provides a great opportunity for researchers to present their work, get acquainted with the work of others, and discuss with colleagues both junior and senior. SIGMM is devoted to outreach and diversity. Students from all over the world and all backgrounds are strongly invited to consider applying to the Carer Award. Under the SIGMM 25-in-25 strategy for a better inclusion for women in SIGMM, applications of women are also strongly encouraged for the award. To promote participation in the conference events, ACM SIGMM will offer SIGMM Carer Awards for attendees (SIGMM membership required, but students can become a member for just about USD15 and then apply).',
        },
        {
          type:'secondaryTitle',
          text:'Application Procedure',
        },
        {
          type:'text',
          text:'SIGMM is sponsoring Carer Awards for ACMMM 2021 to allow attendees to avail of financial support to allow them to more fully engage with the event online. Applications are open between August 20, 2021 to September 30, 2021, and more details are available here (<a class="demo-link" href="https://acmsigmm.wufoo.com/forms/m19w0i430htqyos/">https://acmsigmm.wufoo.com/forms/m19w0i430htqyos/</a>).'
        }
        
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 SIGMM Carer Awards'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";
</style>