<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "tutorial-proposals",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: '',
      contents:[
        {
          type:'primaryTitle',
          text:'Call for Tutorial Proposals',
        },
        {
          type:'text',
          text:'ACM Multimedia is the premier international conference in the area of multimedia within the field of computer science. Multimedia research focuses on technologies that enable the use and exchange of content integrating the multiple perspectives of different digital modalities, including images, text, video, audio, speech, music, and sensor data.',
        },
        {
          type:'text',
          text:'Tutorials are an important part of a quality conference program. We are soliciting high quality proposals for tutorials to be held in conjunction with ACM Multimedia 2021. Tutorials will address the state-of-the-art of research and development and provide a comprehensive overview of specific topics in multimedia. Tutorials can come in different focuses, depending on the area covered. A good tutorial should be educational and instructive to provide in-depth introduction of particular pieces of work rather than just a cursory survey of techniques. It should also be of sufficient relevance and importance to attract significant interest from wide audience in the multimedia community.',
        },
        {
          type:'text',
          text:'Although most courses are half-day offerings (3-4 hours including breaks) we also invite proposals for full-day courses (5-7 hours including breaks) provided that the topic merits the additional time and can be anticipated to generate sufficient interest from our community.',
        },
        {
          type:'text',
          text:'Proposals should be submitted via the submission system: <a class="demo-link" href="https://cmt3.research.microsoft.com/ACMMM2021">https://cmt3.research.microsoft.com/ACMMM2021</a>.',
        },
        {
          type:'text',
          text:'The tutorial description must not exceed three A4 pages (font size 10, all inclusive) including the following information:',
        },
        {
          type:'retract',
          text:'Short course/tutorial title.',
        },
        {
          type:'retract',
          text:'Proposers\' names, titles, affiliations and primary contact email.',
        },
        {
          type:'retract',
          text:'Name of speakers if different from the proposers.',
        },
        {
          type:'retract',
          text:'Short bio (max 3 paragraphs) for each organizer, including pointers to relevant publications and experiences.',
        },
        {
          type:'retract',
          text:'Preference for half- or full-day event and indicative breakdown of day.',
        },
        {
          type:'retract',
          text:'Motivation for having a tutorial on the proposed topic and explanations why the proposed tutorial would be beneficial to the multimedia community and of high impact.',
        },
        {
          type:'retract',
          text:'Course description that includes topics that will be covered, along with a brief outline and work distribution in case of multiple presenters.',
        },
        {
          type:'retract',
          text:'Anticipated target audience (introductory, intermediate, and advanced) as well as expected number of attendees.',
        },
        {
          type:'retract',
          text:'Description of materials: Slides and other materials to be distributed to the attendees. Materials must be released after the tutorial.',
        },
        {
          type:'primaryTitle',
          text:'Review Process',
        },
        {
          type:'text',
          text:'Tutorial proposals will be evaluated by tutorial chairs on the basis of their estimated benefit to the community and their fit within the tutorials program as a whole. Factors to be considered include: relevance, timeliness, importance, and audience appeal; suitability for presentation in a half or full day format; past experience and qualifications of the instructors. Selection will also be based on the overall distribution of topics, expected attendance, and specialties of the intended audiences.',
        },
        {
          type:'primaryTitle',
          text:'Advertisement, budget, and registration',
        },
        {
          type:'text',
          text:'Tutorial speakers are responsible of the scientific organization and promotion of the event and the definition of the program of their tutorial. ACM Multimedia local arrangements will provide and pay for rooms, two coffee breaks per day (morning/afternoon) as well as basic equipment (chairs for registered attendees, video projector and screen). All other activities with budget implications are the responsibility of tutorial organizers (e.g., meals, extra breaks, social functions, travel grants, etc). Tutorial organizers will not have to deal with the participants\' registration or budget issues unless additional activities are planned. Note that the tutorial speakers need to cover their own registration but will be paid a stipend (shared among speakers) for the tutorial.',
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Submission deadline: 26 June 2021',
        },
        {
          type:'noList',
          text:'Notification of acceptance: 24 July 2021',
        },
        {
          type: 'noList',
          text: 'Camera-ready Submission: 12 August, 2021',
        },
        // {
        //   type:'noList',
        //   text:'Tutorial outline (camera ready) and description online: 31 August 2021',
        // },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For any questions regarding submissions, please email the Tutorial Chairs <a href="mailto:mm21-tc@sigmm.org"> &lt;mm21-tc@sigmm.org&gt;</a>:',
        },
        {
          type:'noListBlack',
          text:'<b>Changsheng Xu</b> (csxu@nlpr.ia.ac.cn), Chinese Academy of Sciences, China',
        },
        {
          type:'noListBlack',
          text:'<b>Elisa Ricci</b> (e.ricci@unitn.it / eliricci@fbk.eu), University of Trento & Fondazione Bruno Kessler, Italy',
        },
        {
          type:'noListBlack',
          text:'<b>Dong Xu</b> (dong.xu@sydney.edu.au), The University of Sydney, Australia',
        },
        {
          type:'noListBlack',
          text:'<b>Cathal Gurrin</b> (Cathal.Gurrin@dcu.ie), Dublin City University, Ireland',
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Tutorial Proposals'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

</style>