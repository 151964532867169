<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'Titleadmin'">
          <el-col>
            <p class="title-admin" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "attendee-guide",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Summary of Attendee Guide',
      contents:[
        { 
          type:'text',
          text:'As the ACM Multimedia 2021 will be held in a hybrid mode, i.e. onsite and online jointly. The registered attendees can be divided into several categories, according to their conditions. Here we provide the general <a class="demo-link" href="https://2021.acmmm.org/documents/ACMMM21_Attendee_Guide.pdf"> "Attendee Guide" </a> for all ACM Multimedia 2021 attendees as "generic audience" (instead of onsite/online session chairs or paper presenters or audiences) to participate the hybrid conference. ',
        },
        // {
        //   type:'text',
        //   // text:'Additionally, we also encourage the online attendees to use two Apps (<a class="demo-link" href="https://whova.com/pages/whova-app-user-guide/">Whova</a> and <a class="demo-link" href="https://www.cvent.com/en/blog/events/virtual-events"> Cevent</a>) for obtain better conference experience. The Whova app is used for look up the schedule and browse pre-record videos and posters of all accepted papers. The Cevent app is used for joining the live sessions during the conference days. More details of the usages of the two apps will be release in the next two days <a class="demo-link" href="https://2021.acmmm.org/app-guide"> https://2021.acmmm.org/app-guide</a>.',
        //   text:'Additionally, we also encourage the online attendees to use two Apps (<a class="demo-link" href="https://whova.com/pages/whova-app-user-guide/">Whova</a> and <a class="demo-link" href="https://www.cvent.com/en/blog/events/virtual-events"> Cevent</a>) for obtain better conference experience. The Whova app is used for look up the schedule and browse pre-record videos and posters of all accepted papers. The Cevent app is used for joining the live sessions during the conference days. More details of the usages of the two apps can be found in <a class="demo-link" href="https://2021.acmmm.org/app-guide"> https://2021.acmmm.org/app-guide</a>.',
        // },
        {
          type:'text',
          text:'',
        },
        {
          type:'text',
          text:'',
        },
        {
          type:'secondaryTitle',
          text:'Onsite Attendee (English)',
        },
        {
          type:'text',
          text:'Please follow the <a class="demo-link" href="https://2021.acmmm.org/documents/ACMMM21_Full_Technical_Program.pdf">ACMMM21_Full_Technical_Program</a>, attend the sessions you will present your paper or you are interested in. More details can be found in the <a class="demo-link" href="https://2021.acmmm.org/documents/ACMMM21_Attendee_Guide.pdf">Attendee_Guide</a>.',
        },
        {
          type:'secondaryTitle',
          text:'线下参会者 (中文)',
        },
        {
          type:'text',
          text:'请根据<a class="demo-link" href="https://2021.acmmm.org/documents/ACMMM21_Full_Technical_Program.pdf">大会程序议程</a>, 到会议现场参加您要发表或感兴趣的会议环节。请参考<a class="demo-link" href="https://2021.acmmm.org/documents/ACMMM21_Attendee_Guide.pdf">“参会者指南”</a>了解具体参会信息。',
        },
        {
          type:'text',
          text:'',
        },
        {
          type:'text',
          text:'',
        },
        {
          type:'secondaryTitle',
          text:'Online Attendee (English)',
        },
        {
          type:'text',
          text:'If you are <b>a presenter</b> (invited speaker, oral paper presenter, main conference track/panel organizer, workshop/tutorial organizer), please use ZOOM to join the online conference. The detailed ZOOM links (IDs) of each conference room are provided in the <a class="demo-link" href="https://2021.acmmm.org/documents/ACMMM21_Full_Technical_Program.pdf">ACMMM21_Full_Technical_Program</a>. Please search your paper ID or title in the program to find the session time (GMT+8 time zone) and ZOOM ID.',
        },
        // {
        //   type:'text',
        //   text:'Besides, you are welcome to join the <b>Zoom test sessions</b> hosted during <b>Oct. 18th and 19th 10:00-12:00 and 14:00 – 18:00 (GMT+8)</b> to test the video and audio function of your computer. Debugging to the optimal state. The <b>two Zoom test rooms</b> are shown as follows:',
        // },
        // {
        //   type:'textBlue',
        //   text:'Meeting ID 1: 870 2448 7906',
        // },
        // {
        //   type:'textBlue',
        //   text:'https://us02web.zoom.us/j/87024487906',
        // },
        // {
        //   type:'textBlue',
        //   text:'Meeting ID 2: 879 4652 5187',
        // },
        // {
        //   type:'textBlue',
        //   text:'https://us02web.zoom.us/j/87946525187',
        // },
        {
          type:'text',
          text:'If you have any questions, please contact our technician volunteers during the zoom test.',
        },
        {
        type:'secondaryTitle',
        text:'',
        },
        {
          type:'text',
          text:'If you are <b>a registered audience</b>, you can use two Apps (<a class="demo-link" href="https://whova.com/pages/whova-app-user-guide/">Whova</a> and  <a class="demo-link" href="https://www.cvent.com/en/blog/events/virtual-events">Cvent</a>) for obtaining better conference experience. The Whova app is used for look up the schedule and browse pre-record videos and posters of all accepted papers. The Cvent app is used for joining the live sessions during the conference days. More details of the usages of the two apps can be found in <a class="demo-link" href="https://2021.acmmm.org/app-guide">App Guide</a>.',
        },

        {
          type:'secondaryTitle',
          text:'线上参会者 (中文)',
        },
        {
          type:'text',
          text:'如果您是参会的<b>发表者</b>（受邀演讲者，口头论文发表者，主会场其他议程/研讨会/专题报告组织者），请使用ZOOM会议软件加入在线会议。每个会场具体的ZOOM ID已提供在<a class="demo-link" href="https://2021.acmmm.org/documents/ACMMM21_Full_Technical_Program.pdf">程序手册</a>中。请在该手册中查找与您相关的会场时间(GMT+8 北京时区)和ZOOM ID。',
        },
        // {
        //   type:'text',
        //   text:'此外，您可以于<b>北京时间2021年10月18日和19日的上午10:00-12:00和14:00-18:00</b>两个时段进入<b>ZOOM线上测试会议室</b>，测试您个人电脑远程发表的视频和音频播放效果。两个测试ZOOM线上测试会议室的信息如下：',
        // },
        // {
        //   type:'textBlue',
        //   text:'Meeting ID 1: 870 2448 7906',
        // },
        // {
        //   type:'textBlue',
        //   text:'https://us02web.zoom.us/j/87024487906',
        // },
        // {
        //   type:'textBlue',
        //   text:'Meeting ID 2: 879 4652 5187',
        // },
        // {
        //   type:'textBlue',
        //   text:'https://us02web.zoom.us/j/87946525187',
        // },
        // {
        //   type:'text',
        //   text:'如果您有任何问题，请与ZOOM线上会议室的在线技术人员及时联系。',
        // },
        {
        type:'text',
        text:'',
        },
        {
          type:'text',
          text:'如果您是注册的<b>参会听众</b>，您可以使用两个Apps软件 (<a class="demo-link" href="https://whova.com/pages/whova-app-user-guide/">Whova</a>和<a class="demo-link" href="https://www.cvent.com/en/blog/events/virtual-events">Cvent</a>)来获取更好的参会体验。Whova应用是便于查找会议日程及论文相关信息，观看录用论文的预录制视频以及海报电子版内容。Cvent应用则是用来观看大会现场直播。两个应用的具体使用方法请参考<a class="demo-link" href="https://2021.acmmm.org/app-guide">使用指南</a>.',
        },


        {
        type:'text',
        text:'',
        },
        {
        type:'text',
        text:'',
        },
        {
        type:'text',
        text:'',
        },
        {
        type:'text',
        text:'',
        },
        {
        type:'Titleadmin',
        text:'Detailed Attendee Guide',
      },
        {
          type:'primaryTitle',
          text:'Onsite Session Chair Guide',
        },
        {
          type:'text',
          text:'<b>Onsite Session chairs</b> include (1) session chairs for main conference track oral sessions,  (2) organizers of main conference special tracks (e.g., industrial track, demos, multimedia grand challenge),  (3) organizers of workshops, tutorials.',
        },
        {
          type:'textBlue',
          text:"1. Read the detailed program, check the time and venue of the session you will be chaired.",
        },
        {
          type:'textBlue',
          text:"2. Technicians and student volunteers will be in the session to assist you chairing the session.",
        },
        {
          type:'textBlue',
          text:"3. Follow the process shown above. Presentations will be presented on site or online (via Zoom). If the presenter did not show up, the staff will help you to play the preloaded video of the presentation. ",
        },
        {
          type:'textBlue',
          text:"4. Please adhere to the time schedule listed in the program book so that simultaneous sessions are as closely synchronized as possible. Many attendees move from session to session in order to hear specific presentations.",
        },
        {
          type:'textBlue',
          text:"5. Encourage audience on site and online to ask questions about the presentations. Keep the session stay active and positive.",
        },
        {
          type:'primaryTitle',
          text:'Onsite Presenter Guide',
        },
        {
          type:'text',
          text:'<b>Onsite Presenters</b> include (1) the authors who will present their accepted long and short oral papers onsite; (2) invited speakers who will attend onsite for main conference special tracks (e.g., industrial track, demos, multimedia grand challenge), workshops and tutorials.',
        },
        {
          type:'textBlue',
          text:"1. Read the detailed program, check the time and venue of the session you will make your presentation. Also make sure you know the time duration of the presentation and Q&A.",
        },
        {
          type:'textBlue',
          text:"2. Arrive the conference room 15-20 mins before the session start. Upload your PPT to the desktop computer.",
        },
        {
          type:'textBlue',
          text:"3. Presenting your work according to the schedule. Make sure your presentation will not go overtime.",
        },
        {
          type:'textBlue',
          text:"4. Actively interact with the audience on-site and online during the Q&A time.",
        },


        {
          type:'primaryTitle',
          text:'Onsite Audience Guide',
        },
        {
          type:'text',
          text:'<b>Onsite Audiences</b> include the registered authors who will physically attend the conference.',
        },
        {
          type:'textBlue',
          text:"1. Read the full program before attending the conference. Check the time and conference room of the sessions and presentations you are interested in.",
        },
        {
          type:'textBlue',
          text:"2. Visit Whova to watch the pre-loaded presentation video to prepare yourself. Whova user guide can be found on the conference website.",
        },
        {
          type:'textBlue',
          text:"3. Sign-in at the Sign-in desk on the 5th floor of the conference venue. Collect all the conference materials. Make sure you wear the representative cards during the conference.",
        },
        {
          type:'textBlue',
          text:"4. Interact with the presenter during the Q&A time. ",
        },
        {
          type:'textBlue',
          text:"5. Make sure you enjoy the conference and your time in Chengdu!",
        },


        {
          type:'primaryTitle',
          text:'Online Session Chair Guide',
        },
        {
          type:'text',
          text:'<b>Online Session chairs</b> include (1) registered organizers of main conference special tracks (e.g., industrial track, demos, multimedia grand challenge) who cannot attend the conference onsite, (2) registered organizers of workshops, tutorials who cannot attend the conference onsite.',
        },
        {
          type:'textBlue',
          text:"1. Read the detailed program, check the time and Zoom information of the session you will you will be co-chaired.",
        },
        {
          type:'textBlue',
          text:"2. Local session chairs, technicians and student volunteers will be in the session to assist you chairing the session.",
        },
        {
          type:'textBlue',
          text:"3. Encourage audience on site and online to ask questions about the presentations. Keep the session stay active and positive.",
        },


        {
          type:'primaryTitle',
          text:'Online Presenters Guide',
        },
        {
          type:'text',
          text:'<b>Online Presenters</b> include (1) the authors who will present their accepted long and short oral papers online; (2) online invited speakers for main conference special tracks (e.g., industrial track, demos, multimedia grand challenge), workshops and tutorials.',
        },
        {
          type:'textBlue',
          text:"1. Read the detailed program, check the time and Zoom information of the session you will make your presentation. Also make sure you know the time duration of the presentation and Q&A.",
        },
        {
          type:'textBlue',
          text:"2.Join the test sessions hosted during Oct. 18th and 19th to test the video and audio function of your computer. Debugging to optimal state.",
        },
        {
          type:'textBlue',
          text:"3. Join the Zoom 10-15min before the session start. Don't be late!",
        },
        {
          type:'textBlue',
          text:"4. Presenting your work according to the schedule. Make sure your presentation will not go overtime.",
        },
        {
          type:'textBlue',
          text:"5. Actively interact with the audience on-site and online during the Q&A time.",
        },


        {
          type:'primaryTitle',
          text:'Online Audience Guide',
        },
        {
          type:'text',
          text:'<b>Online Audiences</b> include the registered authors who will remotely attend the conference.',
        },
        {
          type:'textBlue',
          text:"1. Read the full program before attending the conference. Check the time of the sessions and presentations you are interested in.",
        },
        {
          type:'textBlue',
          text:"2. Visit Whova to watch the pre-loaded presentation video to prepare yourself. Whova user guide can be found on the conference website.",
        },
        {
          type:'textBlue',
          text:"3. Live session will be broadcasted via Cvent. Please read the Cvent user guide and log in the Cvent website or App to watch the live sessions.",
        },
        {
          type:'textBlue',
          text:"4. Interact with the presenter during the Q&A time via chatbot. The session chair on site will read out the chosen question to the presenter to answer it.",
        },
        {
          type:'textBlue',
          text:"5. Make sure you enjoy the conference!",
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Attendee Guides'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";
</style>