<template>
  <div class="block">
    <el-carousel :interval="2000" ref="remarkCarusel" :autoplay="true"
                 :loop="true" @change="getIndex" :height="bannerHeight+'px'">
      <el-carousel-item
          v-for="(item,index) in slideshowImgs"
          :key="index"
      >
        <img class="slideShowImage" :src="item.src" alt="轮播图">
      </el-carousel-item>
    </el-carousel>
    <div class="banner-context">
      <div class="banner-title">
        <div class="top">
          ACM MULTIMEDIA 2<img :src="bannerCir" alt="">21
        </div>
        <div class="middle">
          Hybrid (Onsite and Virtual)
        </div>
        <div class="bottom" v-html="China">
        </div>
      </div>
      <div class="clock">
        <p class="clock-title">Time to ACM Multimedia 2021:</p>
        <div class="clearfix">
          <div>
            <div>{{ time.days }}</div>
            <p>DAYS</p>
          </div>
          <div>
            <div>{{ time.hours }}</div>
            <p>HOURS</p>
          </div>
          <div>
            <div>{{ time.mins }}</div>
            <p>MINS</p>
          </div>
          <div>
            <div>{{ time.secs }}</div>
            <p>SECS</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "slideShow",
  data() {
    return {
      China:"20-24 October 2021 Chengdu, China",
      slideshowImgs: [
        {src: "/cnd-image/banner1z.png"},
        {src: "/cnd-image/banner13.png"},
        {src: "/cnd-image//banner6.png"},
        {src: "/cnd-image//banner1.png"},
        {src: "/cnd-image//banner5.png"},
        {src: "/cnd-image//banner2.png"},
        {src: "/cnd-image//banner4.png"},
        {src: "/cnd-image/banner8.png"},
      ],
      bannerCir: "/cnd-image/sy_icon_tys@2x.png",
      time: {
        days: 23,
        hours: 10,
        mins: 10,
        secs: 25,
      },
      // 图片父容器高度
      bannerHeight: 964,
      // 浏览器宽度
      screenWidth: 0
    }
  },
  methods: {
    setSize() {
      //通过浏览器宽度(图片宽度)计算高度
      this.bannerHeight = (964 / 1920) * this.screenWidth;
    },
    getDaysBetween() {
      let startDate = new Date();
      let endDate = Date.parse('20 Oct 2021 00:00:00 GMT');
      let days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
      let hours = parseFloat('0.' + (days + '').split('.')[1]) * 24
      let mins = parseFloat('0.' + (hours + '').split('.')[1]) * 60
      let secs = parseFloat('0.' + (mins + '').split('.')[1]) * 60
      this.time.days = Math.floor(days)
      this.time.hours = Math.floor(hours)
      this.time.mins = Math.floor(mins)
      this.time.secs = Math.floor(secs)
      if (this.time.days <= -1) {
        this.time.days = 0
        this.time.hours = 0
        this.time.mins = 0
        this.time.secs = 0
      }
    },
    getIndex(index) {
      let node = $('.banner-context')
      if (index == 6) {
        node.removeClass('green')
        node.removeClass('define')
        node.addClass('red');
      } else if (index === 1) {
        node.removeClass('red')
        node.removeClass('define')
        node.addClass('green')
      } else if (index === 2 || index === 3 || index === 4 || index === 5 || index === 0 || index === 7) {
        node.removeClass('red')
        node.removeClass('green')
        node.addClass('define')
      }
    },
    timepiece() {
      let time = this.time
      time.secs = this.time.secs - 1
      if (time.secs<0){
        time.secs=0
      }
      if (time.secs === 0) {
        if (time.mins>0||time.hours>0||time.days>0){
          time.secs = 59
          time.mins = time.mins - 1
        }
        if (time.mins === 0) {
          if (time.hours>0||time.days>0){
            time.mins = 59
            time.hours = time.hours - 1
          }
          if (time.hours === 0) {
            if (time.days>0){
              time.hours = 23
              time.days = time.days - 1
            }
          }
        }
      }
    }
  },
  mounted() {

    let startDate = new Date();
    let endDate = Date.parse('20 Oct 2021 00:00:00 GMT');
    if (endDate - startDate>0){
      this.getDaysBetween();
      setInterval(this.timepiece, 1000)
    }else {
      let clock = $('.clock')
      clock.addClass("none")
      this.China = "<p> Chengdu, China</p><p>20-24 October 2021</p>"
    }
    //首次加载时,需要调用一次
    this.screenWidth = window.innerWidth
    if (this.screenWidth <= 750) {
      $('.el-carousel__indicators').css('display', 'none')
    }
    this.setSize();
    //窗口大小发生改变时,调用一次
    window.onresize = () => {
      if (this.screenWidth <= 750) {
        $('.el-carousel__indicators').css('display', 'none')
      }
      this.screenWidth = window.innerWidth
      this.setSize()
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};

</script>

<style scoped>
.banner-context {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #F6F6F6;
}

.red, .red .clock-title, .red .clearfix > div > p {
  color: red !important;
}

.red .clearfix > div > div {
  background-color: red;
}

.green .clearfix > div > div {
  background-color: #03AC34;
}

.green, .green .clock-title, .green .clearfix > div > p {
  color: #03AC34;
}
.none{
  display: none !important;
}
.top{
  top: 16vw !important;
}

.define, .define .clock-title, .define .clearfix > div > p {
  color: #FFFFFF;
}

.define .clearfix > div > div {
  background-color: #3075E2;
}

.banner-context .bottom {
  font-size: 1.88vw;
}

.banner-context .top {
  font-size: 3.5vw;
}

.middle {
  font-size: 1.88vw;
}

.banner-context .top img {
  padding-bottom: .7vw;
  width: 3.2vw;
}

.clock {
  margin-top: 1vw;
}

.clock-title {
  font-size: 0.83vw;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #FFFFFF;
}

.slideShowImage {
  width: 100%;
  height: 100%;
}

.block {
  position: relative;
}

.banner-context {
  top: 12.59vw;
  left: 25.21vw;
  position: absolute;
  z-index: 2;
}

.clearfix > div > p {
  font-size: 0.83vw;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
}

.clearfix > div > div {
  width: 4.06vw;
  height: 4.06vw;
  font-size: 2vw;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 4.06vw;
  text-align: center;
  background-color: rgba(48, 117, 226, 0.8);
  border-radius: 2.03vw;
}


.clearfix > div {
  float: left;
  margin-right: 1.51vw;
}
</style>
