import { render, staticRenderFns } from "./sponsors.vue?vue&type=template&id=6499d06d&scoped=true&"
import script from "./sponsors.vue?vue&type=script&lang=js&"
export * from "./sponsors.vue?vue&type=script&lang=js&"
import style0 from "./sponsors.vue?vue&type=style&index=0&id=6499d06d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6499d06d",
  null
  
)

export default component.exports