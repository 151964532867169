<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{ mainTitle }}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Call for Regular Papers',
      contents: [
        {
          type: 'primaryTitle',
          text: 'Introduction',
        },
        {
          type: 'text',
          text: 'ACM Multimedia is the premier conference in multimedia, a research field that discusses emerging computing methods from a perspective in which each medium — e.g. images, text, audio — is a strong component of the complete, integrated exchange of information. The multimedia community has a tradition of being able to handle big data, has been a pioneer in large scale evaluations and dataset creations, and is uniquely angled towards novel applications and cutting edge industrial challenges. As such the conference openly embraces new intellectual angles from both industry as well as academia and welcomes submissions from related fields, such as data science, HCI and signal processing.',
        },
        {
          type: 'text',
          text: 'ACM Multimedia 2021 calls for research papers presenting novel theoretical and algorithmic solutions addressing problems across the domain of multimedia and related applications. The conference also calls for papers presenting novel, thought-provoking ideas and promising (preliminary) results in realizing these ideas.',
        },
        {
          type: 'text',
          text: 'The conference invites research papers of varying length from <b>6 to 8</b> pages, plus additional pages for the reference pages; i.e., the reference page(s) are not counted towards the page limit of 6 to 8 pages. Please note that there is no longer a distinction between long and short papers, but the authors may themselves decide on the appropriate length of the paper. All papers will undergo the same review process and review period.',
        },
        {
          type: 'text',
          text: 'The conference invites papers in four major themes of multimedia: Engagement, Experience, Systems and Understanding.',
        },
        {
          type: 'secondaryTitle',
          text: 'Theme: Engaging Users with Multimedia',
        },
        {
          type: 'text',
          text: 'The engagement of multimedia with society as a whole requires research that addresses how multimedia can be used to connect people with multimedia artifacts that meet their needs in a variety of contexts. The topic areas included under this theme include:',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Emotional and Social Signals</span>',
        },
        {
          type: 'text',
          text: 'This area focuses on the analysis of emotional, cognitive (e.g., brain-based) and interactive social behavior in the spectrum of individual to small group settings. It calls for novel contributions with a strong human-centered focus specializing in supporting or developing automated techniques for analyzing, processing, interpreting, synthesizing, or exploiting human social, affective and cognitive signals for multimedia applications.)',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Multimedia Search and Recommendation</span>',
        },
        {
          type: 'text',
          text: 'To engage user in information access, search and recommendation requires not only understanding of data but also user and context. This area calls for novel solutions for user-centric multimedia search and recommendations, in either automatic or interactive mode, with topics ranging from optimization, user intent prediction, to personalized, collaborative or exploratory algorithms. (Note: Topics focusing primarily on indexing and scalability should be submitted to <b>"Multimedia systems: Data Systems indexing and management"</b>)',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Summarization, Analytics, and Storytelling</span>',
        },
        {
          type: 'text',
          text: 'The information underlying multimedia is by nature multi-perspective. Allowing efficient multi-perspective and context-adaptive information access remains an open problem. This area calls for new and novel solutions that can compose, link, edit and summarize multimedia data into a compact but insightful, enjoyable and multi-perspective presentation to facilitate tasks such as multimedia analytics, decision making, searching and browsing.',
        },
        {
          type: 'secondaryTitle',
          text: 'Theme: Experience',
        },
        {
          type: 'text',
          text: 'One of the core tenants of our research community is that multimedia data contributes to the user experience in a rich and meaningful manner. The topics organized under this theme are concerned with innovative uses of multimedia to enhance the user experience, how this experience is manifested in specific domains, and metrics for qualitatively and quantitatively measuring that experience in useful and meaningful ways. Specific topic areas addressed this year include:',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Interactions and Quality of Experience</span>',
        },
        {
          type: 'text',
          text: 'Papers under this topic area should address human-centered issues. Topics include (i) novel interaction techniques and modalities for accessing, authoring, and consuming multimedia data, (ii) design and implementation of novel interactive media (iii) new methodologies, models, and metrics to understand and/or measure multimedia quality of experience.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Art and Culture</span>',
        },
        {
          type: 'text',
          text: 'Papers under this topic area should develop techniques that enable effective engagement of the public with art and other forms of cultural expression, balancing between sophisticated computational/engineering techniques and artistic / cultural purposes. Topics include (i) digital artworks, including hybrid physical digital installations; dynamic, generative, and interactive multimedia artworks; (ii) computational tools to support creativity, cultural preservation, and curation.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Multimedia Applications</span>',
        },
        {
          type: 'text',
          text: 'Papers under this topic area should push the envelope of how multimedia can be used to improve the user experience in a rich and meaningful manner. We solicit papers that design, implement, and evaluate applications that employ multimedia data in surprising new ways or in application scenarios that user experience remains challenging based on today\'s start-of-the-art, such as immersive telepresence, distance education.',
        },
        {
          type: 'secondaryTitle',
          text: 'Theme: Multimedia Systems',
        },
        {
          type: 'text',
          text: 'Research in multimedia systems is generally concerned with understanding fundamental tradeoffs between competing resource requirements, developing practical techniques and heuristics for realizing complex optimization and allocation strategies, and demonstrating innovative mechanisms and frameworks for building large-scale multimedia applications. Within this theme, we have focussed on three target topic areas:',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Systems and Middleware</span>',
        },
        {
          type: 'text',
          text: 'This area seeks novel contributions that address performance issues in one of the systems components. Topics include operating systems, mobile systems, storage systems, distributed systems, programming systems and abstractions, and embedded systems. Papers must establish performance improvement or non-trivial tradeoffs through integration of multiple systems components or enhancing one of the system components.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Transport and Delivery</span>',
        },
        {
          type: 'text',
          text: 'Papers under this topic area should address improvement to multimedia transport and delivery mechanisms over a computer network. Topics include network protocol enhancement, supporting multimedia data with network mechanisms such as SDN and NFV, in-network content placement.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Data Systems Management and Indexing</span>',
        },
        {
          type: 'text',
          text: 'Papers under this topic area should address performance issues related to data management and indexing to support multimedia access at a large scale, including browsing, searching, recommendation, analysis, processing, and mining. Topics include scalable systems and indexing techniques that support multimedia access and analytics.',
        },
        {
          type: 'secondaryTitle',
          text: 'Theme: Understanding Multimedia Content',
        },
        {
          type: 'text',
          text: 'Multimedia data types by their very nature are complex and often involve intertwined instances of different kinds of information. We can leverage this multi-modal perspective in order to extract meaning and understanding of the world, often with surprising results. Specific topics addressed this year include:',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Multimodal Fusion and Embeddings</span>',
        },
        {
          type: 'text',
          text: 'In the real world, some problems are addressable only through a combination of multiple media and/or modalities. This area seeks new insights and solutions of how multi-perspective media information should be fused and embedded for novel problems as well as innovative systems.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Vision and Language</span>',
        },
        {
          type: 'text',
          text: 'Recent research have driven the merging of vision and language in different ways, for example, captioning, question-answering, multi-modal chatbots. This area seeks new solutions and results that are specific to the problems of combining or bridging vision and language.',
        },
        {
          type: 'secondaryTitle',
          text: '<span style="color:black">Media Interpretation</span>',
        },
        {
          type: 'text',
          text: 'This area seeks novel processing of media-related information in any form that can lead to new ways of interpreting multimedia content. Examples include processing of visual, audio, music, language, speech, or other modalities, for interpretation, knowledge inference, and understanding.',
        },
        {
          type: 'primaryTitle',
          text: 'Submission Instructions',
        },
        {
          type: 'secondaryTitle',
          text: 'Submission Deadline:',
        },
        {
          type: 'noListBlack',
          text: 'Paper submission deadline (Abstract): <del>27 March, 2021</del> <b>10 April, 2021</b> (23:59 AoE) (Register your paper by submitting an abstract. No new registration is allowed after this deadline and submissions without a meaningful abstract may be removed.)',
        },
        {
          type: 'noListBlack',
          text: 'Paper submission deadline (PDF submission): <del>3 April, 2021</del> <b>17 April, 2021</b> (23:59 AoE) (Submit the final PDF of your full paper.)',
        },
        {
          type: 'secondaryTitle',
          text: 'Submission Site:',
        },
        {
          type: 'text',
          text: '<a class="demo-link" href="https://cmt3.research.microsoft.com/ACMMM2021/">https://cmt3.research.microsoft.com/ACMMM2021/</a>'+
          '<br>After signing in the CMT3 as the author, please choose "<b>main track</b>" to submit the paper to the main conference.'
        },
        {
          type: 'secondaryTitle',
          text: 'Paper Format:',
        },
        {
          type: 'text',
          text: 'Submitted papers (.pdf format) must use the ACM Article Template <a class="demo-link" href="https://www.acm.org/publications/proceedings-template">https://www.acm.org/publications/proceedings-template</a>. Please remember to add Concepts and Keywords.'
          
        },
        {
          type: 'text',
          text: 'Please use the template in traditional double-column format to prepare your submissions. For example, word users may use Word Interim Template, and latex users may use sample-sigconf template.'
        },
        {
          type: 'secondaryTitle',
          text: 'Length:',
        },
        {
          type: 'text',
          text: 'As stated in the CfP, submitted papers may be <b>6 to 8</b> pages. Up to two additional pages may be added for references. The reference pages must only contain references. Overlength papers will be rejected without review. Optionally, you may upload supplementary material that complements your submission (100Mb limit).'
        },
        {
          type: 'secondaryTitle',
          text: 'Blinding:',
        },
        {
          type: 'text',
          text: 'Paper submissions must conform with the "double-blind" review policy. This means that the authors should not know the names of the reviewers of their papers, and reviewers should not know the names of the authors. Please prepare your paper in a way that preserves anonymity of the authors.'
        },
        {
          type: 'retract',
          text: 'Do not put the authors\' names under the title.',
        },
        {
          type: 'retract',
          text: 'Avoid using phrases such as "our previous work" when referring to earlier publications by the authors.',
        },
        {
          type: 'retract',
          text: 'Remove information that may identify the authors in the acknowledgments (e.g., co-workers and grant IDs).',
        },
        {
          type: 'retract',
          text: 'Check supplemental material (e.g., titles in the video clips, or supplementary documents) for information that may identify the authors\' identities.',
        },
        {
          type: 'retract',
          text: 'Avoid providing links to websites that identify the authors.',
        },
        {
          type: 'text',
          text: 'Papers without appropriate blinding will be rejected without review.'
        },
        {
          type: 'secondaryTitle',
          text: 'Originality:',
        },
        {
          type: 'text',
          text: 'Papers submitted to ACM Multimedia must be the original work of the authors. The may not be simultaneously under review elsewhere. Publications that have been peer-reviewed and have appeared at other conferences or workshops may not be submitted to ACM Multimedia (see also the arXiv/Archive policy below). Authors should be aware that ACM has a strict policy with regard to plagiarism and self-plagiarism'+
           ' (<a class="demo-link" href="https://www.acm.org/publications/policies/plagiarism">https://www.acm.org/publications/policies/plagiarism</a>). The authors\' prior work must be cited appropriately.'
        },
        {
          type: 'secondaryTitle',
          text: 'Author List:',
        },
        {
          type: 'text',
          text: 'Please ensure that you submit your papers with the full and final list of authors in the correct order. The author list registered for each submission is not allowed to change in any way after the paper submission deadline. (Note that this rule regards the identity of authors, e.g., typos are correctable.)'
        },
        {
          type: 'secondaryTitle',
          text: 'Proofreading:',
        },
        {
          type: 'text',
          text: 'Please proofread your submission carefully. It is essential that the language use in the paper is clear and correct so that it is easily understandable. (Either US English or UK English spelling conventions are acceptable.)',
        },
        {
          type: 'secondaryTitle',
          text: 'ArXiv/Archive Policy:',
        },
        {
          type: 'text',
          text: 'In accordance with ACM guidelines, all SIGMM-sponsored conferences adhere to the following policy regarding arXiv papers:',
        },
        {
          type: 'text',
          text: 'We define a publication as a written piece documenting scientific work that was submitted for review by peers for either acceptance or rejection, and, after review, has been accepted. Documentation of scientific work that is published in a not-for-profit archive without any form of peer-review (departmental Technical Report, arXiv.org, etc.) is <b>not</b> considered a publication. However, this definition of publication does include peer-reviewed workshop papers, even if they do not appear in formal proceedings. Any submission to ACM Multimedia must not have substantial overlap with prior publications or other work currently undergoing peer review anywhere.'
        },
        {
          type: 'text',
          text: 'Note that documents published on website archives are subject to change. Citing such documents is discouraged. Furthermore, ACM Multimedia will review the documents formally submitted and any additional information in a web archive version will not affect the review.',
        },
        {
          type: 'primaryTitle',
          text: 'Review and Rebuttal Process',
        },
        {
          type: 'text',
          text: 'Each submission will be reviewed by at least three reviewers, adhering to the reviewing guidelines (<a class="demo-link" href="https://2021.acmmm.org/reviewer-guidelines">https://2021.acmmm.org/reviewer-guidelines</a>).',
        },
        {
          type: 'text',
          text: 'After receiving the reviews, authors may optionally submit a rebuttal to address the reviewers\' comments as plain text in the cmt3 interface. There is a 5000 characters limit for this.',
        },
        {
          type: 'text',
          text: 'Note that the author rebuttal is optional, it is meant to provide you with an opportunity to rebut factual errors or to supply additional information requested by the reviewers. It is NOT intended to add new contributions (theorems, algorithms, experiments) that were not included in the original submission and were not requested by the reviewers.'
        },

        {
          type: 'primaryTitle',
          text: 'Publication',
        },
        {
          type: 'text',
          text: 'The conference proceedings will be published in the ACM Digital Library. The official publication date is the date the proceedings are made available in the ACM Digital Library. This date may be up to two weeks prior to the first day of the conference. The official publication date affects the deadline for any patent filings related to the published work.',
        },
        {
          type: 'primaryTitle',
          text: 'Important Dates',
        },
        {
          type: 'text',
          text: 'Please note: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type: 'noList',
          text: 'Paper submission deadline (Abstract): <del>27 March, 2021</del> 10 April, 2021',
        },
        {
          type: 'noList',
          text: 'Paper submission deadline (.pdf submission): <del>3 April, 2021</del> 17 April, 2021',
        },
        {
          type: 'noList',
          text: 'Regular Paper Reviews to Author: 7 June, 2021',
        },
        {
          type: 'noList',
          text: 'Regular Paper Rebuttal Deadline: 13 June, 2021',
        },
        {
          type: 'noList',
          text: 'Notification: 3 July, 2021',
        },
        {
          type: 'noList',
          text: 'Camera-ready Submission: 4 August, 2021',
        },
        {
          type: 'primaryTitle',
          text: 'Contacts',
        },
        {
          type: 'text',
          text: 'For any questions, please contact the Technical Program Chairs <a href="mailto:mm21-tpc@sigmm.org"> &lt;mm21-tpc@sigmm.org&gt;</a>:'
        },
        {
          type: 'noListBlack',
          text: '<b>Yang Yang</b> (dlyyang@gmail.com), University of Electronic Science and Technology of China, China',
        },
        {
          type: 'noListBlack',
          text: '<b>Pablo Cesar</b>  (P.S.CesarGarcia@tudelft.nl), Delft University of Technology, Netherland',
        },
        {
          type: 'noListBlack',
          text: '<b>Florian Metze</b>  (fmetze@cs.cmu.edu), Carnegie Mellon University, USA',
        },
        {
          type: 'noListBlack',
          text: '<b>Balakrishnan Prabhakaran</b>  (bprabhakaran@utdallas.edu), University of Texas at Dallas, USA',
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Regular Papers'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>