<template>
  <div>
    <el-row>
      <el-col>
        <slide-show/>
      </el-col>
    </el-row>
    <el-row class="context" :gutter="0">
      <el-col :xs="2" :sm="2" :md="2" :lg="4" :xl="6">
        <div class="grid-content"></div>
      </el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="13" :xl="9">
        <TextContent/>
      </el-col>
      <el-col :lg="3" :xl="3">
        <Twitter class="twitter"/>
      </el-col>
      <el-col :xs="2" :sm="2" :md="2" :lg="4" :xl="6">
        <div class="grid-content">
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import slideShow from "@/views/home/components/slideShow";
import TextContent from "@/views/home/components/context";
import Twitter from "./components/twitter"
export default {
  name: "Index",
  components:{
    slideShow,
    TextContent,
    Twitter
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Home'
  }
}
</script>

<style scoped>
.context{
  margin-top: 2rem;
}
.twitter{
  padding-left: 4rem;
}
</style>
