<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "workshop-proposals",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: '',
      contents:[
        {
          type:'primaryTitle',
          text:'Call for Workshop Proposals',
        },
        {
          type:'text',
          text:'We are soliciting proposals for workshops to be held in conjunction with the ACM Multimedia 2021. The purpose of the workshops is to provide a comprehensive forum on current and emerging topics that will not be flly explored during the main conference and to encourage in-depth discussion of technical and application issues.',
        },
        {
          type:'primaryTitle',
          text:'Proposal Format',
        },
        {
          type:'text',
          text:'Each workshop proposal (maximum 4 pages, in PDF format) must include:',
        },
        {
          type:'text',
          text:'1.Title of the workshop.',
        },
        {
          type:'text',
          text:'2.Workshop organizers (name, aliaion and short biography).',
        },
        {
          type:'text',
          text:'3.Scope and topics of the workshop.',
        },
        {
          type:'text',
          text:'4.Rationale:',
        },
        {
          type:'retract',
          text:'Why the workshop is related to ACM Multimedia 2021.',
        },
        {
          type:'retract',
          text:'Why the topic is important.',
        },
        {
          type:'retract',
          text:'Why the workshop may attract a significant number of attendees.',
        },
        {
          type:'retract',
          text:'A brief biography for each organizer and panelist.',
        },
        {
          type:'text',
          text:'5.Workshop details:',
        },
        {
          type:'retract',
          text:'A draft call for papers (including organizers, program committee, and steering committee if any). Organizers are expected to be fully committed and physically present at the workshop.',
        },
        {
          type:'retract',
          text:'Workshop tentative schedule (number of expected papers, duration fullhalf day, format talks/posters, etc.). We encourage fll-day events that demonstrate the interest of the community in the proposed topic and guarantee the commitment of the organizers. Half-day proposals are also welcome but expected to provide a rationale for the proposed duration.',
        },
        {
          type:'retract',
          text:'Names of potential participants and invited speakers (if any).',
        },
        {
          type:'text',
          text:'6.Workshop history:',
        },
        {
          type:'retract',
          text:'If there are past workshops, the history of the workshop.',
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Workshop proposal submission: March 6, 2021',
        },
        {
          type:'noList',
          text:'Decision notification: March 18, 2021',
        },
        {
          type: 'noList',
          text: 'Workshop paper notification: August 12, 2021',
        },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For questions regarding the submission you can email the workshop chairs <a href="mailto:mm21-wsc@sigmm.org"> &lt;mm21-wsc@sigmm.org&gt;</a>:',
        },
        {
          type:'noListBlack',
          text:'<b>Meng Wang</b> (eric.mengwang@gmail.com), Hefei University of Technology, China',
        },
        {
          type:'noListBlack',
          text:'<b>Peng Wang</b> (pengw@uow.edu.au), The University of Wollongong, Australia',
        },
        {
          type:'noListBlack',
          text:'<b>Lianli Gao</b> (lianli.gao@uestc.edu.cn), University of Electronic Science and Technology of China, China',
        },
        {
          type:'noListBlack',
          text:'<b>Piotr Koniusz</b> (Peter.Koniusz@data61.csiro.au), Data61/CSIRO & Australian National University, Australia',
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Workshop Proposals'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";
</style>