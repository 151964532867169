<template>
  <div class="acmfooter">
    <p>© ACM Multimedia 2021</p>
  </div>
</template>

<script>
export default {
  name: "acmfooter"
}
</script>

<style scoped>
.acmfooter{
  height: 80px;
  background: #0054B0;
  text-align: center;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 80px;
}
@media screen and (max-width: 750px){
  .acmfooter{
    font-size: 20px;
  }

}
</style>