<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{content.text}}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'table1'">
        <el-col>
          <table border = "1" class="table">
            <tr>
              <td align="center" colspan="2">Type</td>
              <td align="center">Early (until Sept 15th, <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">AoE</a>)</td>
              <td align="center">Late (after Sept 15th, <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">AoE</a>)</td>
            </tr>
            <tr>
              <td rowspan="4">Standard</td>
              <td align="center">ACM/SIGMM Member</td>
              <td align="center">900 USD/5850 CNY</td>
              <td align="center">1000 USD/6500 CNY</td>
            </tr>
            <tr>
              <td align="center">Non-ACM/SIGMM Member</td>
              <td align="center">1000 USD/6500 CNY</td>
              <td align="center">1100 USD/7150 CNY</td>
            </tr>
            <tr>
              <td align="center">Student ACM/SIGMM Member</td>
              <td align="center">500 USD/3250 CNY</td>
              <td align="center">650 USD/4225 CNY</td>
            </tr>
            <tr>
              <td align="center">Student Non-ACM/SIGMM Member</td>
              <td align="center">600 USD/3900 CNY</td>
              <td align="center">650 USD/4225 CNY</td>
            </tr>
          </table>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'table2'">
        <el-col>
          <table border = "1" class="table">
            <tr>
              <td  align="center" colspan="2">Type</td>
              <td align="center">Early (until Sept 15th, <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">AoE</a>)</td>
              <td align="center">Late (after Sept 15th, <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">AoE</a>)</td>
            </tr>
            <tr>
              <td rowspan="4">Standard</td>
              <td align="center">ACM/SIGMM Member</td>
              <td align="center">400 USD/2600 CNY</td>
              <td align="center">500 USD/3250 CNY</td>
            </tr>
            <tr>
              <td align="center">Non-ACM/SIGMM Member</td>
              <td align="center">500 USD/3250 CNY</td>
              <td align="center">600 USD/3900 CNY</td>
            </tr>
          </table>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'table3'">
        <el-col>
          <table border = "1" class="table">
            <tr>
              <td align="center" colspan="2">Type</td>
              <td align="center">Early (until Sept 15th, <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">AoE</a>)</td>
              <td align="center">Late (after Sept 15th, <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">AoE</a>)</td>
            </tr>
            <tr>
              <td rowspan="4">Online</td>
              <td align="center">ACM/SIGMM Member</td>
              <td align="center">500 USD/3250 CNY</td>
              <td align="center">600 USD/3900 CNY</td>
            </tr>
            <tr>
              <td align="center">Non-ACM/SIGMM Member</td>
              <td align="center">600 USD/3900 CNY</td>
              <td align="center">700 USD/4550 CNY</td>
            </tr>
            <!-- <tr>
              <td align="center">Student ACM/SIGMM Member</td>
              <td align="center">300 USD/1950 CNY</td>
              <td align="center">400 USD/2600 CNY</td>
            </tr>
            <tr>
              <td align="center">Student Non-ACM/SIGMM Member</td>
              <td align="center">400 USD/2600 CNY</td>
              <td align="center">500 USD/3250 CNY</td>
            </tr> -->
            
          </table>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'table4'">
        <el-col>
          <table border = "1" class="table4">
            <tr>
              <td>Long Paper</td>
              <td>
                <ul>
                  <li>Oral papers in main track</li>
                  <li>Poster papers in main track</li>
                  <li>Brave New Ideas track</li>
                  <li>Industrial track</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Short Paper</td>
              <td>
                <ul>
                  <li>Demos and Videos track</li>
                  <li>Open Source Software Competition</li>
                  <li>Grand Challenge</li>
                  <li>All workshop papers</li>
                  <li>Reproducibility Companion papers</li>
                  <li>Interactive Arts</li>
                  <li>Doctoral Symposium papers</li>
                </ul>
              </td>
            </tr>
          </table>
        </el-col>
      </el-row>


    </div>
  </div>
</template>

<script>
export default {
  name: "registration",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Registration',
      contents:[
        {
          type:'text',
          text:'Below on this page you will find information that is important for registration. We suggest that you read it through before registering. ',
        },
        {
          type:'text',
          text:'<b>The registration site has been released: <a class="demo-link" href="https://iconf.mike-x.com/RJiIp"> https://iconf.mike-x.com/RJiIp</a></b>',
        },
        {
          type:'text',
          text:'For refund requests and technical issues with registration, please contact the conference secretary via email or cellphone as shown below. ',
        },
        {
          type:'retract',
          text:'Conference Secretary: Penny Lee',
        },
        {
          type:'retract',
          text:'Email: <a href="mailto:secretary@acmmm21.org"> secretary@acmmm21.org</a>',
        },
        {
          type:'retract',
          text:'Phone: +86-13541382102 (9:30-12:00, 13:30-18:00, Monday to Friday, Beijing Time)',
        },
        {
          type:'secondaryBlack',
          text:'Standard Registration Fees',
        },
        {
          type:'text',
          text:'(1USD=6.5CNY)',
        },
        {
          type:'text',
          text:'Full Registrations – 5 Days (covers all technical and social programs):',
        },
        {
          type:'table1',
          text:'表格',
        },
        {
          type:'text',
          text:'Workshop/Tutorial – 2 Days (covers tutorials and workshops only):',
        },
        {
          type:'table2',
          text:'表格',
        },
        {
          type:'secondaryBlack',
          text:'Special Registration Fees for Online Attendees',
        },
        {
          type:'text',
          text:'We offer special rates for online attendees (covers all online technical programs including workshops and tutorials):',
        },
        {
          type:'table3',
          text:'表格',
        },
        {
          type:'text',
          text:'Link to register ACM/SIGMM membership:',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://www.acm.org/membership/join"> https://www.acm.org/membership/join</a>',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://www.acm.org/special-interest-groups/sigs/sigmm"> https://www.acm.org/special-interest-groups/sigs/sigmm</a>',
        },
        {
          type:'secondaryBlack',
          text:'Author Registration Policy',
        },
        {
          type:'text',
          text:'The author registration deadline is <b>31 August 2021</b> (<a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">AoE</a>). All authors, for all tracks of the conference, including workshops must have registered by this deadline. Papers must be covered by an author registration in order to be included in the conference proceedings (ACM Digital Library).',
        },
        // {
        //   type:'text',
        //   text:'The ACM MM conference this year will be held offline. We have prepared the conference venue (space, equipment, etc.) supported for all papers. The author registration shall follow the standard registration fees.',
        // },
        {
          type:'text',
          text:'In particular, each paper at the conference must be covered by at least an author registration according to the following rules.',
        },
        {
          type:'retract',
          text:'One standard non-student full registration can be used to cover one long paper. Alternatively, one standard non-student full registration can be used to cover up to two short papers. The online registration follows the rule of the standard non-student full registration (covers one long paper or up to two short papers).',
        },
        {
          type:'retract',
          text:'One standard student full registration covers one short paper. Note that if a student would like to register and have that registration cover a long paper, then that student must not register as a student but instead register as a non-student for the full conference (as an ACM/SIG or a non-ACM/SIG Member).',
        },
        {
          type:'retract',
          text:'One standard workshop/tutorial registration covers one workshop paper.',
        },
        {
          type:'text',
          text:'Paper Type：',
        },
        {
          type:'table4',
          text:'表格',
        },
        {
          type:'secondaryBlack',
          text:'Meals and Social Events',
        },
        {
          type:'text',
          text:'The standard full registration (student or non-student) includes 1 ticket for the opening reception, 1 ticket for the banquet, and 1 ticket per day for the lunch box. The workshop/tutorial registration covers the lunch boxes on workshop/tutorial days. For each standard full registration (student or non-student), additional tickets for opening reception and banquet can be purchased at the following rates,',
        },
        {
          type:'retract',
          text:'Optionally 1 ticket for an accompanying person at <b>the opening reception on Wednesday 20 October 2021 (50 USD/325 CNY)</b>',
        },
        {
          type:'retract',
          text:'Optionally 1 ticket for an accompanying person at <b>the banquet on Friday 22 October 2021 (100 USD/650 CNY)</b>',
        },
        {
          type:'text',
          text:'The registration form contains check boxes for purchasing additional tickets on social events.',
        },
        {
          type:'secondaryBlack',
          text:'Accommodations',
        },
        {
          type:'text',
          text:'For accomendtations, please refer to the acommendations page: <a class="demo-link" href="https://2021.acmmm.org/recommended-accommodation">https://2021.acmmm.org/recommended-accommodation</a>',
        },
        {
          type:'secondaryBlack',
          text:'Visa',
        },
        {
          type:'text',
          text:'The registration form contains a box that can be checked if you require an invitation letter.',
        },
        {
          type:'secondaryBlack',
          text:'Receipt',
        },
        {
          type:'text',
          text:'A confirmation email will be sent to you by email after you finish a registration. The registration form contains a box that you can click if you need an attendance certificate (given to you at the conference).',
        },
        {
          type:'secondaryBlack',
          text:'Registration Cancellation',
        },
        {
          type:'text',
          text:'Cancellation and refund requests received on or before <b>15 September 2021</b> (<a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">AoE</a>) will be refunded at 50% of the registration fee. Author registration cannot be cancelled after <b>7 September, 2021</b> (<a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">AoE</a>).',
        },
        {
          type:'text',
          text:'Note that it is not possible to cancel a registration if the cancellation would leave a paper in the proceedings uncovered by an author registration (see the rules on author registration). After <b>15 September 2021</b> (<a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">AoE</a>), no refunds will be made.',
        },
        {
          type:'secondaryBlack',
          text:'Responsibility',
        },
        {
          type:'text',
          text:"The organizers of ACM MM 2021 cannot accept any liability for personal accidents, loss or damage of private property of participants, nor of damages caused by them to others or others' properties during the conference and its accompanying events. Registration does not include any form of insurance. Conference participants are therefore responsible for making their own insurance arrangements.",
        },
        {
          type:'secondaryBlack',
          text:'Statement on the Conference Environment',
        },
        {
          type:'text',
          text:'The scientific value of ACM Multimedia is directly dependent on conference participants interacting with each other in an open-minded and respectful manner. In this way, we ensure a welcoming, safe conference environment. Since ACM Multimedia is an ACM conference, attendees, speakers, organizers are bound by the ACM Policy Against Discrimination and Harassment. (<a class="demo-link" href="https://www.acm.org/about-acm/policy-against-harassment">https://www.acm.org/about-acm/policy-against-harassment </a>). The policy covers interaction at the conference venue, but also related interaction, e.g., on social media. The policy is an explicit statement of our commitment, as a community, to inclusion and safety at the conference. During registration, you will be asked to check a box to confirm that you are aware that your behavior must conform to the ACM Policy Against Discrimination and Harassment.',
        },

      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Registration'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
.table{
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  /*text-align: center;*/
  color: #5b5b5b;
  font-size: 1.7rem;
}
.table4{
  width: 100%;
  color: #5b5b5b;
}
.table4 td,th{
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 1.7rem;
  padding-left: 1rem;
}
table td {
  padding-left: 1rem;
}
tr:nth-child(even) {
  background: #f1f1f1;
}

</style>
