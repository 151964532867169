<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{ mainTitle }}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Call for Technical Demos and Videos Program',
      contents: [
        {
          type: 'primaryTitle',
          text: 'Overview',
        },
        {
          type: 'text',
          text: 'ACM Multimedia is the premier international conference in the area of multimedia within the field of computer science. Multimedia research focuses on technologies that enable the use and exchange of content integrating the multiple perspectives of dfferent digital modalties, including images, text, video, audio, speech, music, and sensor data.',
        },
        {
          type: 'text',
          text: 'As in previous years, ACM Multimedia will provide demonstration and video sessions. Demos and videos are intended as real, practical, and interactive proof of the presenters\' research ideas and scientific or engineering contributions, with the goal of providing multimedia researchers and practitioners with the opportunity to discuss working multimedia systems, applications, prototypes, or proof-of-concepts. Such a setting llows conference attendants to view and interact first hand with live evidence of innovative solutions and ideas in the field of multimedia and to see leading edge research at work.',
        },
        {
          type: 'primaryTitle',
          text: 'Expected Contents',
        },
        {
          type: 'text',
          text: 'Submissions are encouraged in all areas related to multimedia, as described in ACM MM 2021 general call for papers: <a class="demo-link" href="http://2021.acmmm.org/regular-papers">http://2021.acmmm.org/regular-papers</a>',
        },
        {
          type: 'primaryTitle',
          text: 'Facilities',
        },
        {
          type: 'text',
          text: 'Once accepted, demonstrators and video presenters will be provided with a table, poster board, power outlet and wireless (shared) Internet. Demo and video presenters are expected to bring with themselves everything else needed for their demo and video presentations, such as hardware, laptops, sensors, PCs, etc. However if you have special requests such as a larger space, special lighting conditions and so on, we will do our best to arrange them.',
        },
        {
          type: 'text',
          text: '<b>Important note for the authors:</b> The oficial publication date is the date the proceedings are made available in the ACM Digital Library. This date may be up to two weeks prior to the first day of the conference. The oficial publication date affects the deadine for any patent flings related to published work.',
        },
        {
          type: 'primaryTitle',
          text: 'Submission Guidelines',
        },
        {
          type: 'text',
          text: 'The documents should be submitted through the conference submission system selecting the <b>“Demos and Videos”</b> track. <a class="demo-link" href="https://cmt3.research.microsoft.com/ACMMM2021/">https://cmt3.research.microsoft.com/ACMMM2021/</a>',
        },
        {
          type: 'text',
          text: 'All submissions will be peer-reviewed, and the demos or videos will be selected through a jury process. Please submit a two-page demo/video proposal (excluding references) in standard ACM MM 2021 format, <b>with the exception that the papers need not be blind.</b> Also each demo/video submission must contain a supplementary file, either in PowerPoint format (no more than 10 slides) or in video (no longer than 3 minutes) explaining to the jury:',
        },
        {
          type: 'retract',
          text: 'What is the scientific or engineering concept behind the work?',
        },
        {
          type: 'retract',
          text: 'What is the novelty of the work and how is the work dfferent from existing systems or techniques?',
        },
        {
          type: 'retract',
          text: 'What will be actually shown during the demo?',
        },
        {
          type: 'text',
          text: 'In addition, a PDF file of one-page description about your demo booth configuration may be provided if you have special requests. Please note that the submission system can accept only files with a maximum size of 100MB. If the total size of your supplementary materials exceeds this, please contact the demo/video chairs or arrange a way to submit the file.',
        },
        {
          type: 'primaryTitle',
          text: 'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type: 'noList',
          text: 'Submission deadline: July 18, 2021'
        },
        {
          type: 'noList',
          text: 'Notification of acceptance: July 27, 2021'
        },
        {
          type: 'noList',
          text: 'Camera-ready Submission: August 12, 2021',
        },
        {
          type: 'primaryTitle',
          text: 'Contacts',
        },
        {
          type: 'text',
          text: 'For any questions regarding submissions, please email the Technical Demos and Videos Program Chairs <a href="mailto:mm21-dvp@sigmm.org"> &lt;mm21-dvp@sigmm.org&gt;</a>:'
        },
        {
          type: 'text',
          text: '<span style="color:#0054B0">Demo Chairs:</span>'
        },
        {
          type: 'noListBlack',
          text: '<b>Jingkuan Song</b> (jingkuan.song@gmail.com), University of Electronic Science and Technology of China, China'
        },
        {
          type: 'noListBlack',
          text: '<b>Richang Hong</b> (jingkuan.song@gmail.com), (hongrc@hfut.edu.cn), Hefei University of Technology, China'
        },
        {
          type: 'text',
          text: '<span style="color:#0054B0">Video Program Chairs:</span>'
        },
        {
          type: 'noListBlack',
          text: '<b>Rainer Lienhart</b> (rainer.lienhart@informatik.uni-augsburg.de), University of Augsburg, Germany'
        },
        {
          type: 'noListBlack',
          text: '<b>Jitao Sang</b>  (jtsang@bjtu.edu.cn), Beijing Jiaotong University, China'
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Technical Demos and Videos Program'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>