<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "interactive-arts",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: '',
      contents:[
        {
          type:'primaryTitle',
          text:'Call for Interactive Artworks',
        },
        {
          type:'text',
          text:'ACM Multimedia is the premier international computer science conference in the area of multimedia. Multimedia research integrates and provides a holistic view over the multiple perspectives of digital modalities including images, text, music, video, sensor data, or spoken audio. As a long standing tradition, ACM Multimedia is calling for work addressing the artistic side of these perspectives. Specifically, we invite original works from practitioners and researchers creating innovative and culturally rich multimedia systems with artistic merit.',
        }, 
        {
          type:'text',
          text:'Given the recent developments in generative approaches for all modalities of multimedia, we seek works showcasing interactions of humans and machines with humanly and automatically created digital content, highlighting and reflecting upon creativity, discovery, and critical thinking on every aspect involved. We welcome works that especially deal with fusions, intersections, transformations, and paradigm shifts of human and AI generated technologies; works that provoke contemplation, address contemporary issues, and interactively engage viewers in discovery and stimulate intellectual adventure and creativity.',
        }, 
        {
          type:'text',
          text:'Exemplary Topics and Technologies comprise, but are not limited to, interactive arts using generative approaches, e.g., GANs, generative and adaptive image and sound installations, hybrid art, interactive media, digital comics, games, cultural heritage, VR/AR digital art, digital communities, digital humanism, mobile media, digital art performances, and (syn)aesthetic exploration.',
        }, 
        {
          type:'text',
          text:'Note that ACM Multimedia is also soliciting full technical papers addressing these topics. These papers should be submitted to the regular paper track under the theme  “Experience”. More details can be found in the <a class="demo-link" href="https://2021.acmmm.org/regular-papers">Call for Regular Papers</a>.',
        }, 
        {
          type:'text',
          text:'Curated and juried works will be presented from 20-24 October 2021 during the main conference in the main conference space. All submissions will be juried by the program committee. The committee will review submissions based on the following criteria:',
        }, 
        {
          type:'retract',
          text:'Originality of the artistic concept, aesthetic and/or critical quality.',
        },
        {
          type:'retract',
          text:'Unique and creative uses of media or technology.',
        },
        {
          type:'retract',
          text:'Relevance to the ACM Multimedia 2021 themes, especially the Interactive Arts theme.',
        },
        {
          type:'retract',
          text:'Prospect of realization/demonstration at exhibition site (e.g., required space or robustness and transportability of the installation).',
        },
        {
          type:'primaryTitle',
          text:'Submission Guidelines',
        },
        {
          type:'text',
          text:'Interested participants should submit a 2-page short paper/description including images and references in <a class="demo-link" href="https://www.acm.org/publications/proceedings-template"> standard ACM conference format </a>, as is used in the regular paper track, with the exception that the papers need not be blind.',
        }, 
        {
          type:'text',
          text:'In addition, authors also need to submit a project information sheet (following the <a class="demo-link" href="http://www.sigmm.org/webfm_send/122"> format here </a>) describing the necessary physical condition for the exhibit (size of works, spatial layout, power requirements, lighting condition, sound environment, Internet connection and other special requirements).',
        }, 
        {
          type:'text',
          text:'Optionally, but strongly suggested, authors should also submit a supplementary video demonstrating the artwork. For the video, the following restrictions apply: no longer than 3 minutes; max. file size of 100 MB (submission system restriction); mp4 file format; playable in the current version of VLC. Note: You can upload the video to CMT via the Author Console after creating the submission in the system.',
        }, 
        {
          type:'text',
          text:'Proposals should be submitted via the CMT submission system at <a class="demo-link" href="https://cmt3.research.microsoft.com/ACMMM2021/"> https://cmt3.research.microsoft.com/ACMMM2021/ </a> under the Interactive Arts track ("+ Create new submission…" → Interactive Arts).',
        }, 
        {
          type:'text',
          text:'The 2-page description of accepted artworks will be published in the ACM Multimedia 2021 Conference proceedings and included in the ACM Digital Library. Once accepted, participants are expected to bring with themselves everything needed for their artworks; hardware/equipment such as projectors, laptops, sensors, PCs, and power transformers (the local condition is 120V, 60Hz, Type-A/B). No financial support will be provided for exhibiting the artworks.',
        }, 
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Submission deadline: June 15, 2021',
        },
        {
          type:'noList',
          text:'Notification of acceptance: July 29, 2021',
        },
        {
          type: 'noList',
          text: 'Camera-ready Submission: August 12, 2021',
        },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For any questions regarding submissions, please email the Interactive Arts Chairs <a href="mailto:mm21-iac@sigmm.org"> &lt;mm21-iac@sigmm.org&gt;</a>:',
        },
        {
          type:'noListBlack',
          text:'<b>Klaus Schoeffmann</b>, Klagenfurt University, Austria',
        },
        {
          type:'noListBlack',
          text:'<b>Minjeong Kim</b>, The University of North Carolina at Greensboro, USA',
        },
        {
          type:'noListBlack',
          text:'<b>Ning Xie</b>, University of Electronic Science and Technology of China, China',
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Interactive Artworks'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";

</style>