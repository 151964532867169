<template>
  <div>
    <!--内容区域-->
    <el-row class="el-rows">
      <!--熊猫图标-->
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <!--title-->
      <el-col :span="22">
        <p class="title"> Important Dates </p>
      </el-col>
    </el-row>

    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'dateText'">
        <el-col class="dateText">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>

    <el-row class="dates-header">
      <el-col :span="18">
        <span class="header-text">SUBJECT</span>
      </el-col>
      <el-col :span="6">
        <span class="header-text">DATE</span>
      </el-col>
    </el-row>

    <div class="table-context">
      <el-row class="table-row" v-for="(item ,index) in table" :key="index">
        <router-link :to="item.link">
          <div v-if="item.bold" class="black" style="color:#000 !important;">
            <el-col :xs="15" :sm="15" :md="15" :lg="16" :xl="16">
              <span class="dates-text">{{ item.subject }}</span>
            </el-col>
            <el-col :xs="9" :sm="9" :md="8" :lg="8" :xl="8">
              <span class="delete-text" v-if="item.delete"><s>{{ item.date }}</s></span>
              <span class="dates-text" v-else v-html="item.date"></span>
            </el-col>
          </div>
          <div v-else>
            <el-col :xs="15" :sm="15" :md="15" :lg="16" :xl="16">
              <span class="dates-text">{{ item.subject }}</span>
            </el-col>
            <el-col :xs="9" :sm="9" :md="9" :lg="8" :xl="8">
              <span class="delete-text" v-if="item.delete"><s>{{ item.date }}</s></span>
              <span class="dates-text" v-else v-html="item.date"></span>
            </el-col>
          </div>
        </router-link>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dates",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      contents: [
        {
          type: 'dateText',
          text: 'Please note: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.',
        },
      ],
      table: [
        {
          subject: 'Grand Challenge Proposal Submission',
          date: 'January 24, 2021',
          link: "/multimedia-grand-challenge-proposals",
          delete: true,
          bold: false,
        },
        {
          subject: 'Reproducibility Companion Paper',
          date: 'February 7, 2021',
          link: "/reproducibility-companion-papers",
          delete: true,
          bold: false,
        },
        {
          subject: 'Grand Challenge Proposal Notification',
          date: 'February 14, 2021',
          link: "/important-dates",
          delete: true,
          bold: false,
        },
        {
          subject: 'Workshop Proposals Submission',
          date: 'March 6, 2021',
          link: "/workshop-proposals",
          delete: true,
          bold: false,
        },
        {
          subject: 'Regular Papers Submission (Abstract)',
          date: 'April 10, 2021',
          link: "/regular-papers",
          delete: true,
          bold: false,
        },
        {
          subject: 'Regular Papers Submission',
          date: 'April 17, 2021',
          link: "/regular-papers",
          delete: true,
          bold: true,
        },
        {
          subject: 'Tutorial Proposals Submission',
          date: 'June 7, 2021',
          link: "/tutorial-proposals",
          delete: true,
          bold: false,
        },
        {
          subject: 'Interactive Artworks Submission',
          date: 'June 15, 2021',
          link: "/interactive-arts",
          delete: true,
          bold: false,
        },
        {
          subject: 'Brave New ldeas Submission',
          date: 'June 21, 2021',
          link: "/brave-new-ideas-proposals",
          delete: true,
          bold: false,
        },
        {
          subject: 'Open Source Competition Submission',
          date: 'June 25, 2021',
          link: "/open-source-software-competition",
          delete: true,
          bold: false,
        },
        {
          subject: 'Tutorial Proposals Submission',
          date: 'June 26, 2021',
          link: "/tutorial-proposals",
          delete: true,
          bold: false,
        },
        {
          subject: 'Panel Proposals Submission',
          date: 'June 26, 2021',
          link: "/panel-proposals",
          delete: true,
          bold: false,
        },
        {
          subject: 'Regular Papers Notification',
          date: 'July 3, 2021',
          link: "/regular-papers",
          delete: true,
          bold: true,
        },
        {
          subject: 'Grand Challenge Solutions Submission',
          date: 'July 11, 2021',
          link: "/multimedia-grand-challenge-submissions",
          delete: true,
          bold: false,
        },
        {
          subject: 'Industrial Papers Submissions',
          date: 'July 15, 2021',
          link: "/industrial-track-proposal",
          delete: true,
          bold: false,
        },
        {
          subject: 'Technical Demo and Video Program Submission',
          date: 'July 18, 2021',
          link: "/technical-demos-and-videos-program",
          delete: true,
          bold: false,
        },       
        {
          subject: 'Doctoral Symposium Submission',
          date: 'August 1, 2021',
          link: "/doctoral-cymposium",
          delete: true,
          bold: false,
        }
      ]
    }
  }
}
</script>

<style scoped>

@import "../../css/responsive.css";


.icon {
  margin-top: .6rem;
  margin-right: 1rem;
  width: 3.4rem;
}

.title {
  margin-left: -1.8rem;
  text-align: left;
  font-size: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #202020;
}

.dates-header {
  height: 5rem;
  background-color: #5EAAFB;
}

.header-text {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-left: 3rem;
  line-height: 5rem;
  color: #FFFFFF;
}

.delete-text {
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #c0c4cc;
  margin-left: 3rem;
  line-height: 5rem;
}

.dates-text {
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #666666;
  margin-left: 3rem;
  line-height: 5rem;
}

.table-row {
  height: 5rem;
}

.table-row:hover {
  margin-top: .25rem;
  width: 101%;
  margin-left: -0.5%;
  margin-bottom: .25rem;
  -webkit-box-shadow: #666 0px 0px 10px;
  -moz-box-shadow: #666 0px 0px 10px;
  box-shadow: #666 0px 0px 10px;
  background-color: #fff;
}

.table-context {
  min-height: 50rem;
  border: 0.1rem solid #979797;
}

.black * {
  color: black !important;
  font-weight: bold;
}

@media screen and (max-width: 750px) {
  .icon {
    margin-top: 0rem;
  }

  .title {
    margin-left: 0;
  }
}
</style>
