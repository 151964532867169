<template>
  <div id="box">
    <ul id="con1"
        @mouseenter="stop"
        @mouseleave="start"
        ref="con1" :class="{anim:animate==true}">
      <li v-for='(item,index) in news' :key="index">
        <div class="content" v-html="item"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props:{
    news: Array
  },
  name: 'news',
  data() {
    return {
      animate: false,
      auto:true,
      items: this.news,
    }
  },
  created() {
    setInterval(this.scroll, 3000) // 在钩子函数中调用我在method 里面写的scroll()方法，注意此处不要忘记加this,我在这个位置掉了好几次坑，都是因为忘记写this。
  },
  methods: {
    start(){
      this.auto = true
      console.log('开始')
    },
    stop(){
      this.auto = false
      console.log("停止")
    },
    scroll() {
      if (this.auto){
        let con1 = this.$refs.con1;
        con1.style.marginTop = '-9.4rem';
        this.animate = !this.animate;
        var that = this; // 在异步函数中会出现this的偏移问题，此处一定要先保存好this的指向
        setTimeout(function () {
          that.items.push(that.items[0]);
          that.items.shift();
          con1.style.marginTop = '0rem';
          that.animate = !that.animate;  // 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了
        }, 1000)
      }
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.content{
  height: 9.4rem;
}

#box {
  width: 100%;
  height: 9.4rem;
  /*line-height: 9.4rem;*/
  overflow: hidden;
  transition: all 0.5s;
}

.anim {
  transition: all 1s;
}

#con1 li {
  cursor: pointer;
  list-style: none;
  width: 100%;
  height: 9.4rem;
  overflow:hidden;
  color: #0054B0;
}
</style>
