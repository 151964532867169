<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Call for Brave New Ideas',
      contents:[
        {
          type:'primaryTitle',
          text:'Overview',
        },
        {
          type:'text',
          text:'ACM Multimedia is the premier international conference in the area of multimedia within the field of computer science. Multimedia research focuses on technologies that enable the use and exchange of content integrating the multiple perspectives of different digital modalities, including images, text, video, audio, speech, music, and sensor data.',
        },
        {
          type:'text',
          text:'The Brave New Ideas (BNI) Track of ACM Multimedia 2021 is calling for innovative papers that open up new vistas for multimedia research and stimulate activity towards addressing new, long term challenges of interest to the multimedia research community. Submissions should be scientifically rigorous and also introduce fresh perspectives. The highlight of BNI 2021 is "Multimedia for Social Good", where innovative research showcasing the benefit to the general public are encouraged.',
        },
        {
          type:'text',
          text:'We understand "brave" to mean that a paper (or an area of research introduced by the paper) has great potential for high impact. For the proposed algorithm, technology or application to be understood as high impact, the authors should be able to argue that their proposal is important to solving problems, to supporting new perspectives, or to providing services that directly affect people’s lives.',
        },
        {
          type:'text',
          text:'We understand "new" to mean that an idea has not yet been proposed before. The component techniques and technologies may exist, but their integration must be novel.',
        },
        {
          type:'text',
          text:'The "Brave New Idea" of the paper can be backed up in one (or a combination) of three ways:',
        },
        {
          type:'retract',
          text:'by an extensive meta-review of existing work that will show that this idea can be productively pursued by the community.',
        },
        {
          type:'retract',
          text:'by an extensive review of existing work that reveals a systematic shortcoming in the way that the research community has been addressing specific challenges. The BNI paper could thus propose a contrarian view.',
        },
        {
          type:'retract',
          text:'by an experimental/theoretical validation of the paper\'s BNI thesis.',
        },
        {
          type:'text',
          text:'Note that is not necessary that papers in this track have large-scale experimental results or comparisons to the state-of-the-art, since it is expected that large, publicly available datasets may not be available, and there may be no existing approaches to which the proposed approach in the paper can be compared.',
        },
        {
          type:'text',
          text:'Submissions must be 6 - 8 pages in length and submitted to the “Brave New Ideas” Track for consideration. For more details on the format, please see the submission instructions in the main conference call for papers. <a class="demo-link" href="http://2021.acmmm.org/regular-papers"> http://2021.acmmm.org/regular-papers</a>',
        },
        {
          type:'text',
          text:'Submissions will be reviewed by a set of three independent reviewers, and the decisions will be made on the basis of their feedback. Review criteria are scientific impact, intellectual rigor, and a compelling case for the paper to be innovative in the sense described above.',
        },
        {
          type:'text',
          text:'<b>Important note for the authors: </b> The official publication date is the date the proceedings are made available in the ACM Digital Library. This date may be up to two weeks prior to the first day of the conference. The official publication date affects the deadline for any patent filings related to the published work.',
        },
        {
          type:'primaryTitle',
          text:'BNI FAQ',
        },
        {
          type:'text',
          text:'<b>1. What type of papers are suitable for the BNI track?</b>',
        },
        {
          type:'text',
          text:'The BNI track invites papers with brave and new ideas, where "brave" means “out-of-the-box thinking” ideas that may generate high impact and "new" means ideas not yet been proposed before. The highlight of BNI 2021 is "Multimedia for Social Good", where innovative research showcasing the benefit to the general public are encouraged.',
        },
        {
          type:'text',
          text:'<b>2. What is the format requirement for BNI papers?</b>',
        },
        {
          type:'text',
          text:'The paper format requirement is consistent with that of the regular paper (<a class="demo-link" href="https://2021.acmmm.org/regular-papers">https://2021.acmmm.org/regular-papers</a>.).',
        },
        {
          type:'text',
          text:'<b>3. When is the BNI paper submission deadline?</b>',
        },
        {
          type:'text',
          text:'The BNI paper submission deadline is June 21. <a class="demo-link" href="https://2021.acmmm.org/important-dates">https://2021.acmmm.org/important-dates</a>',
        },
        {
          type:'text',
          text:'<b>4. How selective is the BNI track?</b>',
        },
        {
          type:'text',
          text:'The BNI track is at least as competitive as the regular track. A BNI paper is regarded as respectful if not more compared to a regular paper. It is even more selective than the regular one with the acceptance rate at ~10% in previous years.',
        },
        {
          type:'text',
          text:'<b>5. When and How should the BNI paper be submitted? </b>',
        },
        {
          type:'text',
          text:'The BNI track is now open on the paper submission website. Please select the BNI (Brave New Idea) Track when you create your submission.',
        },
        {
          type:'text',
          text:'<b>6. How are the BNI papers published?</b>',
        },
        {
          type:'text',
          text:'The BNI papers are officially published in the conference proceeding.',
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Submission deadline: June 21, 2021',
        },
        {
          type:'noList',
          text:'Notification of acceptance: August 4, 2021',
        },
        {
          type:'noList',
          text:'Camera-ready deadline: August 18, 2021',
        },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For any questions regarding submissions, please email the Brave New Ideas Chairs <a href="mailto:mm21-bni@sigmm.org"> &lt;mm21-bni@sigmm.org&gt;</a>:',
        },
        {
          type:'noListBlack',
          text:'<b>Chang Wen Chen</b> (chencw@buffalo.edu), University at Baffalo, USA',
        },
        {
          type:'noListBlack',
          text:'<b>Zi Helen Huang</b> (huang@itee.uq.edu.au), The University of Queensland, Australia',
        },
        {
          type:'noListBlack',
          text:'<b>Qin Jin</b> (qjin@ruc.edu.cn), Renmin University of China, China',
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Brave New Ideas'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>