<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'img1'">
          <el-col>
            <img class="img" :src="Traffic" alt="Traffic information ">
          </el-col>
        </el-row>

      </div>
  </div>
</template>

<script>
export default {
name: "travel-information",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      Traffic: require("@/assets/Chengdu.jpg"),
      // Traffic: "/cnd-image/traffic.png",

      mainTitle: '',
      contents:[
        {
          type:'primaryTitle',
          text:'Traffic information in Chengdu',
        },
        {
          type:'img1',
          text:'图片',
        },

        {
          type:'secondaryBlack',
          text:'By Air:',
        },
        {
          type:'text',
          text:'<b>Chengdu Shuangliu International Airport</b> has a convenient route network to Europe, America, Africa, Asia and Oceania. As of May 31, 2020, 363 routes have been opened, of which 128 are international (regional) routes, 217 are domestic routes, and 18 are transiting domestically to international routes. Chengdu Shuangliu International Airport is located southwest of the central city of Shuangliu District, Chengdu, China. It is 16 kilometers away from the downtown area of Chengdu and is connected to the downtown area by expressways and subways. It takes about 30 to 50 minutes to drive to The Langbo Chengdu conference venue. (For more details, please Visit <a class="demo-link" href="http://www.cdairport.com">http://www.cdairport.com</a>)',
        },
        {
          type:'text',
          text:"<b>Chengdu Tianfu International Airport</b> is a 4F international airport, an international aviation hub, one of the highest-ranking airports in the Silk Road Economic Belt, and the main hub of Chengdu International Aviation Hub. Chengdu Tianfu International Airport is located at Konggang Avenue, Lujia Town, Jianyang City, Chengdu, Sichuan Province, China. It is 50 kilometers away from Chengdu, 50 kilometers away from Chengdu Shuangliu International Airport, and approximately 14.5 kilometers away from Jianyang. ",
        },
        {
          type:'secondaryBlack',
          text:'By Rail:',
        },
        {
          type:'text',
          text:"There are four large railway stations in the urban area of Chengdu. The first one is Chengdu Railway Station (Chengdubei Railway Station) in Jinniu District, which mainly stops at ordinary trains. Chengdu Metro Line 1 and Line 7 set up stations there. The second one is Chengdudong Railway Station in Chenghua District, it mainly handles most of Chengdu's intercity trains and high-speed EMU train services. Chengdu Metro Line 2 and Line 7 have stations there. The third is Chengdunan Railway Station in Wuhou District and it is responsible for the operation of Chengdu-Kunming Railway and Chengdu-Mianyang-Leshan Intercity Railway. Chengdu Metro Line 1 and Line 7 have stations there. The last is Chengduxi Railway Station in Qingyang District, Chengdu Metro Line 4 sets up a station there. Each train station is connected to the bus and subway, making it easy to transfer. (For more details, please Visit <a class='demo-link' href='https://www.12306.cn/index/'>https://www.12306.cn/index/</a> )",
        },
        {
          type:'secondaryBlack',
          text:'By Bus:',
        },
        {
          type:'text',
          text:'Many long-distance passenger stations in the urban area of Chengdu depart to different directions. Among them, what is commonly used in tourism is Chengbei Passenger Transport Center, Chengdu Beimen Bus Station, Wuguiqiao Bus Station, Chengdu Xinnanmen Bus Station, Chadianzi Bus Station, Zhaojue Temple Bus Station.',
        },
        {
          type:'text',
          text:'(For more details, please Visit <a class="demo-link" href="http://www.qichezhan.net/chengdu/">http://www.qichezhan.net/chengdu/</a>)',
        },
        {
          type:'secondaryBlack',
          text:'Local Transportation:',
        },
        {
          type:'text',
          text:'Chengdu has subways, buses, taxis, and shared bikes. The local transportation methods are flexible and diverse, and the combination of a variety of local transportation can reach all parts of Chengdu. (Visit for more  <a class="demo-link" href="https://www.chengdurail.com">https://www.chengdurail.com</a> and <a class="demo-link" href="https://chengdu.8684.cn/">https://chengdu.8684.cn/</a>)',
        },
        {
          type:'secondaryBlack',
          text:'DISCOVER CHENGDU:',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="http://cdwglj.chengdu.gov.cn/cdwglj/index.shtml">http://cdwglj.chengdu.gov.cn/cdwglj/index.shtml</a>',
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Traffic information'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";
.img {
    width: 100%;
}
</style>
