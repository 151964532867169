<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'wechat'">
          <el-col>
            <img class="h1_wechat" :src="hotel1_wechat" alt="">
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'hotel1'">
          <el-col class='h_img'>
            <img class="h1_1" :src="hotel1_2" alt="">
            <img class="h1_2" :src="hotel1_1" alt="">
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'hotel2'">
          <el-col class='h_img'>
            <img class="h1_1" :src="hotel2_2" alt="">
            <img class="h1_2" :src="hotel2_1" alt="">
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'hotel3'">
          <el-col class='h_img'>
            <img class="h1_1" :src="hotel3_1" alt="">
            <img class="h1_2" :src="hotel3_2" alt="">
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'hotel4'">
          <el-col class='h_img'>
            <img class="h4_1" :src="hotel4_2" alt="">
            <img class="h4_2" :src="hotel4_1" alt="">
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'hotel5'">
          <el-col class='h_img'>
            <img class="h1_1" :src="hotel5_1" alt="">
            <img class="h1_2" :src="hotel5_2" alt="">
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "recommended-accommodation",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      hotel1_wechat: "/cnd-image/hotel1_wechat.png",
      hotel1_1: "/cnd-image/hotel1_1.png",
      hotel1_2: "/cnd-image/hotel1_2.png",
      hotel2_1: "/cnd-image/hotel2_1.png",
      hotel2_2: "/cnd-image/hotel2_2.png",
      hotel3_1: "/cnd-image/hotel3_1.png",
      hotel3_2: "/cnd-image/hotel3_2.png",
      hotel4_1: "/cnd-image/hotel4_1.png",
      hotel4_2: "/cnd-image/hotel4_2.png",
      hotel5_1: "/cnd-image/hotel5_1.png",
      hotel5_2: "/cnd-image/hotel5_2.png",
      mainTitle: '',
      contents:[
        {
          type:'primaryTitle',
          text:"Recommended Accommodation",
        },
        {
          type:'secondaryTitle',
          text:'1. Zenec International Hotel (Chengdu Chunxi Tai Koo Li)',
        },
        {
          type:'text',
          text:'<b>Corporate Rate</b>: 350CNY/Per Night, Breakfast included.',
        },
        {
          type:'text',
          text:'<b>Room Type</b>: Twin Bed Room/ Double Bed Room ',
        },
        {
          type:'text',
          text:'<b>Walk Distance</b>: 0.8KM away from the Conference Venue (The Langbo Chengdu)',
        },
        {
          type:'text',
          text:'<b>Walk Time</b>: 12~20mins',
        },
        {
          type:'text',
          text:'<b>Booking Instruction</b>: Please scan below  QR code for reservation.',
        },
        {
          type:'wechat',
          text:'图片',
        },
        {
          type:'hotel1',
          text:'图片',
        },

        {
          type:'secondaryTitle',
          text:'2. Xinliang Hotel',
        },
        {
          type:'text',
          text:'<b>Official Website</b>:  <a class="demo-link" href="http://xinliang.hotel00.com">http://xinliang.hotel00.com</a>',
        },
        {
          type:'text',
          text:'<b>Corporate Rate</b>: 370CNY/Per Night, Breakfast included.',
        },
        {
          type:'text',
          text:'<b>Room Type</b>: Business Twin Room / Business Queen Room',
        },
        {
          type:'text',
          text:'<b>Walk Distance</b>: 1.2KM away from the Conference Venue (The Langbo Chengdu)',
        },
        {
          type:'text',
          text:'<b>Walk Time</b>: 18~20mins',
        },
        {
          type:'text',
          text:'<b>Booking Instruction</b>: Contact Manager Dai +86-18615702633 and inform the meeting name ACM MM 2021 or MM 2021 to make a reservation.',
        },
        {
          type:'hotel2',
          text:'图片',
        },

        {
          type:'secondaryTitle',
          text:'3. Minyoun Chengdu Dongda Hotel',
        },
        {
          type:'text',
          text:'<b>Official Website</b>:  <a class="demo-link" href=" http://minyounroyalhotelchengdu.com">http://minyounroyalhotelchengdu.com</a>',
        },
        {
          type:'text',
          text:'<b>Corporate Rate</b>: 650CNY/Per Night; 700CNY/Per Night; Breakfast included.',
        },
        {
          type:'text',
          text:'<b>Room Type</b>:  Classic / Executive King Room; Classic / Executive Twin-bed Room',
        },
        {
          type:'text',
          text:'<b>Walk Distance</b>: 1KM away from the Conference Venue (The Langbo Chengdu)',
        },
        {
          type:'text',
          text:'<b>Walk Time</b>: 14~20mins',
        },
        {
          type:'text',
          text:'<b>Booking Instruction</b>: Contact Longwen +86-18380475239 and inform the conference name ACM MM 2021 or MM 2021 to make a reservation.',
        },
        {
          type:'hotel3',
          text:'图片',
        },
        {
          type:'secondaryTitle',
          text:'4. Rhombus Park Aura Chengdu Hotel',
        },
        {
          type:'text',
          text:'<b>Official Website</b>:  <a class="demo-link" href="https://rhombuschengduhotel.com">https://rhombuschengduhotel.com</a>',
        },
        {
          type:'text',
          text:'<b>Corporate Rate</b>: 680CNY/Per Night, Breakfast included(Single breakfast)',
        },
        {
          type:'text',
          text:'<b>Room Type</b>: G50 King Deluxe Room/ G50 Twin Deluxe Suite',
        },
        {
          type:'text',
          text:'<b>Walk Distance</b>:  0.3KM away from the Conference Venue (The Langbo Chengdu)',
        },
        {
          type:'text',
          text:'<b>Walk Time</b>:  3~5mins',
        },
        {
          type:'text',
          text:'<b>Booking Instruction</b>: Contact He Ran +86-15378160608 and inform the conference name ACMMM or ACM Multimedia Conference to make a reservation.',
        },
        {
          type:'hotel4',
          text:'图片',
        },
        {
          type:'secondaryTitle',
          text:'5. The Langbo Chengdu, in The Unbound Collection by Hyatt',
        },
        {
          type:'text',
          text:'<b>Official Website</b>:  <a class="demo-link" href="https://www.hyatt.com/zh-CN/hotel/china/the-langbo-chengdu/ctuub">https://www.hyatt.com/zh-CN/hotel/china/the-langbo-chengdu/ctuub</a>',
        },
        {
          type:'text',
          text:'<b>Corporate Rate</b>: 1000CNY/Per Night, Breakfast included',
        },
        {
          type:'text',
          text:'<b>Room Type</b>: Basic Room (Limited numbers, please book as soon as possible)',
        },
        {
          type:'text',
          text:'<b>Walk Distance</b>: Conference Venue',
        },
        {
          type:'text',
          text:'<b>Booking Instruction</b>: Contact +86-18280103813 and inform the conference name ACMMM or ACM Multimedia Conference to make a reservation.',
        },
        {
          type:'hotel5',
          text:'图片',
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Recommended Accommodation'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";
.img_logo {
    width: 28%;
    margin-top: 1.8rem;
    vertical-align: top;
    /*display: inline-block;*/
}
.h_img {
  float: left;
  /*background: #FFFFFF;*/
  /*margin-left: 2rem;*/
  margin-right: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.h1_1 {
    /*width: 48%;*/
    height: 36rem;
    margin-top: .5rem;
    /*margin-left: 2rem;*/
    vertical-align: top;
    /*display: inline-block;*/
}
.h1_2 {
    /*width: 48%;*/
    height: 36rem;
    margin-top: .5rem;
    margin-left: 2rem;
    vertical-align: top;
    /*display: inline-block;*/
}
.h4_1 {
    /*width: 48%;*/
    height: 33rem;
    margin-top: .5rem;
    /*margin-left: 2rem;*/
    vertical-align: top;
    /*display: inline-block;*/
}
.h4_2 {
    /*width: 48%;*/
    height: 33rem;
    margin-top: .5rem;
    margin-left: 2rem;
    vertical-align: top;
    /*display: inline-block;*/
}
.h1_wechat {
    width: 15%;
    margin-top: .5rem;
    margin-left: 18rem;
    vertical-align: top;
    /*display: inline-block;*/
}
</style>
