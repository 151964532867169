<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "Call for Sponsors",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Call for Sponsors',
      contents:[
        {
          type:'text',
          text:'ACM Multimedia is the premier international conference in the area of multimedia. It covers multiple emerging fields focusing on advancing the research and applications of many media, including but not limited to text, image, audio, speech, music, video, sensor and social data. It strongly encourages a complete and integrated approach to exchange, process and utilize information across modalities, as well as all cutting-edge research on each medium with potential of great positive impacts on everyday lives and technological breakthroughs. While the community has a tradition of developing and innovating AI and system approaches to handle big data and improve users\' experiences on engaging and interacting with multimedia, it is also uniquely angled towards novel applications and urgent industrial challenges. As such the conference openly embraces new intellectual perspectives from both industry and academia, and welcomes submissions from related fields, such as artificial intelligence, vision and languages, data sciences, HCI and multimedia signal processing, as well as healthcare, education and beyond.'+
          '<br>The conference is structured around four themes detailed on the conference website:',
        },
        {
          type:'noList',
          text:'Engaging users with multimedia',
        },
        {
          type:'noList',
          text:'Multimedia experience',
        },
        {
          type:'noList',
          text:'Multimedia systems',
        },
        {
          type:'noList',
          text:'Understanding multimedia content',
        },
        {
          type:'primaryTitle',
          text:'Levels of Participation',
        },
        {
          type:'text',
          text:"Pricing is based on exclusivity and valuable sponsorship benefits, which will be assigned on a first-come first-served basis. Full registration gives access to the whole conference, while Exhibition registration only covers access to the sponsor's exhibit area, centrally located between registration, main conference rooms and coffee breaks.",
        },
        {
          type:'secondaryTitle',
          text:'Diamond Level (20000 USD or more)',
        },
        {
          type:'noListBlack',
          text:"Five free full registrations, Three free exhibition registrations,",
        },
        {
          type:'noListBlack',
          text:"Free exhibit space with two tables, four chairs and a power outlet,",
        },
        {
          type:'noListBlack',
          text:"Logo on the ACM MM'21 website (under Diamond Level),",
        },
        {
          type:'noListBlack',
          text:"Extra Large Logo on the handbook and conference proceedings (under Diamond Level),",
        },
        {
          type:'noListBlack',
          text:'Company introduction video: 8 minutes, circulation broadcast during main conference days,',
        },
        {
          type:'noListBlack',
          text:'One-page Advertisement in program booklet,',
        },
        {
          type:'noListBlack',
          text:'Link to the homepage of the sponsor company on the ACM MM\'21 website.',
        },
        {
          type:'noListBlack',
          text:'Certificate of appreciation at the Banquet.',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'secondaryTitle',
          text:'Platinum Level (10000 USD)',
        },
        {
          type:'noListBlack',
          text:'Three free full registrations, Three free exhibition registrations,',
        },
        {
          type:'noListBlack',
          text:'Free exhibit space with two tables, four chairs and a power outlet,',
        },
        {
          type:'noListBlack',
          text:'Logo on the ACM MM\'21 website (under Platinum Level),',
        },
        {
          type:'noListBlack',
          text:'Large Logo on the handbook and conference proceedings (under Platinum Level)',
        },
        {
          type:'noListBlack',
          text:'Company introduction video: 5 minutes, circulation broadcast during main conference days,',
        },
        {
          type:'noListBlack',
          text:'One-page Advertisement in program booklet,',
        },
        {
          type:'noListBlack',
          text:'Link to the homepage of the sponsor company on the ACM MM\'21 website.',
        },
        {
          type:'noListBlack',
          text:'Certificate of appreciation at the Banquet.',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'secondaryTitle',
          text:'Gold Level (5000 USD)',
        },
        {
          type:'noListBlack',
          text:'Two free full registrations, Two free exhibition registrations,',
        },
        {
          type:'noListBlack',
          text:'Free exhibit space with one table, two chairs and a power outlet,',
        },
        {
          type:'noListBlack',
          text:'Logo on the ACM MM\'21 website (under Gold Level),',
        },
        {
          type:'noListBlack',
          text:'Medium Logo on the handbook and conference proceedings (under Gold Level),',
        },
        {
          type:'noListBlack',
          text:'Link to the homepage of the sponsor company on the ACM MM\'21 website.',
        },
        {
          type:'noListBlack',
          text:'Certificate of appreciation at the Banquet.',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'secondaryTitle',
          text:'Silver Level (3000 USD)',
        },
        {
          type:'noListBlack',
          text:'One free full registration, Two free exhibition registrations,',
        },
        {
          type:'noListBlack',
          text:'Free exhibit space with a table, two chairs and a power outlet,',
        },
        {
          type:'noListBlack',
          text:'Logo on the ACM MM\'21 website (under Silver Level),',
        },
        {
          type:'noListBlack',
          text:'Logo on the handbook and conference proceedings (under Silver Level)',
        },
        {
          type:'noListBlack',
          text:'Link to the homepage of the sponsor company on the ACM MM\'21 website.',
        },
        {
          type:'noListBlack',
          text:'Certificate of appreciation at the Banquet.',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'secondaryTitle',
          text:'Start-up Level (1500 USD) (for start-up of up to 10 employees only)',
        },
        {
          type:'noListBlack',
          text:'One free exhibition registration,',
        },
        {
          type:'noListBlack',
          text:'Free exhibit space with a table, two chairs and a power outlet,',
        },
        {
          type:'noListBlack',
          text:'Logo on the ACM MM\'21 website (under Bronze Level),',
        },
        {
          type:'noListBlack',
          text:'Logo on the handbook and conference proceedings (under Bronze Level)',
        },
        {
          type:'noListBlack',
          text:'Link to the homepage of the sponsor company on the ACM MM\'21 website.',
        },
        {
          type:'noListBlack',
          text:'Certificate of appreciation at the Banquet.',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'secondaryTitle',
          text:'Non-profit Sponsor (1200 USD) (for non-profit organizations only)',
        },
        {
          type:'noListBlack',
          text:'One free exhibition registration,',
        },
        {
          type:'noListBlack',
          text:'Free exhibit space with a table, two chairs and a power outlet,',
        },
        {
          type:'noListBlack',
          text:'Logo on the ACM MM\'21 website (under Bronze Level),',
        },
        {
          type:'noListBlack',
          text:'Logo on the handbook and conference proceedings (under Bronze Level)',
        },
        
        {
          type:'noListBlack',
          text:'Link to the homepage of the sponsor company on the ACM MM\'21 website.',
        },
        {
          type:'noListBlack',
          text:'Certificate of appreciation at the Banquet.',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'text',
          text:' ',
        },
        {
          type:'secondaryTitle',
          text:'Additional Sponsoring Options and Opportunities (contact sponsorship chairs <a href="mailto:mm21-sponsor@sigmm.org"> &lt;mm21-sponsor@sigmm.org&gt;</a> for details)',
        },
        {
          type:'noListBlack',
          text:'Booth – Gold sponsors and above can purchase their own little private exhibition area.',
        },
        {
          type:'noListBlack',
          text:'Food/Drinks – Sponsor one (or more) of the lunch break(s) buffet or reception and have your logo displayed on the projection screen(s) during the event and the opportunity to set out swag on the tables, if desired.',
        },
        {
          type:'noListBlack',
          text:'Banquet Entertainment – Support memorable artistic performance of your choice at the conference banquet.',
        },
        {
          type:'noListBlack',
          text:'Best paper award(s).',
        },
        {
          type:'noListBlack',
          text:'Travel grants for students.',
        },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For questions regarding the sponsors you can email the sponsor chairs <a href="mailto:mm21-sponsor@sigmm.org"> &lt;mm21-sponsor@sigmm.org&gt;</a> or <a href="mailto:yanliji@uestc.edu.cn"> &lt;yanliji@uestc.edu.cn&gt;</a>:'
        },
        {
          type:'noListBlack',
          text:'<b>Tao Mei</b> (tmei@jd.com), JD.COM, China'
        },
        {
          type:'noListBlack',
          text:'<b>Qi Tian</b> (tian.qi1@uawei.com), Huawei Cloud & AI, China'
        },
        {
          type:'noListBlack',
          text:'<b>Yanli Ji</b> (yanliji@uestc.edu.cn), UESTC, China'
        },
        
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Call for Sponsors'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>