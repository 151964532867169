<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{content.text}}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "multimedia-grand-challenge",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Multimedia Grand Challenges',
      contents:[
        {
          type:'text',
          text:'The Multimedia Grand Challenge was first presented as part of ACM Multimedia 2009 and has established itself as a prestigious competition in the multimedia community. The purpose of the Multimedia Grand Challenge is to engage the multimedia research community by establishing well-defined and objectively judged challenge problems intended to exercise the state-of-the-art methods and inspire future research directions. The key criteria for Grand Challenges are that they should be useful, interesting, and their solution should involve a series of research tasks over a long period of time, with pointers towards longer-term research.'
        },
        {
          type:'text',
          text:'The purpose of the Multimedia Grand Challenge is to engage the multimedia research community by establishing well-defined and objectively judged challenge problems intended to exercise the state-of-the-art methods and inspire future research directions. The key criteria for Grand Challenges are that they should be useful, interesting, and their solution should involve a series of research tasks over a long period of time, with pointers towards longer-term research.'
        },
        {
          type:'text',
          text:'The Multimedia Grand Challenge proposals accepted for the ACM Multimedia 2021 edition are the following:'
        },
        


        {
          type:'secondaryBlack',
          text:'Deep Video Understanding Challenge',
        },
        {
          type:'text',
          text:'<a class="demo-link" href=" https://sites.google.com/view/dvuchallenge2021/home/"> https://sites.google.com/view/dvuchallenge2021/home/</a>',
        },
        {
          type:'secondaryBlack',
          text:'Pre-training for Video Understanding Challenge',
        },
        {
          type:'text',
          text:'<a class="demo-link" href=" http://auto-video-captions.top/2021/"> http://auto-video-captions.top/2021/</a>',
        },
        {
          type:'secondaryBlack',
          text:'Robust Logo Detection',
        },
        {
          type:'text',
          text:'<a class="demo-link" href=" https://s.alibaba.com/challenge/ACMMM2021ChallengeRobustLogoDetection/"> https://s.alibaba.com/challenge/ACMMM2021ChallengeRobustLogoDetection/</a>',
        },
        {
          type:'secondaryBlack',
          text:'Delay sensitive multimedia Challenge: Meet Deadline Requirements',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://www.aitrans.online/MMGC2021/"> https://www.aitrans.online/MMGC2021/</a>',
        },
        {
          type:'secondaryBlack',
          text:'The 3rd Video Relation Understanding Challenge',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://videorelation.nextcenter.org"> https://videorelation.nextcenter.org</a>',
        },
        {
          type:'secondaryBlack',
          text:'GigaTracking: When Multi-Object Tracking meets Gigapixel Videography',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="http://www.gigavision.cn/ACMMM2021_main.html"> http://www.gigavision.cn/ACMMM2021_main.html</a>',
        },
        {
          type:'secondaryBlack',
          text:'Multi-modal ads video understanding',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://algo.qq.com/index.html?rfisource=ACM"> https://algo.qq.com/index.html?rfisource=ACM/</a>',
        },
        {
          type:'secondaryBlack',
          text:'MultiMediate: Multi-modal Group Behaviour Analysis for Artificial Mediation',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://multimediate-challenge.org/"> https://multimediate-challenge.org/</a>',
        },
        {
          type:'secondaryBlack',
          text:'Facial Micro-Expression (FME) Challenge',
        },
        {
          type:'text',
          text:'<a class="demo-link" href="https://megc2021.github.io/"> https://megc2021.github.io/</a>',
        }
        
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Multimedia Grand Challenge'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>