<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "student-travel-grants",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: '',
      contents:[
        {
          type:'primaryTitle',
          text:'Student Travel Grants',
        },
        {
          type:'secondaryTitle',
          text:'Application Procedure',
        },
        { 
          type:'text',
          text:'SIGMM is sponsoring Student Travel Awards for ACMMM 2021 to allow attendees to avail of financial support to allow them to more fully engage with the event. Applications are open between August 20, 2021 to September 30, 2021, and more details are available <a class="demo-link" href="https://acmsigmm.wufoo.com/forms/sigmm-student-travel-application-form/">here</a>.',
        },
        // {
        //   type:'text',
        //   text:'The ACM International Conference on Multimedia is the flagship conference of ACM SIGMM. It provides a great opportunity for researchers to present their work, get acquainted with the work of others, and discuss with colleagues both junior and senior. To promote participation of students in the conference events and in special student-oriented activities, ACM SIGMM, and ACM MM 2021 organization committee will offer travel grants for a large number of students.',
        // },

        // {
        //   type:'text',
        //   text:'The grant is meant to support personal attendance of one student at the main conference. The applicant must be a SIGMM student member at the time of application (Not a SIGMM member? Click <a class="demo-link" href="https://services.acm.org/public/qj/gensigqj/login_gensigqj.cfm?promo=QJSIG&offering=044&form_type=SIG">here</a>  to join today!) and the selected students for the grant will Students selected for the grant will receive a fixed sum of 3000 CNY for those from China and 1,000 USD for other areas. Grantees need to pay the conference registration fee and make their own travel arrangements.',
        // },
        // {
        //   type:'primaryTitle',
        //   text:'Student Volunteers',
        // },
        // {
        //   type:'text',
        //   text:'Students who are awarded student travel grants are expected to carry out light service as student volunteers during the conference.',
        // },
        // {
        //   type:'primaryTitle',
        //   text:'Selection Criteria',
        // },
        // {
        //   type:'text',
        //   text:"Students being the primary authors of an accepted paper in the main conference or workshops will be given preference in the selection process. Students who participate in the Open Source Competition and Multimedia Grand Challenges will be given high priority. Students without papers accepted for publication at ACMMM 2021, but involved in research closely related to the topics of ACMMM will be considered. Female and minority students' applications are especially encouraged.",
        // },
        // {
        //   type:'primaryTitle',
        //   text:'Application Procedure',
        // },
        // {
        //   type:'text',
        //   text:'Students are encouraged to have the applications sent as soon as possible. Applications arriving after the deadline will not be considered. Please include the following materials (in the PDF format) in an application:',
        // },
       
        // {
        //   type:'textBlue',
        //   text:"1. A certificate proving student status from a faculty member of the student's host institution (preferably from academic/dissertation advisors, signature and stamp mandatory);",
        // },
        // {
        //   type:'textBlue',
        //   text:'2. An application letter from the student with the following information in tabular format;',
        // },
        // {
        //   type:'retract',
        //   text:"The faculty's full name and email address, student's full name and email address;",
        // },
        // {
        //   type:'retract',
        //   text:'The name and full address of the institution;',
        // },
        // {
        //   type:'retract',
        //   text:"The student's academic status and expected year of graduation;",
        // },
        // {
        //   type:'retract',
        //   text:'Whether the student is a (co-)author of a paper that was accepted for the main conference or if the student contributes to any other part of the conference;',
        // },
        // {
        //   type:'retract',
        //   text:"If the student is a (co-)author, the paper title, author list, the student's contribution to the paper, and whether the student will present the paper;",
        // },
        // {
        //   type:'retract',
        //   text:'The reason for applying for the grant, including the expected benefits that the students will have from attending the conference, research significance, the funding status of the student, and the budget of the student travel;',
        // },
        // {
        //   type:'retract',
        //   text:'A brief statement by the student about his or her current research interests and research accomplishments to date, or a link to his/her CV.',
        // },
        // {
        //   type:'retract',
        //   text:'A one-page abstract written in English by the student, summarizing a broad view of his/her research work with motivation, problem description, approach, and progress.',
        // },
        // {
        //   type:'text',
        //   text:'Please combine the application documents into a single PDF file in the order outlined above. Email the document to the Student Travel Grant Chairs <a href="mailto:fumin.shen@gmail.com"> &lt;fumin.shen@gmail.com&gt;</a> as an attachment.'
        // },
        // {
        //   type:'primaryTitle',
        //   text:'Important Dates',
        // },
        // {
        //   type:'text',
        //   text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        // },
        // {
        //   type:'noList',
        //   text:'Application submission deadline: September 15, 2021',
        // },
        // {
        //   type:'noList',
        //   text:'Notification: September 30, 2021',
        // },
        // {
        //   type:'primaryTitle',
        //   text:'Student Travel Grant Chairs',
        // },
        // {
        //   type:'noListBlack',
        //   text:'<b>Fumin Shen</b> (fumin.shen@gmail.com), University of Electronic Science and Technology of China',
        // },
        // {
        //   type:'noListBlack',
        //   text:'<b>Huimin Lu</b> (dr.huimin.lu@ieee.org), Kyushu Institute of Technology, Japan',
        // }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Student Travel Grants'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";
</style>