<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Call for Open Source Software Competition Proposals',
      contents:[
        {
          type:'primaryTitle',
          text:'Introduction',
        },
        {
          type:'text',
          text:'ACM Multimedia is the flagship conference in the area of multimedia within the field of computer science. Multimedia research focuses on technologies that enable the use and exchange of content integrating the multiple perspectives of different digital modalities, including images, text, video, audio, speech, music, and sensor data.',
        },
        {
          type:'text',
          text:'The Open Source Software Competition is an important part of the ACM Multimedia program. The competition, now in its 18th edition, is intended to celebrate, encourage and promote the contribution of researchers, software developers and educators to advance the field by providing the community with implementations of codecs, middleware, frameworks, toolkits, libraries, multimedia players, applications, authoring tools, and other multimedia software. These resources advance the field by providing a common set of tools for building and improving multimedia research prototypes. The use of these tools also allows others to replicate research results more easily.',
        },
        {
          type:'text',
          text:'To qualify, the software must be provided with source code and licensed in such a manner that it can be used free of charge in academic and research settings. As part of the review process, the software will be built from the sources. All source code, license, installation instructions, and other documentation must be available on a public web page or on publicly available software repositories such as GitHub, Bitbucket, etc. License compatibility with other open source software is encouraged. Dependencies on non-open source third-party software are discouraged, with the exception of operating systems and freely available commercial packages.',
        },
        {
          type:'text',
          text:'Authors are encouraged to prepare as much documentation as possible, including examples of how the provided software might be used, existing prototypes that use the software, video demos, download statistics or other public usage information. Entries will be selected for inclusion in the conference program based on peer-review. The criteria for judging submissions include broad applicability and potential impact, novelty, technical depth, demo suitability, and other miscellaneous factors (e.g., maturity, popularity, compatibility with commonly used programming languages, no dependence on closed source, documentation quality, etc.)',
        },
        {
          type:'text',
          text:'Authors of selected entries will be invited to present and demonstrate their software as part of the regular conference program. In addition, accepted overview papers will be included in the conference proceedings. An overall winning entry, as judged by reviewers and the program committee, will be recognized formally at ACM Multimedia 2021.',
        },
        {
          type:'primaryTitle',
          text:'Submission Guidelines',
        },
        {
          type:'text',
          text:'Authors interested in submitting an entry to the ACM Multimedia Open Source Software Competition should make their software contribution available by providing a public URL for download and prepare a package containing the following information to be submitted via the online submission system under the Open Source Software Competition track:',
        },
        {
          type:'noListBlack',
          text:'A PDF file containing a 4-page max paper including an overview of the open source software package, its description, applications, intended audience, main features, etc. This overview paper should be prepared using the same guidelines and formatting as for technical papers, although due to the nature of this submission, reviews will not be double-blind. Authors will be required to sign copyright to the ACM.',
        },
        {
          type:'noListBlack',
          text:'A text or PDF file, containing the following information:',
        },
        {
          type:'retract',
          text:'Title of submission.',
        },
        {
          type:'retract',
          text:'Names and affiliations of authors (indicate students).',
        },
        {
          type:'retract',
          text:'In case of a large distributed project, include full list of contributors, if possible, and indicate main contact (e.g., project owner or maintainer).',
        },
        {
          type:'retract',
          text:'Submitters are also encouraged to tag their paper with up to two areas from the main track (see Areas for a list of the areas).',
        },
        {
          type:'retract',
          text:'A link to a compressed archive file that contains the software.',
        },
        {
          type:'retract',
          text:'The permanent link for the open source software (e.g., GitHub, Bitbucket, Sourceforge, Google Code, SVN).',
        },
        {
          type:'text',
          text:'Authors are strongly encouraged to ease the task of testing and building their code using containers or VMs, e.g., using Docker or Vagrant, or using packet manager systems, e.g., NuGet, CPM, BiiCode, Maven, Bower, etc.',
        },
        {
          type:'text',
          text:'The compressed archive file with all source code, documentation, build/install instructions, and licenses must be placed in a web accessible location. The public URL for project page where software, documentation, and open source license can be found must be included in the overview paper. Comprehensive and clear build/install instructions will be a crucial component of any submission. The evaluation committee will make reasonable effort to build the software for the top contributions, but if they are unable to make the software run, it will be excluded from the competition.',
        },
        {
          type:'text',
          text:'These documents should be submitted through the submission system.',
        },
        {
          type:'text',
          text:'<a class="demo-link" href=" https://cmt3.research.microsoft.com/ACMMM2021/"> https://cmt3.research.microsoft.com/ACMMM2021/</a>.',
        },
        {
          type:'text',
          text:'Please submit the paper in the PDF format and the supplementary file as a ZIP archive.',
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Submission Deadline: 25 June, 2021'
        },
        {
          type:'noList',
          text:'Notification of acceptance: 26 July, 2021'
        },
        {
          type: 'noList',
          text: 'Camera-ready Submission: 12 August, 2021',
        },
        // {
        //   type:'noList',
        //   text:'Notification of acceptance: July 15, 2020'
        // },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For any questions regarding submissions, please email the Open Source Competition Chairs <a href="mailto:mm21-osc@sigmm.org"> &lt;mm21-osc@sigmm.org&gt;</a>:'
        },
        {
          type:'noListBlack',
          text:'<b>Jingdong Wang</b> (jingdw@microsoft.com), Microsoft Research Asia, China'
        },
        {
          type:'noListBlack',
          text:'<b>Roger Zimmermann</b> (rogerz@comp.nus.edu.sg), National University of Singapore, Singapore'
        },
        {
          type:'noListBlack',
          text:'<b>Toshihiko Yamasaki</b> (yamasaki@hal.t.u-tokyo.ac.jp), The University of Tokyo, Japan'
        },
        {
          type:'noListBlack',
          text:'<b>Qi Dai</b> (qi.dai@microsoft.com), Microsoft Research Asia, China'
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Open Source Software Competition Proposals'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>