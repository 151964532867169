<template>
  <div>
    <!--第一部分-->
    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> News and Updates </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">2021-10-25</p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="content-text">
        ACM Multimedia 2021 has concluded successfully. Please check out the photos of highlights in the <b><a class="demo-link" href="http://y.camera360.com/photolivepc/?orderId=202110151228275788&channel=h5&origin=qrcode">Photo Gallery</a></b>.
        </p>
      </el-col>
    </el-row>



    <el-row>
      <el-col>
        <p class="headset">2021-10-22</p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="content-text">
          Congratulations to all the award winners of ACM Multimedia 2021! Click <b><a class="demo-link" href="https://2021.acmmm.org/awards">here</a></b> for more detailed information.

       <!--  Congratulations to all the winners. For more details, please find in <b><a class="demo-link" href="https://2021.acmmm.org/awards">Awards</a></b>. -->
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="content-text">
        <font color="#3279B7"><b>Best Paper Award</b></font> <br>
        "Video Background Music Generation with Controllable Music Transformer"<br>
        <font color="#5b5b5b"> Shangzhe Di, Zeren Jiang, Si Liu*, Zhaokai Wang, Leyan Zhu, Zexin He, Hongming Liu, Shuicheng Yan</font>
         </p>
      </el-col>
    </el-row>

 <el-row>
      <el-col>
        <p class="content-text">
        <font color="#3279B7"><b>Best Student Paper Award</b></font> <br>
        "aBio: Active Bi-Olfactory Display Using Subwoofers for Virtual Reality"<br>
        <font color="#5b5b5b">You-Yang Hu*, Yao-Fu Jan, Kuan-Wei Tseng, You-Shin Tsai, Hung-Ming Sung, Jin-Yao Lin, Yi-Ping Hung</font>
         </p>
      </el-col>
    </el-row>


    <el-row>
      <el-col>
        <p class="content-text">
        <font color="#3279B7"><b>Best Demo Award</b></font> <br>
        "ViDA-MAN: Visual Dialog with Digital Humans" <br>
        <font color="#5b5b5b"> Tong Shen*, Jiawei Zuo, Fan Shi, Jin Zhang, Liqin Jiang, Meng Chen, Zhengchen Zhang, Wei Zhang, Xiaodong He, Tao Mei</font>
         </p>
      </el-col>
    </el-row>


    <el-row>
      <el-col>
        <p class="content-text">
        <font color="#3279B7"><b>Best Open Source Award</b></font> <br>
        "X-modaler: A Versatile and High-performance Codebase for Cross-modal Analytics"<br>
        <font color="#5b5b5b">Yehao Li, Yingwei Pan*, Jingwen Chen, Ting Yao, Tao Mei</font>
         </p>
      </el-col>
    </el-row>


    <el-row>
      <el-col>
        <p class="content-text">
        <font color="#3279B7"><b>SIGMM Outstanding Technical Achievement Award</b></font><br>
         <font color="#5b5b5b">Jiebo Luo</font>
         </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
         <font color="#3279B7"><b>SIGMM Rising Star Award</b></font> <br>
         <font color="#5b5b5b">Jingkuan Song</font>
          </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
         <font color="#3279B7"><b>SIGMM Test of Time Paper Award</b></font><br>
         "Dynamic Adaptive Streaming over HTTP ‐ Standards and Design Principles" in MMSys'11, pp. 133‐144, 2011.<br>
         <font color="#5b5b5b">Thomas Stockhammer</font>
          </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
         <font color="#3279B7"><b>SIGMM Outstanding PhD Thesis Award</b></font><br>
         "Video Adaptation for High-Quality Content Delivery"<br>
         <font color="#5b5b5b">Kevin Spiteri</font>
        </p>
      </el-col>
    </el-row>
     <el-row>
      <el-col>
        <p class="content-text">
         <font color="#3279B7"><b>2021 NICHOLAS GEORGANAS ACM TOMM Best Paper Award</b></font><br>
         "Do Users Behave Similarly in VR? Investigation of the User Influence on the System Design"<br>
         <font color="#5b5b5b">Silvia Rossi, Cagri Ozcinar, Aljosa Smolic and Laura Toni</font>
        </p>
      </el-col>
    </el-row>

<!--
    <el-row>
      <el-col>
        <p class="content-text">
        <font color="#0054B0"><b>The Best Paper Award of ACM Multimedia 2021</b></font> goes to <b>"Video Background Music Generation with Controllable Music Transformer"</b>, by <font color="#0054B0"><b> Shangzhe Di, Zeren Jiang, Si Liu*, Zhaokai Wang, Leyan Zhu, Zexin He, Hongming Liu, Shuicheng Yan</b></font> from <b>Beihang University, Charterhouse School and Sea AI Labs</b>.
         </p>
      </el-col>
    </el-row>


    <el-row>
      <el-col>
        <p class="content-text">
        <font color="#0054B0"><b>The Best Student Paper Award of ACM Multimedia 2021</b></font> goes to <b>"aBio: Active Bi-Olfactory Display Using Subwoofers for Virtual Reality"</b>, by <font color="#0054B0"><b>You-Yang Hu*, Yao-Fu Jan, Kuan-Wei Tseng, You-Shin Tsai, Hung-Ming Sung, Jin-Yao Lin, Yi-Ping Hung</b></font> from <b>NTU and TNNUA</b>.
         </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="content-text">
        <font color="#0054B0"><b>The Best Demo Award of ACM Multimedia 2021</b></font> goes to <b>"ViDA-MAN: Visual Dialog with Digital Humans"</b>, by <font color="#0054B0"><b> Tong Shen*, Jiawei Zuo, Fan Shi, Jin Zhang, Liqin Jiang, Meng Chen, Zhengchen Zhang, Wei Zhang, Xiaodong He, Tao Mei</b></font> from <b>JD AI Research and Migu</b>.
         </p>
      </el-col>
    </el-row>


    <el-row>
      <el-col>
        <p class="content-text">
        <font color="#0054B0"><b>The Best Open Source Award of ACM Multimedia 2021</b></font> goes to <b>"X-modaler: A Versatile and High-performance Codebase for Cross-modal
Analytics"</b>, by <font color="#0054B0"><b> Yehao Li, Yingwei Pan*, Jingwen Chen, Ting Yao, Tao Mei</b></font> from <b>Sun Yat-Sen University and JD AI Research</b>.
         </p>
      </el-col>
    </el-row>


    <el-row>
      <el-col>
        <p class="content-text">
        <font color="#0054B0"><b>The SIGMM Outstanding Technical Achievement Award</b></font> goes to <font color="#0054B0"><b>Jiebo Luo</b></font>, for his contributions to Multimedia Computing, Communications and Applications.
         </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
         <font color="#0054B0"><b>The SIGMM Rising Star Award</b></font> goes to <font color="#0054B0"><b>Jingkuan Song</b></font>, for his significant contributions in Multimedia Compact Representation and Analysis.
          </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
         <font color="#0054B0"><b>The SIGMM Test of Time Paper Award</b></font> goes to <font color="#0054B0"><b>Thomas Stockhammer</b></font>, for his paper <b>"Dynamic Adaptive Streaming over HTTP ‐ Standards and Design Principles"</b> in Proceedings of the 2nd ACM Conference on Multimedia Systems, MMSys'11, pp. 133‐144, 2011.
          </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
         <font color="#0054B0"><b>The SIGMM Outstanding PhD Thesis Award</b></font> goes to <font color="#0054B0"><b>Kevin Spiteri</b></font> from University of Massachusetts Amherst, for his PhD thesis <b>"Video Adaption for High Quality Content Deliver"</b>.
        </p>
      </el-col>
    </el-row> -->

    <el-row>
      <el-col>
        <p class="headset">2021-10-17</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
        <b><a class="demo-link" href="https://whova.com/portal/webapp/acmmu_202110/">Conference Event</a></b> has been published on Whova. Please read <b><a class="demo-link" href="https://2021.acmmm.org/app-guide">APP Guide</a></b> for more details.
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">2021-10-16</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
        <!-- Online attendees can use <b><a class="demo-link" href="https://whova.com/pages/whova-app-user-guide/">Whova</a></b> and <b><a class="demo-link" href="https://www.cvent.com/en/blog/events/virtual-events"> Cvent</a></b> for obtain better conference experience. The usages of two apps can be found in <b><a class="demo-link" href="https://2021.acmmm.org/app-guide">APP Guide</a></b>. -->
        Attendees are encouraged to use two Apps, <b><a class="demo-link" href="https://whova.com/pages/whova-app-user-guide/">Whova</a></b> (schedule, videos and posters) and <b><a class="demo-link" href="https://www.cvent.com/en/blog/events/virtual-events"> Cvent</a></b> (live sessions). The usages of two apps can be found in <b><a class="demo-link" href="https://2021.acmmm.org/app-guide">APP Guide</a></b>.
        </p>
      </el-col>
    </el-row>


    <el-row>
      <el-col>
        <p class="headset">2021-10-14</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
        <!-- <b><a href="https://2021.acmmm.org/full-draft-program"> Full Technical Program </a></b> is now released and available for <a href="https://2021.acmmm.org/documents/ACMMM21_Full_Technical_Program.pdf"> download</a>. Please also pay attention to the <b><a href="https://2021.acmmm.org/attendee-guide"> "Attendee Guide"</a></b> to well prepare for the comming conference. You can download the guide book <b><a href="https://2021.acmmm.org/documents/ACMMM21_Attendee_Guide.pdf"> here</a></b> for easy reference. -->

        <b><a href="https://2021.acmmm.org/full-draft-program"> Full Technical Program </a></b> is available for download. <b><a href="https://2021.acmmm.org/documents/ACMMM21_Full_Technical_Program.pdf">[pdf]</a></b>
      </p>
      <p class="content-text">
        <!-- Please also pay attention to the <b><a href="https://2021.acmmm.org/attendee-guide"> "Attendee Guide"</a></b> to well prepare for the upcoming conference. You can download the <b><a href="https://2021.acmmm.org/documents/ACMMM21_Attendee_Guide.pdf"> Guide Book</a></b> for easy reference. -->
        For all attendees, please refer to the <b><a href="https://2021.acmmm.org/attendee-guide">Attendee Guide</a></b> and <b><a href="https://2021.acmmm.org/documents/ACMMM21_Attendee_Guide.pdf"> Guide Book</a></b>, respectively.
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">2021-09-29</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
        We are now <b>calling for session chairs</b> who would like to host the <b>ONSITE oral sessions</b> during the three main conference days (October 21-23, 2021, GMT+8 time zone). If you will attend the ONSITE conference, please click <a href="https://iconf.mike-x.com/OSMGX">here</a> for application. Thank you!
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">2021-09-28</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
        <!--   The <a href="http://acmmm.hchyun.com/program-at-a-glance"> Program at a Glance</a> is now available. The instructions of uploading the pre-recorded videos and posters can be found <a href="http://acmmm.hchyun.com/author-instruction">here</a>. (Deadline: October 10th, 11:59 PM, <a href="https://time.is/Anywhere_on_Earth">AOE</a>) -->
          <b><a href="https://2021.acmmm.org/program-at-a-glance"> Program at a Glance</a></b> is now available. The instructions of uploading the pre-recorded videos and posters can be found <a href="https://2021.acmmm.org/author-instruction">here</a>. (Deadline: October 10th, 11:59 PM, <a href="https://time.is/Anywhere_on_Earth">AOE</a>)
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">2021-09-07</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
       Presenters please pay attention to the information of <a href="https://2021.acmmm.org/author-instruction">author instruction</a>. We will also send an email containing details of instruction later, please check it.
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">2021-08-30</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          Six distinguished scientists are invited to give keynote speeches in ACM MM 2021. The details of keynotes can be found at <a href="https://2021.acmmm.org/keynotes">Conference Keynotes</a>.
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">2021-08-17</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <a href="https://2021.acmmm.org/sigmm-carer-awards">SIGMM Carer Awards</a> is now available for SIGMM members. Students from all over the world and women are strongly invited to apply. The application form can be found <a href="https://acmsigmm.wufoo.com/forms/m19w0i430htqyos/">here</a>. <!-- when virtually attending SIGMM-approved conferences. -->
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">2021-08-13</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
         <a href="https://2021.acmmm.org/registration">Registration</a> website is available now. Please read carefully the instruction before registration. Deadline: <b>August 31, 2021</b>.
          <!-- <a href="https://2021.acmmm.org/registration">Registration</a> website is available now. The author registration deadline is <b>August 31, 2021</b>. -->
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">2021-08-12</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
           <!-- is now available for application.  -->Students are encouraged to apply for <a href="https://2021.acmmm.org/student-travel-grants">Student Travel Grants</a> before <b>September 15, 2021</b>.
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">2021-08-04</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
         <!--  The <a href="https://2021.acmmm.org/main-track-list">Main Track Accepted Paper List</a> is now released. -->
         Main track accepted paper list has been announced. The list of papers accepted to ACM MM 2021 can be found <a href="https://2021.acmmm.org/main-track-list">here</a>.
          <!-- The List of Accepted Paper has been released in <a href="https://2021.acmmm.org/main-track-list">https://2021.acmmm.org/main-track-list</a>. -->
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">2021-04-13</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <a href="https://2021.acmmm.org/interactive-arts">Call for Interactive Artworks</a> has been published. The submission deadline is June 15, 2021.
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">2021-04-06</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <a href="http://2021.acmmm.org/tutorial-proposals">Call for Tutorial Proposals</a> is now released. Proposals should be submitted via <a href="https://cmt3.research.microsoft.com/ACMMM2021">here</a>.
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">2021-03-19</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          Due to the global emergency of Coronavirus pandemic and the numerous requests from the multimedia community, we decide to postpone the regular paper submission deadline to <b>April 17, 2021</b>.
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">2021-03-18</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <a href="http://2021.acmmm.org/multimedia-grand-challenge-submissions">Multimedia Grand Challenges Submissions</a> is now released. Please submit grand challenge paper before 11 July, 2021.
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">2021-03-06</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <a href="https://2021.acmmm.org/call-for-sponsors">Call for Sponsors</a> has been released. Companies are welcome to sponsor ACM MM 2021.
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">2021-02-22</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-texts">
          CMT Submission Website is available now (<a href="https://cmt3.research.microsoft.com/ACMMM2021">https://cmt3.research.microsoft.com/ACMMM2021</a>).
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">2020-12-12</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">
          <b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.<br>
          <span> <span style="color:black;font-weight: 600">Regular Papers</span> submission deadline: <span
              style="color:#0054B0;">April 17, 2021. </span></span><br>
          <span> <span style="color:black;font-weight: 600">Workshop Proposals</span> submission deadline: <span
              style="color:#0054B0;">March 6, 2021. </span></span><br>
          <span> <span style="color:black;font-weight: 600">Doctoral Symposium</span> deadline: <span
              style="color:#0054B0;">June 15, 2021.</span></span><br>
          <span> <span style="color:black;font-weight: 600">Brave New ldeas</span> submission deadline: <span
              style="color:#0054B0;">June 21, 2021. </span></span><br>
          <span> <span style="color:black;font-weight: 600">Grand Challenge Solutions</span> deadline: <span
              style="color:#0054B0;">July 11, 2021.</span></span><br>
          <span> <span style="color:black;font-weight: 600">Industrial Papers</span> deadline: <span
              style="color:#0054B0;">July 15, 2021.</span></span><br>
        </p>
      </el-col>
    </el-row>
    <!--第二部分-->
    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Introduction </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">Welcome to the 29th ACM International Conference on Multimedia.</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text"> Since the founding of ACM SIGMM in 1993, ACM Multimedia has been the worldwide
          premier conference and a key world event to display scientific achievements and innovative industrial
          products in the multimedia field. For the first time in its history, ACM Multimedia 2021 will be held in
          Chengdu, the capital city of the Sichuan Province in China. At ACM Multimedia 2021, we will present an
          extensive program consisting of technical sessions covering all aspects of the multimedia field via
          oral, video and poster presentations, tutorials, panels, exhibits, demonstrations, workshops, doctoral
          symposium, multimedia grand challenge, brave new ideas on shaping the research landscape, open source
          software competition, and also an interactive arts program stimulating artists and computer scientists
          to meet and discover together the frontiers of artistic communication. We will also add the industrial
          track to recognize those research works with significant industrial values.</p>
      </el-col>
    </el-row>
    <!--第三部分-->
<!--     <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Important Dates </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <img class="time-line-pc" :src="timeLinepc" alt="">
        <img class="time-line-modie" :src="timeLineModie">
      </el-col>
    </el-row> -->
    <!--第四部分-->
    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Research Topics </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text">ACM Multimedia 2021 calls for research papers presenting novel theoretical and
          algorithmic solutions addressing problems across the domain of multimedia and related applications. The
          conference also calls for papers presenting novel, thought-provoking ideas and promising (preliminary)
          results in realizing these ideas. Submissions are invited in the following 4 themes containing 12
          areas:</p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">Engaging Users with Multimedia</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Emotional and social signals</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Multimedia search and recommendations </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Summarization, analysis and storytelling </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">Experience</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Interactions and quality of experiences </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Art and culture </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Multimedia applications </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">Multimedia Systems</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Systems and middleware </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Transport and delivery </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Data systems management and indexing </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">Understanding Multimedia Content</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Multimodal fusion and embedding </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Vision and language </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="1">
        <div class="dot"></div>
      </el-col>
      <el-col :span="21">
        <p class="content-text"> Media interpretation </p>
      </el-col>
    </el-row>
    <!--第五部分-->
    <el-row class="el-rows">
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <el-col :span="22">
        <p class="title"> Highlights of ACM Multimedia 2021 </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <p class="headset">Scientific Diversity</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text"> It is among our top priorities to ensure a top-quality conference that covers a
          full diversity of all fields of multimedia research including a variety of media modalities by addressing
          both technological and practical challenges. </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">Value</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text"> We strive to select the most innovative and the highest-quality research aiming at
          the most impactful novelty on individual media and/or from a systematic perspective of innovating and
          integrating multiple components across modalities.</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="headset">Sharing</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="content-text"> We value the sharing of not only knowledge (in the form of papers, presentations
          and demos) but also the code and open-source software. While we will continue the implementation of the
          ACM Artifact Review and Badging, best efforts will be made on sharing and preserving the open-source code
          and systems resulting from the published papers. </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "context",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      timeLinepc: "/cnd-image/sy_sjz2x02.png",
      timeLineModie: '/cnd-image/sy_sjz2x02.png',
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.el-rows {
  /* todo */
  margin-top: 3rem;
  margin-bottom: 2rem;
}

p {
  margin: 0;
}

.time-line-pc {
  width: 100%;
}

.time-line-modie {
  width: 100%;
}

.icon {
  margin-top: .8rem;
  margin-right: 1rem;
  width: 3.4rem;
}

.dot {
  width: 1rem;
  height: 1rem;
  margin-top: .5rem;
  background-color: #0054B0;
  border-radius: .5rem
}

.title {
  margin-left: -1.8rem !important;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 3.5rem;
  font-weight: bold;
  color: #202020;
}

@media screen and (max-width: 1920px) {
  .title {
    margin-left: 0;
  }

  .time-line-pc {
    display: none;
  }

  .time-line-modie {
    display: block;
  }
}

@media screen and (min-height: 750px) {
  .time-line-pc {
    display: block;
  }

  .time-line-modie {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .title {
    margin-left: 1rem !important;
  }

  .icon {
    margin-top: .4rem;
  }

  .time-line-pc {
    display: none;
  }

  .time-line-modie {
    display: block;
  }
}

.headset {
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: .4rem;
  color: #0054B0;
}

.content-text {
  text-align: justify;
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #202020;
  margin-bottom: 1rem;
}
.content-texts {
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #202020;
  margin-bottom: 1rem;
}

.content-div {
  margin-bottom: 1rem;
}

.link {
  color: #0054B0;
}

</style>
