<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
name: "author-instruction",
data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: '',
      contents:[
        {
          type:'text',
          text:'Last update: 09/28/2021',
        },
        {
          type:'primaryTitle',
          text:'Author Instruction',
        },
        
        // {
        //   type:'retract',
        //   text:'Instructions on how and where to submit the prerecorded video and the poster file will be sent to the authors via a separate email on late September.'
        // },
        // {
        //   type:'retract',
        //   text:'The dealine for submiting the pre-record videos and posters will be on early October tentatively.',
        // },
        {
          type:'secondaryBlack',
          text:'Outline',
        },
        {
          type:'text',
          text:'This page contains information for the presenters at ACM Multimedia 2021. Additionally, please watch your email for any messages that you might receive from the conference organizers with additional details.',
        },
        {
          type:'text',
          text:'The following points regarding the paper type:',
        },
        {
          type:'text',
          text:'Long Paper:',
        },
        {
          type:'retract',
          text:'Main conference track',
        },
        {
          type:'retract',
          text:'Brave new ideas',
        },
        {
          type:'retract',
          text:'Industrial track',
        },
        {
          type:'text',
          text:'Short Paper:',
        },
        {
          type:'retract',
          text:'Technical demos and video program',
        },
        {
          type:'retract',
          text:'Open source software competition',
        },
        {
          type:'retract',
          text:'Multimedia grand challenge',
        },
        {
          type:'retract',
          text:'Doctoral symposium',
        },
        {
          type:'retract',
          text:'Reproducibility companion',
        },
        {
          type:'retract',
          text:'Interactive arts',
        },
        {
          type:'retract',
          text:'All workshops',
        },
        {
          type:'secondaryBlack',
          text:'Attentions for Pre-recorded Video & Poster Upload:',
        },
        {
          type:'retract',
          text:'For each one of all the above papers, the authors need to provide a prerecored video file and/or a poster file.'
        },
        {
          type:'retract',
          text:'The pre-record videos and posters for each track described below are mandatory, while the video submission requirement from ACM DL is optional.'
        },
        {
          type:'retract',
          text:'The deadline for submitting the pre-record videos and posters will be on October 10, 11:59 PM (<b><a href="https://time.is/Anywhere_on_Earth">AOE</a></b>).',
        },
        {
          type:'retract',
          text:'The primary contact author of each paper (in all tracks) will receive an email from <b><a href="https://whova.com/">Whova</a></b>, a free, official app which supports the hybrid conference mode of ACM Multimedia 2021. Note that the primary contact author is set as the corresponding author who handled the paper in the CMT paper <b><a href="https://cmt3.research.microsoft.com/User/Login?ReturnUrl=%2FACMMM2021%2FSubmission%2FIndex">submission system</a></b>. The primary contact author can distribute the email to the co-authors who can be in charge of uploading the pre-recorded video and poster.',
        },
        {
          type:'retract',
          text:'In the Whova platform, when uploading your poster file, go to your "Sessions"->"Documents"->"Upload PDF file"; when uploading your pre-recorded video file, go to your "Sessions"->"Recorded Video"->"Add Video". Only these two files are required, the other information such as "Live Polling" and "Live Stream" can be simply neglected.',
        },
        
        {
          type:'text',
          text:'Requirement for the prerecorded video:',
        },
        {
          type:'retract',
          text:'Provide a prerecorded video which will be presented at the conference web portal during the conference period. All the videos can be accessed via the conference app for the registered authors.',
        },
        {
          type:'retract',
          text:'The length of video for different types of papers are different. More details can be found in the latter parts.',
        },
        {
          type:'retract',
          text:'Videos should be encoded in an MP4/MOV format, at 1920x1080p, using H264 compression, with 16/9 aspect ratio.  Please ensure that your associated audio is clear and at the appropriate level. The maximum video file size allowed by the virtual platform is 200MB, however, we recommend a much smaller file size (e.g., 50-100MB) to avoid issues with uploading or downloading. ',
        },
        {
          type:'text',
          text:'Requirement for the poster:',
        },
        {
          type:'retract',
          text:'Provide a poster (in PDF format, A0 size, width x height = 841 × 1189 mm = 33.1 × 46.8 inches) portrait format. All the posters will be printed out and be posted on the conference venue to facilitate the exchange of attendees both online and onsite. All the attendees onsite do NOT need to print out the poster by themselves. All the posters will be directly collected and printed out by the conference organizers.',
        },
        {
          type:'retract2Black',
          text:'<b>An optional CVPR-style poster template can be referred to <a href="https://2021.acmmm.org/documents/mm21_poster_template_A0size(841mmX1189mm).pptx">here</a>.</b>',
        },
        {
          type:'retract',
          text:'Please kindly put your paper id (as listed in the conference program) at the top-left of each of your presentation slides. It will help the organizer to manage your poster. Put your email address or QR code on the poster: it will help people find your poster and contact you.',
        },
        // {
        //   type:'text',
        //   text:'Moreover, to facilitate the interactions between online and onsite participants during the poster sessions, we set up <b>VIRTUAL DISCUSSION ROOMs</b>. During the conference time, both the attendees onsite and online can join the rooms to discuss the posters. <b>Details about the setup of the virtual rooms will be sent to the authors via a separate email.</b>',
        // },
        // {
        //   type:'secondaryBlack',
        //   text:'<br>  ',
        // },
        // {
        //   type:'secondaryBlack',
        //   text:'<br>  ',
        // },
        // {
        //   type:'text',
        //   text:'Below you will find specific information on the Main Conference track. <b>The instructions for the other tracks will be notified and updated on this website later.</b>'
        // },
        {
          type:'primaryTitle',
          text:'Main Conference Track',
        },
        {
          type:'text',
          text:'Please first refer to <a class="demo-link" href="https://2021.acmmm.org/main-track-list">https://2021.acmmm.org/main-track-list</a> to check your paper type: Oral or Poster.'
        },
        {
          type:'text',
          text:'<b>For Oral Paper:</b>',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 12-min video which will be presented at the conference web portal during the conference period. ',
        },
        {
          type:'retract',
          text:'Provide a poster PDF (A0 size, width x height = 841 × 1189 mm = 33.1 × 46.8 inches) portrait format. (<a href="https://2021.acmmm.org/documents/mm21_poster_template_A0size(841mmX1189mm).pptx">Optional template poster</a>)',
        },
        {
          type:'retract',
          text:"For authors who CAN physically attend the conference onsite, you are expected to give a live talk (12 mins' presentation + 3 mins' Q&A) onsite in the corresponding oral session.",
        },
        {
          type:'retract',
          text:"For authors who CANNOT physically attend the conference, you can prefer to give a live talk (12 mins' presentation + 3 mins' Q&A) online or play the prerecorded video online in the corresponding oral session by the session chair onsite, depending on the conflict of the time zone of the authors and the GMT+8 time zone.",
        },
        {
          type:'retract',
          text:'In case of emergent network connection problem during the live talk, the volunteer serving for the session can play the prerecorded video for the paper.',
        },
        {
          type:'text',
          text:'<b>For Poster Paper:</b>',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 12-min video which will be presented at the conference web portal during the conference period.',
        },
        {
          type:'retract',
          text:'Provide a poster PDF (A0 size, width x height = 841 × 1189 mm = 33.1 × 46.8 inches) portrait format. (<a href="https://2021.acmmm.org/documents/mm21_poster_template_A0size(841mmX1189mm).pptx">Optional template poster</a>)',
        },
        {
          type:'retract',
          text:"For authors who CAN physically attend the conference, you are expected to stand at your poster, present your work and discuss with conference participants, during the entire poster session/period to which you are assigned.",
        },
        // {
        //   type:'retract',
        //   text:'For authors who CANNOT physically attend the conference, you can prefer to apply for a time slot of Zoom virtual discussion room. Details will be provided in section VIRTUAL DISCUSSION ROOM later.',
        // },
        {
          type:'text',
          text:'<b>Especially, for the Best Paper Session in main conference,</b>',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 15-min video which will be presented at the conference web portal during the conference period. ',
        },
        {
          type:'retract',
          text:'Provide a poster PDF (A0 size, width x height = 841 × 1189 mm = 33.1 × 46.8 inches) portrait format. (<a href="https://2021.acmmm.org/documents/mm21_poster_template_A0size(841mmX1189mm).pptx">Optional template poster</a>)',
        },
        {
          type:'retract',
          text:"For authors who CAN physically attend the conference onsite, you are expected to give a live talk (15 mins' presentation + 3 mins' Q&A) onsite in the corresponding session.",
        },
        {
          type:'retract',
          text:"For authors who CANNOT physically attend the conference, you can prefer to give a live talk (15 mins' presentation + 3 mins' Q&A) online or play the prerecorded video online in the corresponding oral session by the session chair onsite, depending on the conflict of the time zone of the authors and the GMT+8 time zone.",
        },
        {
          type:'retract',
          text:'In case of emergent network connection problem during the live talk, the volunteer serving for the session can play the prerecorded video for the paper.',
        },
        {
          type:'primaryTitle',
          text:'Brave New Ideas',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 15-min video which will be presented at the conference Web portal during the conference period. ',
        },
        {
          type:'retract',
          text:'Provide a poster PDF (A0 size, width x height = 841 × 1189 mm = 33.1 × 46.8 inches) portrait format. (<a href="https://2021.acmmm.org/documents/mm21_poster_template_A0size(841mmX1189mm).pptx">Optional template poster</a>)',
        },
        {
          type:'retract',
          text:"For authors who CAN physically attend the conference onsite, you are expected to give a live talk (15 mins' presentation + 3 mins' Q&A) onsite in the corresponding session.",
        },
        {
          type:'retract',
          text:"For authors who CANNOT physically attend the conference, you can prefer to give a live talk (15 mins' presentation + 3 mins' Q&A) online or play the prerecorded video online in the corresponding oral session by the session chair onsite, depending on the conflict of the time zone of the authors and the GMT+8 time zone.",
        },
        {
          type:'retract',
          text:'In case of emergent network connection problem during the live talk, the volunteer serving for the session can play the prerecorded video for the paper.',
        },
        {
          type:'retract',
          text:'Please contact the Brave New Ideas chairs (mm21-bni@sigmm.org) if you have further questions.',
        },
        {
          type:'primaryTitle',
          text:'Industrial Track',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 12-min video which will be presented at the conference web portal during the conference period. ',
        },
        {
          type:'retract',
          text:'Provide a poster PDF (A0 size, width x height = 841 × 1189 mm = 33.1 × 46.8 inches) portrait format. (<a href="https://2021.acmmm.org/documents/mm21_poster_template_A0size(841mmX1189mm).pptx">Optional template poster</a>)',
        },
        {
          type:'retract',
          text:"For authors who CAN physically attend the conference onsite, you are expected to give a live talk (12 mins' presentation + 3 mins' Q&A) onsite in the corresponding session.",
        },
        {
          type:'retract',
          text:"For authors who CANNOT physically attend the conference, you can prefer to give a live talk (12 mins' presentation + 3 mins' Q&A) online or play the prerecorded video online in the corresponding oral session by the session chair onsite, depending on the conflict of the time zone of the authors and the GMT+8 time zone.",
        },
        {
          type:'retract',
          text:'In case of emergent network connection problem during the live talk, the volunteer serving for the session can play the prerecorded video for the paper.',
        },
        {
          type:'retract',
          text:'Please contact the Technical Track chairs (mm21-tpc@sigmm.org) if you have further questions.',
        },
        
        {
          type:'primaryTitle',
          text:'Technical Demo and Video Program',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 3-min video (containing the explanation and demo of your software), it will be presented at the conference web portal during the conference period. ',
        },
        {
          type:'retract',
          text:'You do NOT have to prepare a poster.',
        },
        {
          type:'retract',
          text:"For authors who CAN physically attend the conference onsite, you are expected to give a live talk (3 mins' presentation + 2 mins' Q&A) onsite in the corresponding session.",
        },
        {
          type:'retract',
          text:"For authors who CANNOT physically attend the conference, you can prefer to give a live talk (3 mins' presentation + 2 mins' Q&A) online or play the prerecorded video online in the corresponding oral session by the session chair onsite, depending on the conflict of the time zone of the authors and the GMT+8 time zone.",
        },
        {
          type:'retract',
          text:'In case of emergent network connection problem during the live talk, the volunteer serving for the session can play the prerecorded video for the paper.',
        },
        {
          type:'retract',
          text:'Please contact the Technical Demo and Video Program Chairs (mm21-dvp@sigmm.org) if you have further questions.',
        },
        {
          type:'primaryTitle',
          text:'Open Source Software Competition',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 5-min video (containing the explanation and demo of your software), it will be presented at the conference web portal during the conference period.',
        },
        {
          type:'retract',
          text:'You do NOT have to prepare a poster.',
        },
        {
          type:'retract',
          text:"For authors who CAN physically attend the conference onsite, you are expected to give a live talk (5 mins' presentation + 2 mins' Q&A) onsite in the corresponding session.",
        },
        {
          type:'retract',
          text:"For authors who CANNOT physically attend the conference, you can prefer to give a live talk (5 mins' presentation + 2 mins' Q&A) online or play the prerecorded video online in the corresponding oral session by the session chair onsite, depending on the conflict of the time zone of the authors and the GMT+8 time zone.",
        },
        {
          type:'retract',
          text:'In case of emergent network connection problem during the live talk, the volunteer serving for the session can play the prerecorded video for the paper.',
        },
        {
          type:'retract',
          text:'Please contact the Open Source Software Chairs chairs (mm21-osc@sigmm.org) if you have further questions.',
        },
        {
          type:'primaryTitle',
          text:'Multimedia Grand Challenge',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 3-min video which will be presented at the conference web portal during the conference period. ',
        },
        {
          type:'retract',
          text:'You do NOT have to prepare a poster.',
        },
        {
          type:'retract',
          text:"For authors who CAN physically attend the conference onsite, you are expected to give a live talk (3 mins' presentation + 2 mins' Q&A) onsite in the corresponding session.",
        },
        {
          type:'retract',
          text:"For authors who CANNOT physically attend the conference, you can prefer to give a live talk (3 mins' presentation + 2 mins' Q&A) online or play the prerecorded video online in the corresponding oral session by the session chair onsite, depending on the conflict of the time zone of the authors and the GMT+8 time zone.",
        },
        {
          type:'retract',
          text:'In case of emergent network connection problem during the live talk, the volunteer serving for the session can play the prerecorded video for the paper.',
        },
        {
          type:'retract',
          text:'Please contact the Multimedia Grand Challenge chairs (mm21-mgc@sigmm.org) if you have further questions.',
        },
        {
          type:'primaryTitle',
          text:'Doctoral Symposium',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 12-min video, it will be presented at the conference web portal during the conference period.',
        },
        {
          type:'retract',
          text:'Provide a poster PDF (A0 size, width x height = 841 × 1189 mm = 33.1 × 46.8 inches) portrait format. (<a href="https://2021.acmmm.org/documents/mm21_poster_template_A0size(841mmX1189mm).pptx">Optional template poster</a>)',
        },
        {
          type:'retract',
          text:"For authors who CAN physically attend the conference onsite, you are expected to give a live talk (12 mins' presentation + 3 mins' Q&A) onsite in the corresponding session.",
        },
        {
          type:'retract',
          text:"For authors who CANNOT physically attend the conference, you can prefer to give a live talk (12 mins' presentation + 3 mins' Q&A) online or play the prerecorded video online in the corresponding oral session by the session chair onsite, depending on the conflict of the time zone of the authors and the GMT+8 time zone.",
        },
        {
          type:'retract',
          text:'In case of emergent network connection problem during the live talk, the volunteer serving for the session can play the prerecorded video for the paper.',
        },
        {
          type:'retract',
          text:'If you have further questions, please contact the Doctoral Symposium Chairs (mm21-dsc@sigmm.org).',
        },
        {
          type:'primaryTitle',
          text:'Reproducibility Companion Track',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 5-min video which will be presented at the conference web portal during the conference period.',
        },
        {
          type:'retract',
          text:'Provide a poster PDF (A0 size, width x height = 841 × 1189 mm = 33.1 × 46.8 inches) portrait format. (<a href="https://2021.acmmm.org/documents/mm21_poster_template_A0size(841mmX1189mm).pptx">Optional template poster</a>)',
        },
        {
          type:'retract',
          text:'For authors who CAN physically attend the conference, you are expected to stand at your poster, present your work and discuss with conference participants, during the entire poster session/period to which you are assigned.',
        },
        // {
        //   type:'retract',
        //   text:'For authors who CANNOT physically attend the conference, you can prefer to can apply for a time slot of Zoom virtual discussion room. Details can be found in section VIRTUAL DISCUSSION ROOM.',
        // },
        {
          type:'retract',
          text:'If you have further questions, please contact the Reproducibility Companion Chairs (mm-repro@sigmm.org).',
        },
        {
          type:'primaryTitle',
          text:'Interactive Arts',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 5-min video which will be presented at the conference web portal during the conference period.',
        },
        {
          type:'retract',
          text:'Provide a poster PDF (A0 size, width x height = 841 × 1189 mm = 33.1 × 46.8 inches) portrait format. (<a href="https://2021.acmmm.org/documents/mm21_poster_template_A0size(841mmX1189mm).pptx">Optional template poster</a>)',
        },
        {
          type:'retract',
          text:'For authors who CAN physically attend the conference, you are expected to stand at your poster, present your work and discuss with conference participants, during the entire poster session/period to which you are assigned.',
        },
        // {
        //   type:'retract',
        //   text:'For authors who CANNOT physically attend the conference, you can prefer to can apply for a time slot of Zoom virtual discussion room. Details can be found in section VIRTUAL DISCUSSION ROOM.',
        // },
        {
          type:'retract',
          text:'If you have further questions, please contact the Doctoral Symposium Chairs (mm21-iac@sigmm.org).',
        },
        {
          type:'primaryTitle',
          text:'Workshops',
        },
        {
          type:'retract',
          text:'If you are presenting at a workshop, you will receive instructions from your workshop organizers, who will tell you how long your oral presentation is and/or when you will present your poster.',
        },
        {
          type:'retract',
          text:'Provide a prerecorded 5-min video which will be presented at the conference web portal during the conference period.',
        },
        {
          type:'retract',
          text:'Provide a poster PDF (A0 size, width x height = 841 × 1189 mm = 33.1 × 46.8 inches) portrait format. Note that workshop papers may not have paper numbers (if so, ignore the instructions relating to paper numbers above).  (<a href="https://2021.acmmm.org/documents/mm21_poster_template_A0size(841mmX1189mm).pptx">Optional template poster</a>)',
        },
        {
          type:'retract',
          text:'For authors who CAN physically attend the conference, you are expected to (1) give a live talk onsite in the corresponding session; and (2) stand at your poster, present your work and discuss with conference participants, during the entire poster session/period to which you are assigned.',
        },
        // {
        //   type:'retract',
        //   text:'For authors who CANNOT physically attend the conference, you can prefer to (1) give a live talk online or play the prerecorded video online in the corresponding oral session by the session chair onsite, depending on the conflict of the time zone of the authors and the GMT+8 time zone; and (2) apply for a time slot of Zoom virtual discussion room. Details can be found in section VIRTUAL DISCUSSION ROOM.',
        // },
        {
          type:'retract',
          text:'Workshop posters can be put up at the beginning of the workshop and taken down at the end of the workshop. Posters left up after the end of the workshop will be removed by the organizers.',
        },
        {
          type:'retract',
          text:'If you have further questions, please contact the Workshop Chairs (mm21-wsc@sigmm.org)',
        },
        {
          type:'primaryTitle',
          text:'Tutorials',
        },
        {
          type:'retract',
          text:'Each tutorial is assigned with half-day time and should be presented by one or more organizers (presenters).',
        },
        {
          type:'retract',
          text:'For the tutorial presenters who CAN physically attend the conference onsite, please attend the tutorial session assign to you. You can use your own laptop or the publicly available laptop in the room.',
        },
        {
          type:'retract',
          text:'For the tutorial presenters who CANNOT physically attend the conference onsite, you can prefer to give a live talk online.',
        },
        {
          type:'retract',
          text:'The tutorial presenters are also expected to interact with the participants via the online discussion forum on the Web portal during the conference period.',
        }
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Author Instruction'
  }
}
</script>

<style scoped>
@import "../../css/responsive.css";
</style>