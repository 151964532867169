<template>
  <div>
    <!--内容区域-->
    <el-row>
      <!--熊猫图标-->
      <el-col :span="2">
        <img class="icon" :src="iconImage" alt="">
      </el-col>
      <!--title-->
      <el-col :span="22">
        <p class="title">Organization Committee</p>
      </el-col>
    </el-row>
    <!--人员信息-->
    <div class="pre">
      <el-row v-for="(itme,index) in personnel" :key="index">
        <el-col class="personnels">
          <p class="headset">{{ itme.title }}</p>
          <p class="email">{{ itme.email}}</p>
          <div class="parent clearfix">
            <div class="personnel" v-for="(pre ,index) in itme.context" :key="index">
              <div class="pre-header">
                <div>
                  <img :src="pre.image" alt="">
                </div>
              </div>
              <div class="pre-middle">
                <p>{{ pre.name }}</p>
              </div>
              <div class="pre-button">
                <p>{{ pre.address }}</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Organizing Committee",
  mounted() {
    document.title = 'ACM Multimedia 2021 Organizing Committee'
  },
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm@2x.png"),
      personnel: [
        {
          title: 'Honorary Chairs',
          context: [
            {
              image: require('@/assets/organization/Yunhe_Pan@2x.png'),
              name: 'Yunhe Pan',
              address: 'Zhejiang University, China',
            },
            {
              image: require('@/assets/organization/Qionghai_Dai@2x.png'),
              name: 'Qionghai Dai',
              address: 'Tsinghua University, China',
            },
          ],
        },
        {
          title: 'General Chairs',
          email: 'mm21-gc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Heng_Tao_Shen@2x.png'),
              name: 'Heng Tao Shen',
              address:
                  'University of Electronic Science and Technology of China, China',
            },
            {
              image: require('@/assets/organization/Yueting_Zhuang@2x.png'),
              name: 'Yueting Zhuang',
              address: 'Zhejiang University, China',
            },
            {
              image: require('@/assets/organization/John_R_Smith@2x.png'),
              name: 'John R. Smith',
              address: 'IBM, USA',
            },
          ],
        },
        {
          title: 'Technical Program Committee Chairs',
           email: 'mm21-tpc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Yang_Yang@2x.png'),
              name: 'Yang Yang',
              address:
                  'University of Electronic Science and Technology of China, China',
            },
            {
              image: require('@/assets/organization/Pablo_Cesar@2x.png'),
              name: 'Pablo Cesar',
              address: 'CWI & TU Delft, The Netherlands',
            },
            {
              image: require('@/assets/organization/Florian_Metze@2x.png'),
              name: 'Florian Metze',
              address: 'FACEBOOK, Inc., USA',
            },
            {
              image: require('@/assets/organization/Balakrishnan_Prabhakaran@2x.png'),
              name: 'Balakrishnan Prabhakaran',
              address: 'University of Texas at Dallas, USA',
            },
          ],
        },
        {
          title: 'Panel Chairs',
          email: 'mm21-pc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Xian-Sheng_Hua@2x.png'),
              name: 'Xian-Sheng Hua',
              address: 'Alibaba Cloud, China',
            },
            {
              image: require('@/assets/organization/Fei_Wu@2x.png'),
              name: 'Fei Wu',
              address: 'Zhejiang University, China',
            },
            {
              image: require('@/assets/organization/Stevan_Rudinac@2x.png'),
              name: 'Stevan Rudinac',
              address: 'University of Amsterdam, The Netherlands',
            },
            {
              image: require('@/assets/organization/Xiaochun_Cao@2x.png'),
              name: 'Xiaochun Cao',
              address: 'Chinese Academy of Sciences, China',
            },
          ],
        },
        {
          title: 'Tutorial Chairs',
          email: 'mm21-tc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Changsheng_Xu@2x.png'),
              name: 'Changsheng Xu',
              address: 'Chinese Academy of Sciences, China',
            },
            {
              image: require('@/assets/organization/Elisa_Ricci@2x.png'),
              name: 'Elisa Ricci',
              address: 'University of Trento & Fondazione Bruno Kessler, Italy',
            },
            {
              image: require('@/assets/organization/Dong_Xu@2x.png'),
              name: 'Dong Xu',
              address: 'The University of Sydney, Australia',
            },
            {
              image: require('@/assets/organization/Cathal_Gurrin@2x.png'),
              name: 'Cathal Gurrin',
              address: 'Dublin City University, Ireland',
            },
          ],
        },
        {
          title: 'Workshop Chairs',
          email: 'mm21-wsc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Meng_Wang@2x.png'),
              name: 'Meng Wang',
              address: 'Hefei University of Technology, China',
            },
            {
              image: require('@/assets/organization/Lianli_Gao@2x.png'),
              name: 'Lianli Gao',
              address:
                  'University of Electronic Science and Technology of China, China',
            },
            {
              image: require('@/assets/organization/Peng_Wang@2x.png'),
              name: 'Peng Wang',
              address: 'The University of Wollongong, Australia',
            },
            {
              image: require('@/assets/organization/Piotr_Koniusz@2x.png'),
              name: 'Piotr Koniusz',
              address:
                  'Data61/CSIRO & Australian National University, Australia',
            },
          ],
        },
        {
          title: 'Brave New Idea Track Chairs',
          email: 'mm21-bni@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Chang_Wen_Chen@2x.png'),
              name: 'Chang Wen Chen',
              address: 'University at Buffalo, USA',
            },
            {
              image: require('@/assets/organization/Zi_Huang@2x.png'),
              name: 'Zi Huang',
              address: 'The University of Queensland, Australia',
            },
            {
              image: require('@/assets/organization/Qin_Jin@2x.png'),
              name: 'Qin Jin',
              address: 'Renmin University of China, China',
            },
          ],
        },
        {
          title: 'Multimedia Grand Challenge Chairs',
          email: 'mm21-mgc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Xirong_Li@2x.png'),
              name: 'Xirong Li',
              address: 'Renmin University of China, China',
            },
            {
              image: require('@/assets/organization/Lu_Fang@2x.png'),
              name: 'Lu Fang',
              address: 'Tsinghua University, China',
            },
            {
              image: require('@/assets/organization/Lixin_Duan@2x.png'),
              name: 'Lixin Duan',
              address: 'University of Electronic Science and Technology of China, China',
            },
            {
              image: require('@/assets/organization/David_Brady@2x.png'),
              name: 'David Brady',
              address: 'University of Arizona, USA',
            },
          ],
        },
        {
          title: 'Open Source Software Chairs',
          email: 'mm21-osc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Jingdong_Wang@2x.png'),
              name: 'Jingdong Wang',
              address: 'Microsoft Research Asia, China',
            },
            {
              image: require('@/assets/organization/Roger_Zimmermann@2x.png'),
              name: 'Roger Zimmermann',
              address: 'National University of Singapore, Singapore',
            },
            {
              image: require('@/assets/organization/Toshihiko_Yamasaki@2x.png'),
              name: 'Toshihiko Yamasaki',
              address: 'The University of Tokyo, Japan',
            },
            {
              image: require('@/assets/organization/Qi_Dai@2x.png'),
              name: 'Qi Dai',
              address: 'Microsoft Research Asia, China',
            },
          ],
        },
        {
          title: 'Technical Demo Chairs',
          email: 'mm21-dvp@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Jingkuan_Song@2x.png'),
              name: 'Jingkuan Song',
              address:
                  'University of Electronic Science and Technology of China, China',
            },
            {
              image: require('@/assets/organization/Richang_Hong@2x.png'),
              name: 'Richang Hong',
              address: 'Hefei University of Technology, China',
            },
          ],
        },
        {
          title: 'Video Program Chairs',
          email: 'mm21-dvp@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Jitao_Sang@2x.png'),
              name: 'Jitao Sang',
              address:
                  'Beijing Jiaotong University, China',
            },
            {
              image: require('@/assets/organization/Rainer_Lienhart@2x.png'),
              name: 'Rainer Lienhart',
              address: 'Universität Augsburg, Germany',
            },
          ],
        },
        {
          title: "Interactive Arts Chairs",
          email: 'mm21-iac@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Klaus_Schöffmann@2x.png'),
              name: 'Klaus Schöffmann',
              address: 'Klagenfurt University, Austria',
            },
            {
              image: require('@/assets/organization/Minjeong_Kim@2x.png'),
              name: 'Minjeong Kim',
              address: 'The University of North Carolina at Greensboro, USA',
            },
            {
              image: require('@/assets/organization/Ning_Xie@2x.png'),
              name: 'Ning Xie',
              address: 'University of Electronic Science and Technology of China, China',
            },
          ]
        },

        {
          title: "Local Organization Chairs",
          email: 'mm21-local@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Ling_Tian@2x.png'),
              name: 'Ling Tian',
              address: 'University of Electronic Science and Technology of China, China',
            },
            {
              image: require('@/assets/organization/Xing_Xu@2x.png'),
              name: 'Xing Xu',
              address: 'University of Electronic Science and Technology of China, China',
            },
          ]
        },

        {
          title: "Sponsorship Chairs",
          email: 'mm21-sponsor@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Tao_Mei@2x.png'),
              name: 'Tao Mei',
              address: 'JD.COM, China',
            },
            {
              image: require('@/assets/organization/Qi_Tian@2x.png'),
              name: 'Qi Tian',
              address: 'Huawei, China',
            },
          ]
        },

        {
          title: "Web and Social Media Chairs",
          email: 'mm21-web@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Benoit_Huet@2x.png'),
              name: 'Benoit Huet',
              address: 'MEDIAN Technologies, France',
            },
            {
              image: require('@/assets/organization/Xiao_Wu@2x.png'),
              name: 'Xiao Wu',
              address: 'Southwest Jiaotong University, China',
            },
          ]
        },

        {
          title: "Publicity Chairs",
          email: 'mm21-publicity@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Jinhui_Tang@2x.png'),
              name: 'Jinhui Tang',
              address: 'Nanjing University of Science and Technology, China',
            },
            {
              image: require('@/assets/organization/Islem_Rekik@2x.png'),
              name: 'Islem Rekik',
              address: 'Istanbul Technical University & BASIRA Lab, Turkey',
            },
            {
              image: require('@/assets/organization/Phoebe_Chen@2x.png'),
              name: 'Phoebe Chen',
              address: 'La Trobe University, Australia',
            },
            {
              image: require('@/assets/organization/Xiaofeng_Zhu@2x.png'),
              name: 'Xiaofeng Zhu',
              address: 'University of Electronic Science and Technology of China, China',
            },
          ]
        },

        {
          title: "Finance Chairs",
          email: 'mm21-finance@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Yanli_Ji@2x.png'),
              name: 'Yanli Ji',
              address: 'University of Electronic Science and Technology of China, China',
            },
            {
              image: require('@/assets/organization/Tatsuya_Harada@2x.png'),
              name: 'Tatsuya Harada',
              address: 'The University of Tokyo, Japan',
            },
          ]
        },

        {
          title: "Reproducibility Companion Chairs",
          email: 'mm-repro@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Naoko_Nitta@2x.png'),
              name: 'Naoko Nitta',
              address: 'Osaka University, Japan',
            },
            {
              image: require('@/assets/organization/Michael_Alexander_Riegler@2x.png'),
              name: 'Michael Alexander Riegler',
              address: 'SimulaMet, Norway',
            },
          ]
        },

        {
          title: "Doctoral Symposium Chairs",
          email: 'mm21-dsc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Mohan_Kankanhalli@2x.png'),
              name: 'Mohan Kankanhalli',
              address: 'National University of Singapore, Singapore',
            },
            {
              image: require('@/assets/organization/Bing_Zeng@2x.png'),
              name: 'Bing Zeng',
              address: 'University of Electronic Science and Technology of China, China',
            },
          ]
        },

        {
          title: "Travel Grant Chairs",
          email: 'mm21-tgc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Fumin_Shen@2x.png'),
              name: 'Fumin Shen',
              address: 'University of Electronic Science and Technology of China, China',
            },
            {
              image: require('@/assets/organization/Huimin_Lu@2x.png'),
              name: 'Huimin Lu',
              address: 'Kyushu Institute of Technology, Japan',
            },
          ]
        },

        {
          title: "Industry Track Chairs",
          email: 'mm21-ind@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Changhu_Wang@2x.png'),
              name: 'Changhu Wang',
              address: 'ByteDance AI Lab, China',
            },
            {
              image: require('@/assets/organization/Dong_Yu@2x.png'),
              name: 'Dong Yu',
              address: 'Tencent AI Lab, USA',
            },
            {
              image: require('@/assets/organization/Rongrong_Ji@2x.png'),
              name: 'Rongrong Ji',
              address: 'Xiamen University, China',
            },
            {
              image: require('@/assets/organization/Jianlong_Fu@2x.png'),
              name: 'Jianlong Fu',
              address: 'Microsoft Research Asia, China',
            },
          ]
        },

        {
          title: "Proceedings Chairs",
          email: 'mm21-psc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Liqiang_Nie@2x.png'),
              name: 'Liqiang Nie',
              address: 'Shandong University, China',
            },
            {
              image: require('@/assets/organization/Qianru_Sun@2x.png'),
              name: 'Qianru Sun',
              address: 'Singapore Management University, Singapore',
            },
            {
              image: require('@/assets/organization/Peng_Cui@2x.png'),
              name: 'Peng Cui',
              address: 'Tsinghua University, China',
            },
          ]
        },

        {
          title: "Best Paper Committee Chair",
          email: 'mm21-bpc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Tat-Seng_Chua@2x.png'),
              name: 'Tat-Seng Chua',
              address: 'National University of Singapore, Singapore',
            },
          ]
        },

        {
          title: "Registration Chairs",
          email: 'mm21-rc@sigmm.org',
          context: [
            {
              image: require('@/assets/organization/Jingjing_Chen@2x.png'),
              name: 'Jingjing Chen',
              address: 'Fudan University, China',
            },
            {
              image: require('@/assets/organization/Wen_Li@2x.png'),
              name: 'Wen Li',
              address: 'University of Electronic Science and Technology of China, China',
            },
          ]
        },
      ]
    }
  }

}
</script>

<style scoped>
@import "../../css/responsive.css";

.headset {
  font-size: 2.8rem !important;
}
.email {
  font-size: 1.7rem !important;
  font-family: Roboto-Medium, Roboto;
  font-weight: 500;
  color: #6E6E6E;
  line-height: 20px;
}

p {
  margin: 0 0 0 !important;
}

.parent {
  display: inline-block;
  margin-top: 2rem;
}

.personnel {
  float: left;
  width: 18rem;
  height: 28rem;
  background: #FFFFFF;
  border-radius: 1.8rem;
  border: 2px solid #EEEEEE;
  margin-right: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.personnel:hover {
  box-shadow: 0 0.2rem 0.5rem 0 #B3B3B3;
}

.personnels {
  text-align: center;
  margin-bottom: 2rem;
}

.pre-header > div {
  width: 16rem;
  height: 16rem;
  /*background-color: red;*/
  margin: 1rem auto 1rem;
  border-radius: 1rem;
}

.pre-header > div > img {
  width: 100%;
}

.pre-middle > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #272727;
  margin-top: 1.6rem;
  /*line-height: 19px;*/
}

.pre-button {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-top: 1rem;
}

.pre-button > p {
  font-size: 1.4rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-family: Roboto-Medium, Roboto, sans-serif;
  font-weight: 500;
  color: #0054B0;
  /*line-height: 16px;*/
}

.pre {
  margin-top: 2rem;
}

@media screen  and (max-width: 750px) {
  .personnel p {
    margin: 0 0 .8rem !important;
  }

  .parent .personnel:first-child {
    margin-left: 2rem;
  }
  .parent .personnel:last-child {
    margin-left: 2rem;
  }

  .personnel {
    margin-right: 1.2rem;
  }

  .personnels {
    width: 105%;
  }
}

.parent > div:first-child {
  margin-left: 2rem !important;
}

</style>
