<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "regular-papers",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Call for Doctoral Symposium',
      contents:[
        {
          type:'primaryTitle',
          text:'Overview',
        },
        {
          type:'text',
          text:'The ACM Multimedia 2021 Doctoral Symposium will provide PhD students working in all areas of multimedia with a unique opportunity to interact with renowned and experienced researchers in the field, and discuss with them about their ongoing research. The Doctoral Symposium also aims at establishing a supportive worldwide community of doctoral students working on multimedia, and promoting social and intellectual interactions among students and researchers from academia, industry and government.' 
        },
        {
          type:'text',
          text:'The mechanism is simple: PhD students are invited to send a short paper (up to 4 pages for technical contents and 1 page for references) related to their ongoing research. A program committee will review the submissions, deciding the rejection or acceptance of the papers. In the latter case, the papers will be discussed in an oral or poster session. The best submission will be recognized at the symposium/conference.' 
        },
        {
          type:'primaryTitle',
          text:'Description of the Event',
        },
        {
          type:'text',
          text:'The Doctoral Symposium will take place during the main conference in oral and poster presentation formats. For each accepted oral paper, a panel of senior experts (expressly chosen by the Doctoral Symposium co-chairs to match at best the topics to be covered) will interact with the author and the audience during the presentation, providing constructive advice and suggestions. In order to maximize discussion effectiveness during the presentations, the selected candidates will be interviewed by the panelists before the main conference. All accepted PhD students will have chance to present their work at the conference Poster Session, to enable interaction with a broader audience of the main conference.',
        },
        {
          type:'primaryTitle',
          text:'Eligibility',
        },
        {
          type:'text',
          text:'Prospective student attendees should already have a clear research direction and possibly have published some results. Preference will be given to students who are about at the middle of their PhD work. Students who have just started their doctoral program are advised to consult with their local advisor/supervisor before submitting a paper.',
        },
        {
          type:'text',
          text:'Students who are approaching the end of their PhD should rather strive for submitting a paper at the main conference or at a workshop. The Doctoral Symposium should not be used for placing just another publication.',
        },
        {
          type:'primaryTitle',
          text:'Submission Guidelines',
        },
        {
          type:'text',
          text:'Each doctoral student who wants to submit a paper for the Doctoral Symposium should prepare:',
        },
        {
          type:'text',
          text:'<b>1. Abstract:</b>',
        },
        {
          type:'text',
          text:'A 3-4 page abstract summarizing her/his dissertation research, singly authored by herself/himself. The use of figures, diagrams or illustrations is encouraged. The abstract should include:',
        },
        {
          type:'noListBlack',
          text:'Motivation, problem description',
        },
        {
          type:'noListBlack',
          text:'Background and related work',
        },
        {
          type:'noListBlack',
          text:'Novelty and significance relative to the state of the art',
        },
        {
          type:'noListBlack',
          text:'Approach and proposed experiments (where appropriate)',
        },
        {
          type:'noListBlack',
          text:'Results obtained and work in progress',
        },
        {
          type:'noListBlack',
          text:'Keywords and key references of the presented work',
        },
        {
          type:'noListBlack',
          text:'Discussion of legal, ethical and privacy implications of the work',
        },
        {
          type:'noListBlack',
          text:'Discussion of the measures taken to ensure reproducibility of the work',
        },
        {
          type:'noListBlack',
          text:'Keywords and key references of the presented work',
        },
        {
          type:'text',
          text:'The submission must comply with the two-column ACM proceedings format. The Template is available at:'+
          '<br><a class="demo-link" href=" https://www.acm.org/publications/proceedings-template/"> https://www.acm.org/publications/proceedings-template/</a>.',
        },
        {
          type:'text',
          text:'<br><b>2. Recommendation Letter:</b>',
        },
        {
          type:'text',
          text:'A letter of recommendation from the student\'s PhD advisor, verifying:',
        },
        {
          type:'noListBlack',
          text:'The student status of the applicant',
        },
        {
          type:'noListBlack',
          text:'Date of advancement to PhD candidacy',
        },
        {
          type:'noListBlack',
          text:'The expected date of thesis defense or graduation',
        },
        {
          type:'text',
          text:'<br><b>3. Personal document:</b>',
        },
        {
          type:'noListBlack',
          text:'A short CV, including the list of all publications.',
        },
        {
          type:'noListBlack',
          text:'A short (~200 words) statement from the student on why she/he is interested in participating in the Doctoral Symposium. At the end of the statement, the student should state suggestions of multimedia experts that she/he would be interested in discussing her/his work with. (There is no guarantee that these experts are available, but we will try to take each student\'s "wish list" into account.)',
        },
        {
          type:'text',
          text:'<br>All three documents must be written in English.',
        },
        {
          type:'primaryTitle',
          text:'Review Process and Publication',
        },
        {
          type:'text',
          text:'Review is single-blind. Selections will be based on the 3-4 page abstract, singly authored by the student wishing to attend. Selected abstracts will be published in the ACM Multimedia Conference Proceedings. Doctoral students who submit to the Doctoral Symposium are encouraged to submit a paper on their research to the main conference. However, acceptance for participation in the Symposium will be based solely on the abstract written for the event. All abstracts will be reviewed with respect to overall quality of presentation, potential for future impact of the research on the field, and expected benefit to the other doctoral students attending the Doctoral Symposium.'
        },
        {
          type:'text',
          text:'<b>Important note for the authors:</b> The official publication date is the date the proceedings are made available in the ACM Digital Library. This date may be up to two weeks prior to the first day of the conference. The official publication date affects the deadline for any patent filings related to published work.'
        },
        {
          type:'text',
          text:'<b>Registration:</b> Please note that the student registration is sufficient to cover a doctoral symposium accepted article.'
        },
        {
          type:'primaryTitle',
          text:'Important Dates',
        },
        {
          type:'text',
          text:'<b>Please note</b>: The submission deadline is at 11:59 p.m. of the stated deadline date <a class="demo-link" href="https://www.timeanddate.com/time/zones/aoe">Anywhere on Earth</a>.'
        },
        {
          type:'noList',
          text:'Submission Deadline: August 1, 2021'
        },
        // {
        //   type:'noList',
        //   text:'Notification of acceptance: July 15, 2020'
        // },
        {
          type: 'noList',
          text: 'Camera-ready Submission: August 18, 2021',
        },
        {
          type:'primaryTitle',
          text:'Contacts',
        },
        {
          type:'text',
          text:'For any questions regarding tutorials please email the Doctoral Symposium Chairs <a href="mailto:mm21-dsc@sigmm.org"> &lt;mm21-dsc@sigmm.org&gt;</a>:'
        },
        {
          type:'noListBlack',
          text:'<b>Mohan Kankanhalli</b> (mohan@comp.nus.edu.sg), National University of Singapore, Singapore'
        },
        {
          type:'noListBlack',
          text:'<b>Bing Zeng</b> (eezeng@uestc.edu.cn), University of Electronic Science and Technology of China, China'
        },
      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Doctoral Symposium'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>