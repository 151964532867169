import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('@/views/Index'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import('@/views/home/Index'),
            },
        ]
    },
    {
        path: '/important-dates',
        name: 'Dates',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/important-dates/Index')
            }
        ]
    },
    // todo Organization 开始
    {
        path: '/organization-committee',
        name: 'Organization',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/organization/organizing-committee')
            }
        ]
    },
    {
        path: '/reviewer-guidelines',
        name: 'Reviewer Guidelines',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/organization/reviewer-guidelines')
            }
        ]
    },
    // todo Organization 结束
    {
        path: '/sponsors',
        name: 'Sponsors',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/sponsor/sponsors')
            }
        ]
    },
    {
        path: '/call-for-sponsors',
        name: 'Reviewer Guidelines',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/sponsor/call-for-sponsors')
            }
        ]
    },
    // todo 注册
    {
        path: '/registration',
        name: 'registration',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/attend/registration')
            }
        ]
    },    // todo 注册
    {
        path: '/attendee-guide',
        name: 'attendee-guide',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/attend/attendee-guide')
            }
        ]
    },
    {
        path: '/app-guide',
        name: 'app-guide',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/attend/app-guide')
            }
        ]
    },
    {
        path: '/whova-guide',
        name: 'whova-guide',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/attend/whova-guide')
            }
        ]
    },
    {
        path: '/travel-information',
        name: 'travel-information',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/attend/travel-information')
            }
        ]
    },
    {
        path: '/conference-venue',
        name: 'conference-venue',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/attend/conference-venue')
            }
        ]
    },
    {
        path: '/student-travel-grants',
        name: 'student-travel-grants',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/attend/student-travel-grants')
            }
        ]
    },    // todo 学生
    {
        path: '/author-instruction',
        name: 'author-instruction',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/attend/author-instruction')
            }
        ]
    },
    {
        path: '/sigmm-carer-awards',
        name: 'sigmm-carer-awards',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/attend/sigmm-carer-awards')
            }
        ]
    },
    {
        path: '/recommended-accommodation',
        name: 'recommended-accommodation',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/attend/recommended-accommodation')
            }
        ]
    },
    {
        path: '/keynotes',
        name: 'keynotes',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/keynotes')
            }
        ]
    },
    {
        path: '/program-at-a-glance',
        name: 'program-at-a-glance',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/program-at-a-glance')
            }
        ]
    },
    {
        path: '/full-draft-program',
        name: 'full-draft-program',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/full-draft-program')
            }
        ]
    },
    {
        path: '/tutorials',
        name: 'tutorials',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/tutorials')
            }
        ]
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/panel')
            }
        ]
    },
    {
        path: '/main-track-list',
        name: 'maintracklist',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/main-track-list')
            }
        ]
    },
    {
        path: '/multimedia-grand-challenge',
        name: 'multimedia-grand-challenge',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/multimedia-grand-challenge')
            }
        ]
    },
    {
        path: '/awards',
        name: 'awards',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/awards')
            }
        ]
    },
    // todo calls-for-submission 开始
    {
        path: '/industrial-track-proposal',
        name: 'industrial-track-proposal',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/industrial-track-proposal')
            }
        ]
    },
    {
        path: '/doctoral-cymposium',
        name: 'doctoral-cymposium',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/doctoral-cymposium')
            }
        ]
    },
    {
        path: '/brave-new-ideas-proposals',
        name: 'brave-new-ideas-proposals',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/brave-new-ideas-proposals')
            }
        ]
    },
    {
        path: '/interactive-arts',
        name: 'interactive-arts',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/interactive-arts')
            }
        ]
    },
    {
        path: '/multimedia-grand-challenge-proposals',
        name: 'multimedia-grand-challenge-proposals',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/multimedia-grand-challenge-proposals')
            }
        ]
    },
    {
        path: '/multimedia-grand-challenge-submissions',
        name: 'multimedia-grand-challenge-submissions',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/multimedia-grand-challenge-submissions')
            }
        ]
    },
    {
        path: '/open-source-software-competition',
        name: 'open-source-software-competition',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/open-source-software-competition')
            }
        ]
    },
    {
        path: '/panel-proposals',
        name: 'panel-proposals',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/panel-proposals')
            }
        ]
    },
    {
        path: '/workshop-proposals',
        name: 'workshop-proposals',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/workshop-proposals')
            }
        ]
    },
    {
        path: '/regular-papers',
        name: 'regular-papers',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/regular-papers')
            }
        ]
    },
    {
        path: '/reproducibility-companion-papers',
        name: 'reproducibility-companion-papers',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/reproducibility-companion-papers')
            }
        ]
    },
    {
        path: '/technical-demos-and-videos-program',
        name: 'technical-demos-and-videos-program',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/technical-demos-and-videos-program')
            }
        ]
    },
    {
        path: '/tutorial-proposals',
        name: 'tutorial-proposals',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/tutorial-proposals')
            }
        ]
    },
    {
        path: '/workshop-papers',
        name: 'workshop-papers',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/workshop-papers')
            }
        ]
    },
    // todo calls-for-submission 结束
]

const router = new VueRouter({
    mode: 'history', // 去掉url中的#/
    routes,
})

export default router
