<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{ mainTitle }}
      </el-col>
    </el-row>

    <div v-for="(item,index) in Keynote" :key="index" :id="item.id">
      <div v-for="(content,index) in item.contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 't_author'">
        <el-col>
          <p class="author-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>

    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tutorial",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      mainTitle: 'Conference Tutorials',
      // personnel: [
      //   {
      //     person: [
      //       {
      //         //定位id
      //         id: "1",
      //         image: require('@/assets/keynotes/Wen_Gao.jpg'),
      //         keytitle: 'Video Coding for Machine',
      //         name: 'Wen Gao',
      //         address: 'Peking University & Peng-Cheng Laboratory',
      //       },
      //       {
      //         id: "2",
      //         image: require('@/assets/keynotes/Jagadish.jpg'),
      //         keytitle: 'Semantic Media Conversion: Possibilities and Limits',
      //         name: 'H. V. Jagadish',
      //         address: 'University of Michigan',
      //       },
      //       {
      //         id: "3",
      //         image: require('@//assets/keynotes/Cordelia_Schmid.jpg'),
      //         keytitle: 'Do you see what I see? Large-scale Learning from Multimodal Videos',
      //         name: 'Cordelia Schmid',
      //         address: 'Inria/Google',
      //       },
      //     ],
      //   }
      // ],
      // personnel2: [
      //   {
      //     person: [
      //       {
      //         id: "4",
      //         turn: 'photo_4',
      //         image: require('@/assets/keynotes/James_Lester.jpg'),
      //         keytitle: 'AI and the Future of Education',
      //         name: 'James Lester',
      //         address: 'North Carolina State University',
      //       },
      //       {
      //         id: "5",
      //         turn: 'photo_5',
      //         image: require('@/assets/keynotes/Zhengyou_Zhang.jpg'),
      //         keytitle: 'Digital Human in an Integrated Physical-Digital World (IPhD)',
      //         name: 'Zhengyou Zhang',
      //         address: 'Tencent AI Lab & Tencent Robotics X',
      //       },
      //       {
      //         id: "6",
      //         turn: 'photo_6',
      //         image: require('@/assets/keynotes/Jingren_Zhou.jpg'),
      //         keytitle: 'Large-scale Multi-Modality Pretrained Models: Applications and Experiences',
      //         name: 'Jingren Zhou',
      //         address: 'Ant Group',
      //       }
      //     ],
      //   }
      // ],
      // 定位ok
      Keynote: [
        {
          // 定位id
          id: "1",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 1',
            },
            {
              type: 'textBlue',
              text: '<b>Title: Image Quality Assessment in the Modern Age</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Kede Ma, City University of Hong Kong'
            },
            {
              type: 't_author',
              text: 'Yuming Fang, Jiangxi University of Finance and Economics'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 24 October, 2021 14:00-17:00 (GMT+8 time zone)'
            },
            {
              type: 'text',
              text: '<b>Abstract: </b>This tutorial provides the audience with the basic theories, methodologies, and current progresses of image quality assessment (IQA). From an actionable perspective, we will first revisit several subjective quality assessment methodologies, with emphasis on how to properly select visual stimuli. We will then present in detail the design principles of objective quality assessment models, supplemented by an in-depth analysis of their advantages and disadvantages. Both hand-engineered and (deep) learning-based methods will be covered. Moreover, the limitations with the conventional model comparison methodology for objective quality models will be pointed out, and novel comparison methodologies such as those based on the theory of "analysis by synthesis" will be introduced. We will last discuss the real-world multimedia applications of IQA, and give a list of open challenging problems, in the hope of encouraging more and more talented researchers and engineers devoting to this exciting and rewarding research field.'
            },
          ]
        },
        {
          // 定位id
          id: "2",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 2',
            },
            {
              type: 'textBlue',
              text: '<b>Title: Trustworthy Multimedia Analysis</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Xiaowen Huang, Beijing Jiaotong University'
            },
            {
              type: 't_author',
              text: 'Jiaming Zhang, Beijing Jiaotong University'
            },
            {
              type: 't_author',
              text: 'Yi Zhang, Beijing Jiaotong University'
            },
            {
              type: 't_author',
              text: 'Xian Zhao, Beijing Jiaotong University'
            },
            {
              type: 't_author',
              text: 'Jitao Sang, Beijing Jiaotong University'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 20 October, 2021 14:00-17:00 (GMT+8 time zone)'
            },
            {
              type: 'text',
              text: '<b>Abstract: </b>This tutorial discusses the trustworthiness issue in multimedia analysis. Starting from introducing two types of spurious correlations learned from distilling human knowledge, we partition the (visual) feature space along two dimensions of task-relevance and semantic-orientation. Trustworthy multimedia analysis ideally relies on the task-relevant semantic features and consists of three modules as trainer, interpreter and tester. These three modules essentially form a closed loop, which respectively address goals of extracting task-relevant features, extracting task-relevant semantic features, and detecting spurious correlations to be corrected by the trainer and interpreter.'
            },
          ]
        },
        {
          // 定位id
          id: "3",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 3',
            },
            {
              type: 'textBlue',
              text: '<b>Title: Multimedia Classifiers: Behind the Scenes</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Manjunath Iyer, Wipro'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 20 October, 2021 9:00-12:00 (GMT+8 time zone)'
            },
            {
              type: 'text',
              text: '<b>Abstract: </b>This tutorial provides an in-depth understanding of the art and science behind the decision-making in a multimedia classifier. A multimedia classifier typically takes image, text, waveform, ordinal number or categorical data or their combination as the input and produces a single output indicating the class of the input pattern. Such a piece of AI ML system is extensively used as a decision-making element in several autonomous systems. The yardstick used by the human experts for decision making for the same input pattern often differs from the system, still producing the same output. In some cases, the outputs differ for the same input data and throws open a question on the reliability of the model. If such models are used in critical applications, which is often the case in an autonomous system, adequate mitigations for minimizing impact of the misjudgment has to be taken. It calls for ripping open the decision making process in the black box classifiers. Unwinding the black box is the need of the hour for the regulatory bodies as well. EU region has already made it mandatory to provide the details of the decision-making mechanism if it involves some form of AI ML components. This tutorial throws light on the decision making process in a classifier that may be used for a variety of applications. More than one technique to get a glimpse of the classifier in action would be discussed. The explanation can come in the form of ta heatmap indicating the relevant features influencing the decision making process, patterns learnt by the neurons or Textual description of the attributes of the input. The bottom line is these explanations are to be consistent. The mechanism to achieve the coherent explanation would be detailed in this tutorial.'
            },
          ]
        },
        {
          // 定位id
          id: "4",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 4',
            },
            {
              type: 'textBlue',
              text: '<b>Title: Few-shot Learning for Multi-Modality Tasks</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Jie Chen, Peking University & Peng Cheng Laboratory'
            },
            {
              type: 't_author',
              text: 'Qixiang Ye, University of Chinese Academy of Sciences & Peng Cheng Laboratory'
            },
            {
              type: 't_author',
              text: 'Xiaoshan Yang, University of Chinese Academy of Sciences & Peng Cheng Laboratory'
            },
            {
              type: 't_author',
              text: 'S. Kevin Zhou, University of Science and Technology of China & Peng Cheng Laboratory'
            },
            {
              type: 't_author',
              text: "Xiaopeng Hong, Xi'an Jiaotong University & Peng Cheng Laboratory"
            },
            {
              type: 't_author',
              text: 'Li Zhang, Fudan University'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 24 October, 2021 9:00-12:00 (GMT+8 time zone)'
            },
            {
              type: 'text',
              text: '<b>Abstract: </b>Recent deep learning methods rely on a large amount of labeled data to achieve high performance. These methods may be impractical in some scenarios, where manual data annotation is costly or the samples of certain categories are scarce (e.g., tumor lesions, endangered animals and rare individual activities). When only limited annotated samples are available, these methods usually suffer from the overfitting problem severely, which degrades the performance significantly. In contrast, humans can recognize the objects in the images rapidly and correctly with their prior knowledge after exposed to only a few annotated samples. To simulate the learning schema of humans and relieve the reliance on the large-scale annotation benchmarks, researchers start shifting towards the few-shot learning problem: they try to learn a model to correctly recognize novel categories with only a few annotated samples.'
            },
          ]
        },
        {
          // 定位id
          id: "5",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 5',
            },
            {
              type: 'textBlue',
              text: '<b>Title: Plenoptic Quality Assessment: The JPEG Pleno Experience</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Antonio M. G. Pinheiro, Universidade da Beira Interior and Instituto de Telecomunicacoes'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 24 October, 2021 14:00-17:00 (GMT+8 time zone)'
            },
            {
              type: 'text',
              text: '<b>Abstract: </b>Plenoptic representations, like light fields, point clouds or digital holography, provide the means for 3D representations suitable for multiple immersive and computer vision applications. JPEG has been standardizing coding tools for these types of plenoptic data in its project JPEG Pleno. This standardization effort has been developing quality assessment models suitable for the quality evaluation of the coding technologies.'
            },
            {
              type: 'text',
              text: 'In this tutorial the quality assessment methodologies defined for the evaluation of the different proposals of the three plenoptic modalities, are explained. The tutorial also includes possible alternatives considered in the definition of the quality assessment models and the selection of appropriate anchors decided during JPEG Pleno development process.'
            },
          ]
        },
        {
          // 定位id
          id: "6",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 6',
            },
            {
              type: 'textBlue',
              text: '<b>Title: A Tutorial on AI Music Composition</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Xu Tan, Microsoft Research Asia'
            },
            {
              type: 't_author',
              text: 'Xiaobing Li, Central Conservatory of Music'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 24 October, 2021 9:00-12:00 (GMT+8 time zone)'
            },
            {
              type: 'text',
              text: '<b>Abstract: </b>AI music composition is one of the most attractive and important topics in artificial intelligence, music, and multimedia. The typical tasks in AI music composition include melody generation, song writing, accompaniment generation, arrangement, performance generation, timbre rendering, sound generation, and singing voice synthesis, which cover different modalities (e.g., symbolic music score, sound) and well match to the theme of ACM Multimedia. As the rapid development of artificial intelligence techniques such as content creation and deep learning, AI based music composition has achieved rapid progress, but still encountered a lot of challenges. A thorough introduction and review on the basics, the research progress, as well as how to address the challenges in AI music composition are timely and necessary for a broad audience working on artificial intelligence, music, and multimedia. In this tutorial, we will first introduce the background of AI music composition, including music basics and deep learning techniques for music composition. Then we will introduce AI music composition from two perspectives: 1) key components, which include music score generation, music performance generation, and music sound generation; 2) advanced topics, which include music structure/form/style/emotion modeling, timbre synthesis/transfer/mixing, etc. At last, we will point out some research challenges and future directions in AI music composition. This tutorial can serve both academic researchers and industry practitioners working on AI music composition.'
            },
          ]
        },
        {
          // 定位id
          id: "7",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 7',
            },
            {
              type: 'textBlue',
              text: '<b>Title: Out-of-distribution Generalization and Its Applications for Multimedia</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Xin Wang, Tsinghua University'
            },
            {
              type: 't_author',
              text: 'Peng Cui, Tsinghua University'
            },
            {
              type: 't_author',
              text: 'Wenwu Zhu, Tsinghua University'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 20 October, 2021 14:00-17:00 (GMT+8 time zone)'
            },
            {
              type: 'text',
              text: '<b>Abstract: </b>Out-of-distribution generalization is becoming a hot research topic in both academia and industry. This tutorial is to disseminate and promote the recent research achievements on out-of-distribution generalization as well as their applications on multimedia, which is an exciting and fast-growing research direction in the general field of machine learning and multimedia. We will advocate novel, high-quality research findings, as well as innovative solutions to the challenging problems in out-of-distribution generalization and its applications for multimedia. This topic is at the core of the scope of ACM Multimedia, and is attractive to MM audience from both academia and industry.'
            },
          ]
        },
        {
          // 定位id
          id: "8",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Tutorial 8',
            },
            {
              type: 'textBlue',
              text: '<b>Title: Deep Learning for Visual Data Compression</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>'
            },
            {
              type: 't_author',
              text: 'Guo Lu, Beijing Institute of Technology'
            },
            {
              type: 't_author',
              text: 'Ren Yang, ETH Zürich'
            },
            {
              type: 't_author',
              text: 'Shenlong Wang, University of Illinois at Urbana-Champaign'
            },
            {
              type: 't_author',
              text: 'Shan Liu, Tencent'
            },
            {
              type: 'text',
              text: '<b>Date: </b> 24 October, 2021 9:00-12:00 (GMT+8 time zone)'
            },
            {
              type: 'text',
              text: '<b>Abstract: </b>In this paper, we will introduce the recent progress in deep learning based visual data compression, including image compression, video compression and point cloud compression. In the past few years, deep learning techniques have been successfully applied to various computer vision and image processing applications. However, for the data compression task, the traditional approaches (i.e., block based motion estimation and motion compensation, etc.) are still widely employed in the mainstream codecs. Considering the powerful representation capability of neural networks, it is feasible to improve the data compression performance by employing the advanced deep learning technologies. To this end, the deep leaning based compression approaches have recently received increasing attention from both academia and industry in the field of computer vision and signal processing.'
            },
          ]
        },
      ],


    //   // //已经无效
    //   // contents: [

    //   ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Conference Tutorials'
  }
  ,
  methods: {
    goAnchor(id) {
      var anchor = document.getElementById(id);
      // chrome
      document.body.scrollTop = anchor.offsetTop;
      // firefox
      document.documentElement.scrollTop = anchor.offsetTop;
      // safari
      window.pageYOffset = anchor.offsetTop;
    }
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";

.photo {
  width: 28%;
  margin-top: 1.8rem;
  vertical-align: top;
  /*display: inline-block;*/
}

.parent {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: -3rem;
}

.personnel {
  float: left;
  width: 22rem;
  height: 36rem;
  background: #FFFFFF;
  border-radius: 1.8rem;
  border: 2px solid #EEEEEE;
  margin-right: 2rem;
  margin-left: 2rem;
  text-align: center;
  /*margin-bottom: -4rem;*/

}

.personnel:hover {
  box-shadow: 0 0.2rem 0.5rem 0 #B3B3B3;
}

.personnels {
  text-align: center;
  margin-bottom: 2rem;
}

.pre-header > div {
  width: 19rem;
  height: 19rem;
  /*background-color: red;*/
  margin: 1rem auto 1rem;
  border-radius: 1rem;
}

.pre-header > div > img {
  width: 100%;
}

.pre-title > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #0054B0;
  margin-top: 0.6rem;
  /*line-height: 19px;*/
}

.pre-middle > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #272727;
  margin-top: 0.6rem;
  /*line-height: 19px;*/
}

.pre-button {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-top: 0.6rem;

}

.pre-button > p {
  font-size: 1.4rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-family: Roboto-Medium, Roboto, sans-serif;
  font-weight: 500;

  color: #272727;
  /*line-height: 16px;*/
}

.pre {
  margin-top: 2rem;
}

@media screen  and (max-width: 750px) {
  .personnel p {
    margin: 0 0 .8rem !important;
  }

  .parent .personnel:first-child {
    margin-left: 2rem;
  }

  .personnel {
    margin-right: 1.2rem;
  }

  .personnels {
    width: 105%;
  }
}

.parent > div:first-child {
  margin-left: 2rem !important;
}


</style>
