<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{ mainTitle }}
      </el-col>
    </el-row>
    <!--第一排-->
    <div class="pre">
      <el-row v-for="(item,index2) in personnel" :key="index2">
        <el-col class="personnels">
          <div class="parent clearfix">
            <div class="personnel" v-for="(pre,index3) in item.person" :key="index3" @click="goAnchor(pre.id)">
              <div class="pre-header">
                <div>
                  <img :src="pre.image" alt="">
                </div>
              </div>
              <div class="pre-title">
                <p>{{ pre.keytitle }}</p>
              </div>
              <div class="pre-middle">
                <p>{{ pre.name }}</p>
              </div>
              <div class="pre-button">
                <p>{{ pre.address }}</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--第二排-->
    <div class="pre">
      <el-row v-for="(item,index2) in personnel2" :key="index2">
        <el-col class="personnels">
          <div class="parent clearfix">
            <div class="personnel" v-for="(pre,index3) in item.person" :key="index3" @click="goAnchor(pre.id)">
              <div class="pre-header">
                <div>
                  <img :src="pre.image" alt="">
                </div>
              </div>
              <div class="pre-title">
                <p>{{ pre.keytitle }}</p>
              </div>
              <div class="pre-middle">
                <p>{{ pre.name }}</p>
              </div>
              <div class="pre-button">
                <p>{{ pre.address }}</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div v-for="(item,index) in Keynote" :key="index" :id="item.id">
      <div v-for="(content,index) in item.contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'photo1'">
        <el-col>
          <img id='photo_1' class="photo" :src="photo_Wen_Gao" alt=" ">
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'photo2'">
        <el-col>
          <img id="photo_2" class="photo" :src="photo_Jagadish" alt=" ">
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'photo3'">
        <el-col>
          <img id="photo_3" class="photo" :src="photo_Cordelia_Schmid" alt=" ">
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'photo4'">
        <el-col>
          <img id="photo_4" class="photo" :src="photo_James_Lester" alt=" ">
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'photo5'">
        <el-col>
          <img id="photo_5" class="photo" :src="photo_Zhengyou_Zhang" alt=" ">
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'photo6'">
        <el-col>
          <img id="photo_6" class="photo" :src="photo_Jingren_Zhou" alt=" ">
        </el-col>
      </el-row>


    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "keynotes",
  data() {
    return {
      iconImage: require("@/assets/content/sy_icon_xm.png"),
      photo_Wen_Gao: require("@/assets/keynotes/Wen_Gao.jpg"),
      photo_Jagadish: require("@/assets/keynotes/Jagadish.jpg"),
      photo_Cordelia_Schmid: require("@/assets/keynotes/Cordelia_Schmid.jpg"),
      photo_James_Lester: require("@/assets/keynotes/James_Lester.jpg"),
      photo_Zhengyou_Zhang: require("@/assets/keynotes/Zhengyou_Zhang.jpg"),
      photo_Jingren_Zhou: require("@/assets/keynotes/Jingren_Zhou.jpg"),
      mainTitle: 'Conference Keynotes',
      personnel: [
        {
          person: [
            {
              //定位id
              id: "1",
              image: require('@/assets/keynotes/Wen_Gao.jpg'),
              keytitle: 'Video Coding for Machine',
              name: 'Wen Gao',
              address: 'Peking University & Peng-Cheng Laboratory',
            },
            {
              id: "2",
              image: require('@/assets/keynotes/Jagadish.jpg'),
              keytitle: 'Semantic Media Conversion: Possibilities and Limits',
              name: 'H. V. Jagadish',
              address: 'University of Michigan',
            },
            {
              id: "3",
              image: require('@//assets/keynotes/Cordelia_Schmid.jpg'),
              keytitle: 'Do you see what I see? Large-scale Learning from Multimodal Videos',
              name: 'Cordelia Schmid',
              address: 'Inria/Google',
            },
          ],
        }
      ],
      personnel2: [
        {
          person: [
            {
              id: "4",
              // turn: 'photo_6',
              image: require('@/assets/keynotes/Jingren_Zhou.jpg'),
              keytitle: 'Large-scale Multi-Modality Pretrained Models: Applications and Experiences',
              name: 'Jingren Zhou',
              address: 'Ant Group',
            },
            {
              id: "5",
              // turn: 'photo_4',
              image: require('@/assets/keynotes/James_Lester.jpg'),
              keytitle: 'AI and the Future of Education',
              name: 'James Lester',
              address: 'North Carolina State University',
            },
            {
              id: "6",
              // turn: 'photo_5',
              image: require('@/assets/keynotes/Zhengyou_Zhang.jpg'),
              keytitle: 'Digital Human in an Integrated Physical-Digital World (IPhD)',
              name: 'Zhengyou Zhang',
              address: 'Tencent AI Lab & Tencent Robotics X',
            }
          ],
        }
      ],
      // 定位ok
      Keynote: [
        {
          // 定位id
          id: "1",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Keynote 1',
            },
            {
              type: 'textBlue',
              text: '<b>Title: Video Coding for Machine</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>Wen Gao, Peking University & Peng-Cheng Laboratory'
            },
            {
              type: 'text',
              text: '<b>Date: </b>21 October, 2021 8:30-9:20 (GMT+8 time zone)'
            },
            {
              type: 'photo1',
              text: 'photo'
            },
            {
              type: 'text',
              text: '<b>Abstract: </b>Video coding systems, started for TV broadcasting services over satellite and cable networks with limited bandwidth, later on used for surveillance video and internet video, those target on higher compression ratio with lower quality lose, under the trade-off of RDO (rate distortion optimization) model, judged by human experts. In other word, current video coding standards are good for people, for human visual perception, not design for machine intelligence. However, today more and more applications from industry require video coding for machine, which targets to compress image and video for machine usage, object detection and or tracking, image classification, event analysis, and so on, those target on higher compression ratio with higher recognition accuracy, under the trade-off of RAO (rate accuracy optimization) model, judged by system. In this case, video coding needs to do feature compression, which preserves and transmits the most critical information for computer vision and pattern recognition, not for human visual perception. So it is quite different between video coding for human and video coding for machine, even if the two systems will coexist for a long time. In this talk, I will introduce the history of VCM, list some early works on pattern analysis based on compressed data domain, some efforts from ISO/IEC MPEG group on MPEG-7 CDVS (compact descriptor for visual search) and CDVA (compact descriptors for visual analysis), some ongoing projects on AVS working group and MPEG working group, give the key techniques and challenges on VCM, and overview its future.'
            },
            {
              type: 'text',
              text: '<b>Bio: </b>Wen Gao now is a Boya Chair Professor at Peking university, and the Director of Pengcheng Laboratory. He received his Ph.D. degree in electronics engineering from the University of Tokyo in 1991. He joined with Harbin Institute of Technology from 1991 to 1996, and Institute of Computing Technology (ICT), Chinese Academy of Sciences (CAS) from 1996 to 2006. He joined the Peking University since February of 2006. He has authored or co-authored five books and over 1000 technical articles in refereed journals and conference proceedings in the areas of image processing, video coding and communication, computer vision, multimedia retrieval, multimodal interface, and bioinformatics. '
            },
            {
              type: 'text',
              text: 'Prof. Gao served or serves on the editorial board for several journals, such as IEEE Transactions on Image Processing, IEEE Transactions on Circuits and Systems for Video Technology, IEEE Transactions on Multimedia, IEEE Transactions on Autonomous Mental Development, EURASIP Journal of Image Communications, Journal of Visual Communication and Image Representation. He chaired a number of prestigious international conferences on multimedia and video signal processing, such as IEEE ICME 2007, ACM Multimedia 2009, IEEE ISCAS 2013, and also served on the advisory and technical committees of numerous professional organizations. He is the deputy Director of China National Standardization Technical Committees. He was the Vice President of National Natural Science Foundation (NSFC) of China from 2013 to 2018, the President of China Computer Federation (CCF) since 2016 to 2020. Prof. Gao is an academician of Chinese Academy of Engineering, a fellow of ACM, and a fellow of IEEE. '
            },
          ]
        },
         {
          // 定位id
          id: "2",
          contents: [
            {
              type: 'primaryTitle',
              text: 'Keynote 2',
            },
            {
              type: 'textBlue',
              text: '<b>Title: Semantic Media Conversion: Possibilities and Limits</b>'
            },
            {
              type: 'text',
              text: '<b>Presenter: </b>H. V. Jagadish, University of Michigan'
            },
            {
              type: 'text',
              text: '<b>Date: </b>21 October, 2021  9:20-10:10 (GMT+8 time zone)'
            },
            {
              type: 'photo2',
              text: 'photo'
            },
            {
              type: 'text',
              text: "<b>Abstract: </b>With recent amazing progress in machine intelligence, it is becoming increasingly easy to “convert” information reliably from one medium to another.  For example, there is already a regular annual conference on “Text as Data”.  We will soon have similar facility to deal with images, videos, music, and so on.  Let's call this semantic media conversion."
            },
            {
              type: 'text',
              text: 'In this talk, I will outline some possibilities with high quality semantic media conversion.  In particular, it becomes possible to convert all media into alphanumeric data, nicely organized in structured tables with limited loss of information.  Multimedia data, so converted, becomes easy to use, to aggregate, and to analyze, leading to new Data Science opportunities.'
            },
            {
              type: 'text',
              text: 'But this ease of analysis also leads to questions of appropriateness. '
            },
            {
              type: 'retractBlack',
              text: "We shouldn't necessarily do everything that we have the ability to do."
            },
            {
              type: 'retractBlack',
              text: "What are our values"
            },
            {
              type: 'retractBlack',
              text: "How do we apply them in practice"
            },
            {
              type: 'retractBlack',
              text: "What limits do we apply to semantic media conversion and the analysis enabled by it."
            },
            {
              type: 'text',
              text: '<b>Bio: </b>H. V. Jagadish is Bernard A Galler Collegiate Professor of Electrical Engineering and Computer Science at the University of Michigan in Ann Arbor, and Director of the Michigan Institute for Data Science.  Prior to 1999, he was Head of the Database Research Department at AT&T Labs, Florham Park, NJ. '
            },
            {
              type: 'text',
              text: 'Professor Jagadish is well known for his broad-ranging research on information management, and has approximately 200 major papers and 37 patents, with an H-index of 94. He is a fellow of the ACM, "The First Society in Computing," (since 2003) and of AAAS (since 2018).  He served on the board of the Computing Research Association (2009-2018). He has been an Associate Editor for the ACM Transactions on Database Systems (1992-1995), Program Chair of the ACM SIGMOD annual conference (1996), Program Chair of the ISMB conference (2005), a trustee of the VLDB (Very Large DataBase) foundation (2004-2009), Founding Editor-in-Chief of the Proceedings of the VLDB Endowment (2008-2014), and Program Chair of the VLDB Conference (2014). Since 2016, he is Editor of the Morgan & Claypool Synthesis Lecture Series on Data Management.  Among his many awards, he won the David E Liddle Research Excellence Award (at the University of Michigan) in 2008, the ACM SIGMOD Contributions Award in 2013, and the Distinguished Faculty Achievement Award (at the University of Michigan) in 2019.  His popular MOOC on Data Science Ethics is available on EdX, Coursera, and Futurelearn.'
            },
          ]
        },
        {
          // 定位id
          id: "3",
          contents: [
{
          type: 'primaryTitle',
          text: 'Keynote 3',
        },
        {
          type: 'textBlue',
          text: '<b>Title: Do you see what I see? Large-scale Learning from Multimodal Videos</b>'
        },
        {
          type: 'text',
          text: '<b>Presenter: </b>Cordelia Schmid, Inria/Google'
        },
        {
          type: 'text',
          text: '<b>Date: </b>22 October, 2021 14:00-14:50 (GMT+8 time zone)'
        },
        {
          type: 'photo3',
          text: 'photo'
        },
        {
          type: 'text',
          text: '<b>Abstract: </b>In this talk we present recent progress on large-scale learning of multimodal video representations. We start by presenting VideoBert, a joint model for video and language, repurposing the Bert model for multimodal data. This model achieves state-of-the-art results on zero shot prediction and video captioning. Next we show how to extend learning from instruction videos to general movies based on cross-modal supervision. We use movie screenplays to learn a speech to action classifiers and use these classifiers to mine video clips from thousands of hours of movies. We demonstrate a performance comparable or better than fully supervised approaches for action classification. Next we present an approach for video question answering which relies on training from instruction videos and cross-modal supervision with a textual question answer module. We show state-of-the-art results for video question answering without any supervision (zero-shot VQA) and demonstrate that our approach obtains competitive results for pre-training and then fine-tuning on video question answering datasets. We conclude our talk by presenting a recent video feature which is fully transformer based. Our Video Vision Transformer (ViViT) is shown to outperform the state-of-the-art. Furthermore, it is flexible and allows for performance / accuracy trade-off based on several different architectures. '
        },
        {
          type: 'text',
          text: '<b>Bio: </b>Cordelia Schmid holds a M.S. degree in Computer Science from the University of Karlsruhe and a Doctorate from the Institut National Polytechnique de Grenoble (INPG). Her doctoral thesis received the best thesis award from INPG in 1996. Dr. Schmid was a post-doctoral research assistant in the Robotics Research Group of Oxford University. Since 1997 she has held a permanent research position at Inria, where she is a research director. Dr. Schmid has been an Associate Editor for IEEE PAMI (2001–2005) and for IJCV (2004--2012), editor-in-chief for IJCV (2013–2019), a program chair of IEEE CVPR 2005 and ECCV 2012 as well as a general chair of IEEE CVPR 2015, ECCV 2020 and ICCV 2023. In 2006, 2014 and 2016, she was awarded the Longuet-Higgins prize for fundamental contributions in computer vision that have withstood the test of time. She is a fellow of IEEE. She was awarded an ERC advanced grant in 2013, the Humbolt research award in 2015 and the Inria & French Academy of Science Grand Prix in 2016. She was elected to the German National Academy of Sciences, Leopoldina, in 2017. In 2018 she received the Koenderink prize for fundamental contributions in computer vision that have withstood the test of time. She was awarded the Royal Society Milner award in 2020. Starting 2018 she holds a joint appointment with Google research.  '
        },
          ]
        },
        {
          // 定位id
          id: "4",
          contents: [
{
          type: 'primaryTitle',
          text: 'Keynote 4',
        },
        {
          type: 'textBlue',
          text: '<b>Title: Large-scale Multi-Modality Pretrained Models: Applications and Experiences</b>'
        },
        {
          type: 'text',
          text: '<b>Presenter: </b>Jingren Zhou, Ant Group'
        },
        {
          type: 'text',
          text: '<b>Date: </b>22 October, 2021 14:50-15:40 (GMT+8 time zone)'
        },
        {
          type: 'photo6',
          text: 'photo'
        },
        {
          type: 'text',
          text: '<b>Abstract: </b>In this talk, we present our experiences and applications of large-scale multi-modality pretrained models, developed at Alibaba and Ant Group. We first present a cross-modal pretraining method called M6 (Multi-Modality to Multi-Modality Multitask Mega-transformer), for unified pretraining on the data of multiple modalities. We scale the model size up to 1 trillion parameters, and build the largest pretrained model in Chinese. We apply the model to a series of downstream applications, and demonstrate its outstanding performance in comparison with strong baselines. Furthermore, we specifically design a downstream task of text-guided image generation, and show that the finetuned M6 can create high-quality images with high resolution and fidelity.'
        },
        {
          type: 'text',
          text: 'We also present research and applications of image editing with pretrained Generative Adversarial Networks (GANs). A general principle between the underlying manifold and the generator is discovered. Based on our discovery, we propose an algorithm for GANs with low-rank factorization, which can be harnessed for image editing with pretrained GAN models.'
        },
        {
          type: 'text',
          text: '<b>Bio: </b>Jingren Zhou is Senior Vice President at Ant Group. He is responsible for driving data intelligence infrastructure and several key data-driven businesses at Ant Group. Specifically, he leads work to develop advanced techniques for personalized search, product recommendation, and advertisement at Alipay, a leading online payment and digital lifestyle platform. '
        },
        {
          type: 'text',
          text: 'He also manages data analytics and intelligence research lab at Alibaba DAMO Academy. His research interests include cloud-computing, databases, and large-scale machine learning. He received his PhD in Computer Science from Columbia University. He is a Fellow of IEEE.'
        },
          ]
        },
        {
          // 定位id
          id: "5",
          contents: [
        {
          type: 'primaryTitle',
          text: 'Keynote 5',
        },
        {
          type: 'textBlue',
          text: '<b>Title: AI and the Future of Education</b>'
        },
        {
          type: 'text',
          text: '<b>Presenter: </b>James Lester, North Carolina State University'
        },
        {
          type: 'text',
          text: '<b>Date: </b>23 October, 2021 08:30-09:20 (GMT+8 time zone)'
        },
        {
          type: 'photo4',
          text: 'photo'
        },
        {
          type: 'text',
          text: '<b>Abstract: </b>It has become clear that AI will profoundly transform society. AI will dramatically change the socio-technological landscape, produce seismic economic shifts, and fundamentally reshape the workforce in ways that we are only beginning to grasp. With its imminent arrival, it is critically important to deeply engage with questions around how we should design education in the Age of AI. Fortunately, while we must address the significant challenges posed by AI, we can also leverage AI itself to address these challenges. In this talk we will consider how (and at what rate) AI technologies for education will evolve, discuss emerging innovations in AI-augmented learning environments for formal and informal contexts, and explore what competencies will be elevated in an AI-pervasive workforce. We will discuss near-future AI technologies that leverage advances in natural language processing, computer vision, and machine learning to create narrative-centered learning environments, embodied conversational agents for learning, and multimodal learning analytics. We will conclude by considering what all of these developments suggest for K-12 education and the future of human learning.'
        },
        {
          type: 'text',
          text: '<b>Bio: </b>James Lester is Distinguished University Professor of Computer Science and Director of the Center for Educational Informatics at North Carolina State University. He is the Director of the National Science Foundation AI Institute for Engaged Learning. His research centers on transforming education with artificial intelligence. His current work spans AI-driven narrative learning environments, virtual agents for learning, and multimodal learning analytics. '
        },
        {
          type: 'text',
          text: "He is the recipient of the National Science Foundation's CAREER Award and numerous Best Paper Awards. His foundational work on pedagogical agents has been recognized with the IFAAMAS Influential Paper Award by the International Federation for Autonomous Agents and Multiagent Systems. He has served as Editor-in-Chief of the International Journal of Artificial Intelligence in Education and on the 2020 National Educational Technology Plan Technical Working Group for the US Department of Education. He is a Fellow of the Association for the Advancement of Artificial Intelligence (AAAI). "
        },
          ]
        },
        {
          // 定位id
          id: "6",
          contents: [
{
          type: 'primaryTitle',
          text: 'Keynote 6',
        },
        {
          type: 'textBlue',
          text: '<b>Title: Digital Human in an Integrated Physical-Digital World (IPhD)</b>'
        },
        {
          type: 'text',
          text: '<b>Presenter: </b>Zhengyou Zhang, Tencent AI Lab & Tencent Robotics X'
        },
        {
          type: 'text',
          text: '<b>Date: </b>23 October, 2021 09:20-10:10 (GMT+8 time zone)'
        },
        {
          type: 'photo5',
          text: 'photo'
        },
        {
          type: 'text',
          text: '<b>Abstract: </b>With the rapid development of digital technologies such as VR, AR, XR, and more importantly the almost ubiquitous mobile broadband coverage, we are entering an Integrated Physical-Digital World (IPhD), the tight integration of virtual world with the physical world. The IPhD is characterized with four key technologies: Virtualization of the physical world, Realization of the virtual world, Holographic internet, and Intelligent Agent. Internet will continue its development with faster speed and broader bandwidth, and will eventually be able to communicate holographic contents including 3D shape, appearance, spatial audio, touch sensing and smell. Intelligent agents, such as digital human, and digital/physical robots, travels between digital and physical worlds. In this talk, we will describe our work on digital human for this IPhD world. This includes: computer vision techniques for building digital humans, multimodal text-to-speech synthesis (voice and lip shapes), speech-driven face animation, neural-network-based body motion control, human-digital-human interaction, and an emotional video game anchor.'
        },
        {
          type: 'text',
          text: '<b>Bio: </b>Zhengyou Zhang received the B.S. degree in electronic engineering from Zhejiang University, Hangzhou, China, in 1985, the M.S. degree in computer science from the University of Nancy, Nancy, France, in 1987, and the Ph.D. degree in computer science in 1990 and the Doctorate of Science (Habilitation à diriger des recherches) in 1994 from the University of Paris XI, Paris, France. '
        },
        {
          type: 'text',
          text: 'He is the Chief Scientist at Tencent, China, and is the Director of Tencent AI Lab and Tencent Robotics X since March 2018. Before that, he was a Partner Research Manager with Microsoft Research, Redmond, WA, USA, for 20 years. Before joining Microsoft Research in March 1998, he was a Senior Research Scientist with INRIA (French National Institute for Research in Computer Science and Control), France, for over 10 years. In 1996-1997, he spent a one-year sabbatical as an Invited Researcher with the Advanced Telecommunications Research Institute International (ATR), Kyoto, Japan. '
        },
        {
          type: 'text',
          text: "Dr. Zhang is an ACM Fellow and an IEEE Fellow. He is the Founding Editor-in-Chief of the IEEE Transactions on Autonomous Mental Development (now renamed IEEE Transactions on Cognitive and Developmental Systems), is on the Honorary Board of the International Journal of Computer Vision and on the Steering Committee of the Machine Vision and Applications, and serves or served as an Associate Editor for many journals. He was a General Co-Chair of IEEE Conference on Computer Vision and Pattern Recognition (CVPR) 2017. He received the IEEE Helmholtz Test of Time Award at ICCV 2013 for his paper published in 1999 on camera calibration, now known as Zhang's method."
        },
          ]
        },
      ],


    //   // //已经无效
    //   // contents: [

    //   ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Conference Keynotes'
  }
  ,
  methods: {
    goAnchor(id) {
      var anchor = document.getElementById(id);
      // chrome
      document.body.scrollTop = anchor.offsetTop;
      // firefox
      document.documentElement.scrollTop = anchor.offsetTop;
      // safari
      window.pageYOffset = anchor.offsetTop;
    }
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";

.photo {
  width: 28%;
  margin-top: 1.8rem;
  vertical-align: top;
  /*display: inline-block;*/
}

.parent {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: -3rem;
}

.personnel {
  float: left;
  width: 22rem;
  height: 36rem;
  background: #FFFFFF;
  border-radius: 1.8rem;
  border: 2px solid #EEEEEE;
  margin-right: 2rem;
  margin-left: 2rem;
  text-align: center;
  /*margin-bottom: -4rem;*/

}

.personnel:hover {
  box-shadow: 0 0.2rem 0.5rem 0 #B3B3B3;
}

.personnels {
  text-align: center;
  margin-bottom: 2rem;
}

.pre-header > div {
  width: 19rem;
  height: 19rem;
  /*background-color: red;*/
  margin: 1rem auto 1rem;
  border-radius: 1rem;
}

.pre-header > div > img {
  width: 100%;
}

.pre-title > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #0054B0;
  margin-top: 0.6rem;
  /*line-height: 19px;*/
}

.pre-middle > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #272727;
  margin-top: 0.6rem;
  /*line-height: 19px;*/
}

.pre-button {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-top: 0.6rem;

}

.pre-button > p {
  font-size: 1.4rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-family: Roboto-Medium, Roboto, sans-serif;
  font-weight: 500;

  color: #272727;
  /*line-height: 16px;*/
}

.pre {
  margin-top: 2rem;
}

@media screen  and (max-width: 750px) {
  .personnel p {
    margin: 0 0 .8rem !important;
  }

  .parent .personnel:first-child {
    margin-left: 2rem;
  }

  .personnel {
    margin-right: 1.2rem;
  }

  .personnels {
    width: 105%;
  }
}

.parent > div:first-child {
  margin-left: 2rem !important;
}


</style>
