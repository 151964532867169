import { render, staticRenderFns } from "./open-source-software-competition.vue?vue&type=template&id=7a84af05&scoped=true&"
import script from "./open-source-software-competition.vue?vue&type=script&lang=js&"
export * from "./open-source-software-competition.vue?vue&type=script&lang=js&"
import style0 from "./open-source-software-competition.vue?vue&type=style&index=0&id=7a84af05&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a84af05",
  null
  
)

export default component.exports